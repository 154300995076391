<div
  aria-label="Mobile passenger modal"
  aria-modal="true"
  role="dialog"
  [hidden]="!isModalVisible"
  class="modal-window-container"
>
  <div class="modal-container" aria-label="Modal container">
    <div class="modal-header" aria-label="Modal header">
      <div class="tw-h-[4rem]">
        <div class="modal-header-container tw-bg-yellow-100">
          <div
            aria-label="Modal title"
            class="tw-text-center tw-items-center tw-justify-center tw-font-bold"
          >
            {{ modalData.title }}
          </div>
          <div class="modal-close">
            <img
              aria-label="Modal close icon"
              class="tw-absolute tw-right-5 tw-top-4"
              (click)="onCloseModal()"
              src="/assets/icons/svg/exit.svg"
              alt="exit"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body" aria-label="Modal body">
      <div class="modal-body">
        <form [formGroup]="modalFormGroup">
          <div class="tw-relative">
            <p aria-label="Modal label">{{ modalData.label }}</p>
            <tr-dropdown-multiselect
              #dropDownMenu
              aria-label="Passengers dropdown mobile field"
              formControlName="passengers"
              [config]="passengersConfigTitle"
            >
            </tr-dropdown-multiselect>
          </div>
        </form>
      </div>
    </div>
    <div class="modal-footer" aria-label="Modal footer">
      <button
        aria-label="Done button"
        (click)="onDoneClicked()"
        class="tw-btn tw-btn-primary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
      >
        {{ 'label.button.done.label' | translate }}
      </button>
    </div>
  </div>
</div>
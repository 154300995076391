import { EnvironmentSettings } from './environmentSettings';

export const environment: EnvironmentSettings = {
  production: false,
  name: 'uat',
  appVersion: '0.0.1',
  appSource: 'stafftravel',
  apiKey: '88v7wpmcy44v643tg9trqmw5',                                                                                                                                                                                
  baseUrl: 'https://apigw.singaporeair.com/stafftravel',
  features: {
    greyOutDateWithNoFlights: false
  },
  classOfServices: {
    scootForSure: ['L'],
    scootPlus: 'US',
    economy: 'GS'
  },
  worldpay: {
    baseUrl: 'https://centinelapistag.cardinalcommerce.com',
    ddcUrl: 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/Collect',
    stepUpUrl: 'https://centinelapistag.cardinalcommerce.com/V2/Cruise/StepUp',
    ddcTimeout: 12000,
    polling: {
      retryCount: 15,
      interval: 3000
    },
    payNow: {
      retryCount: 100,
      interval: 10000
    }
  },
  singleSignOn: {
    authority: 'https://login.microsoftonline.com/50fff042-6db6-4c5a-bebb-9bd95c687146',
    clientId: 'ac91b551-ad0c-4f0f-8d1e-7d9be23c574d',
    tenantId: '50fff042-6db6-4c5a-bebb-9bd95c687146',
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  session: {
    warningTimeoutInSeconds: 300,
    sessionExpired: true,
  }
};

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NotificationData } from './notification.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly _notification$ = new BehaviorSubject<NotificationData | undefined>(undefined);

  readonly notification$ = this._notification$.asObservable();

  show(notification: NotificationData) {
    this._notification$.next(notification);
  }

  hide(notification: NotificationData, isConfirm: boolean) {    
    if (notification.callBack)
      notification.callBack(isConfirm);

    this._notification$.next(undefined);
  }
}

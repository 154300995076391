import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, shareReplay, tap } from 'rxjs';
import { Country, CurrencyResponse, ExchangeRateResponse, MarketResponse, StationResponse } from './resource.model';
import { AppConstants } from '../../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private httpClient: HttpClient) {}

  getCountries$(): Observable<Country[]> {
    return this.httpClient.get<Country[]>('/resources/countries').pipe(shareReplay(1));
  }

  getMarketOrigin$(): Observable<MarketResponse> {
    return this.httpClient.get<MarketResponse>('/resources/markets/origins').pipe(
      map((data) => {
        return data;
      })
    );
  }

  getMarketDestination$(OriginStationCode: string): Observable<MarketResponse> {
    return this.httpClient
      .get<MarketResponse>('/resources/markets/destinations', {
        params: new HttpParams().set('originStationCode', OriginStationCode)
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  getStations$(): Observable<StationResponse[]> {
    return this.httpClient.get<StationResponse[]>('/resources/stations').pipe(shareReplay(1));
  }

  getCurrencies$(): Observable<CurrencyResponse[]> {
    return this.httpClient.get<CurrencyResponse[]>('/resources/currencies').pipe(
      tap((data)=>{
        const sgdIndex = data.findIndex((x) => x.currencyCode === AppConstants.DefaultCurrencyCode);
        if (sgdIndex !== -1) {
          data.unshift(data.splice(sgdIndex, 1)[0]);
        }
      })
    );
  }

  getExternalRates$(): Observable<ExchangeRateResponse[]> {
    return this.httpClient.get<ExchangeRateResponse[]>('/resources/externalrates').pipe(shareReplay(1));
  }
}

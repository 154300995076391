import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap, throwError } from 'rxjs';
import {
  StaffProfile,
  StaffProfileRequest,
  StaffProfileResponse,
  StaffProfileVoucherRequest,
  VoucherList
} from './profile.model';
import { TitleConstant } from './profile.enum';
import { GenderEnum, PassengerTypeEnum } from '../booking/flight/flight.enum';
import { AppConstants } from '../app.constants';
import { AccountService } from '../account/account.service';

import { UserContactInformation } from '../booking/booking.model';
import { SKIP_LOADER } from '../shared/model/common.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private readonly _staffProfile$ = new BehaviorSubject<StaffProfile | null>(null);

  readonly staffProfile$ = this._staffProfile$.asObservable();

  constructor(
    private httpClient: HttpClient,
    private accountService: AccountService

  ) {}

  retrieveStaffProfile$(request: StaffProfileRequest, skipLoader: boolean = false): Observable<StaffProfileResponse | null> {
    return this.httpClient
      .post<StaffProfileResponse>('/external/staff/profile/get', request , { context: new HttpContext()
      .set(SKIP_LOADER, skipLoader) })
      .pipe(
        tap((response) => {
          const staffData = response.data;
          this._staffProfile$.next(staffData);
          if (staffData) {
            const userInfo = {
              employeeId: staffData.employeeId,
              email: staffData.email,
              firstName: staffData.firstName,
              lastName: staffData.lastName,
              company: staffData.company,
              seniorityDescriptor: staffData.seniorityDescriptor,
              isCadetPilot: staffData.isCadetPilot,
              terminationDate: staffData.terminationDate
            };
            this.accountService.setUserInfo(userInfo)
          }
        }),
        catchError((error) => this.handleError(error))
      );
  }

  retrieveStaffProfileVouchers$(request: StaffProfileVoucherRequest): Observable<VoucherList> {
    return this.httpClient.post<VoucherList>('/external/staff/profile/vouchers/get', request);
  }

  getPassengerType(dateOfBirth: string, currentDate: Date): string {
    let age = this.getAge(dateOfBirth, currentDate);
    return age >= 12
      ? PassengerTypeEnum.ADULT
      : age < 12 && age >= 2
      ? PassengerTypeEnum.CHILD
      : PassengerTypeEnum.INFANT;
  }

  getMobileNumber(phoneNumber: string | undefined): [string, string] {
    let mobileCode = '';
    let mobileNumber = '';

    if (phoneNumber) { 
      if (phoneNumber?.includes('(')) {
        const numberData = phoneNumber.trim().split('(');
        mobileCode = numberData[0].replace(/ +/g, '').replace('+', '').replace('(', '').replace(')', '').trim();
        mobileNumber = numberData[1].replace(/ +/g, '').replace('(', '').replace(')', '').trim();
      }
      else {
        const numberData = phoneNumber.replace(/  +/g, ' ').trim().split(' ');
        mobileCode = numberData[0].replace('+', '').replace('(', '').replace(')', '');
        for (var i = 1; i < numberData.length; i++) {
          mobileNumber = mobileNumber + numberData[i].replace('(', '').replace(')', '').trim();
        }
      }
    }

    if (mobileCode) {
      mobileCode = `+${mobileCode.length > 3 ? mobileCode.substring(0, 3) : mobileCode}`;
    }

    return [mobileCode, mobileNumber];
  }

  getGenderTitle(gender: number) {
    return gender === GenderEnum.Male ? TitleConstant.MR : TitleConstant.MS;
  }

  getContactInformation(profile: StaffProfile): UserContactInformation {
    const mobileDetails = this.getMobileNumber(profile.phoneNumber);
    const contact = {
      salutation: profile.gender === GenderEnum.Male ? TitleConstant.MR : TitleConstant.MS,
      firstName: profile.firstName,
      lastName: profile.lastName,
      emailAddress: profile.email,
      mobileCode: mobileDetails[0],
      mobileNumber: mobileDetails[1],
      isEuResident: false,
      isUnionMember: profile.isUnionMember,
      address: {
      addressLine1: profile.primaryAddressLine1 ?? '',
        addressLine2: '',
        addressLine3: '',
        townCity: profile.homeAddressCity ?? '',
        country: profile.primaryAddressCountry ?? '',
        zipPostalCode: profile.primaryAddressPostalCode ?? ''
      },
      companyName: AppConstants.CompanyName,
    };

    return contact;
  }

  getAge(dateOfBirth: string, currentDate: Date): number {
    let age = 0;
    if (dateOfBirth) {
      const dateBirth = new Date(dateOfBirth);
      const monthDiff = currentDate.getTime() - dateBirth.getTime();
      const ageDateTime = new Date(monthDiff);
      const year = ageDateTime.getUTCFullYear();
      age = Math.abs(year - 1970);
    }
    return age;
  }

  private handleError(error: HttpErrorResponse) {
    return throwError(() => error);
  }
}

import { Injectable, Type } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ModalTypeComponent {}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private readonly _currentlyDisplayed$ = new BehaviorSubject<Type<ModalTypeComponent> | undefined>(undefined);
  private readonly _currentlyData$ = new BehaviorSubject<Type<any> | undefined>(undefined);

  readonly currentlyDisplayed$ = this._currentlyDisplayed$.asObservable();
  readonly currentlyData$ = this._currentlyData$.asObservable();

  show(component: Type<ModalTypeComponent>, data: any = null) {
    this._currentlyDisplayed$.next(component);
    this._currentlyData$.next(data);
  }

  hide() {
    this._currentlyDisplayed$.next(undefined);
    this._currentlyData$.next(undefined);
  }
}

export enum PageRoute {
  Home = '/home',
  Error = '/error',
  BookFlight = '/book-flight',
  BookFlightSelection = '/book-flight/selection',
  BookFlightPayment = '/book-flight/payment',
  ManageMyBooking = '/manage-booking',
  FAQ = '/faq',
  Profile = '/profile',
  ProfileDetails = '/profile/details',
  ProfileFOCEntitlement = '/profile/foc-entitlement',
  ProfileMyVoucher = '/profile/my-voucher',
  Confirmation = '/confirmation',
  Wait = '/wait',
  ProfileTravelBeneficiaries = '/profile/travel-beneficiaries'
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { HeaderService } from '../header/header.service';
import { Observable } from 'rxjs';
import { PageRoute } from '../shared/enums/page-routes.enum';

@Component({
  selector: 'app-progression-bar',
  templateUrl: './progression-bar.component.html',
  styleUrls: ['./progression-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressionBarComponent implements OnInit {

  pageRoute = PageRoute;
  currentPage$?: Observable<string>;
  
  constructor(
    private headerService: HeaderService
  ) { }

  ngOnInit(): void {
    this.currentPage$ = this.headerService.currentPage$;
  }
}

export enum TabMenuEnum {
  Upcoming = 'Upcoming',
  Past = 'Past',
  Cancelled = 'Cancelled'
}

export enum TripStatusEnum {
  All = -1,
  Upcoming = 0,
  Past = 1,
  Cancelled = 2
}

export enum BookingLiftStatusEnum {
  Default = 0,
  CheckedIn = 1,
  Boarded = 2,
  NoShow  = 3
}
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FlightSearchService } from '../../flight-search.service';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DateFieldTypeEnum } from '../../../../flight/flight.enum';
import { AppConstants } from '../../../../../app.constants';
import { FlightSearchCalendarComponent } from '../flight-search-calendar.component';

@Component({
  selector: 'app-flight-search-calendar-mobile-modal',
  templateUrl: './flight-search-calendar-mobile-modal.component.html',
  styleUrls: ['./flight-search-calendar-mobile-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSearchCalendarMobileModalComponent {
  readonly weekDays = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
  readonly dateFieldTypes = DateFieldTypeEnum;
  readonly dateInputFormatConstant = AppConstants.DefaultDisplayDateFormat;

  modalFormGroup!: FormGroup;
  showDatepicker = true;
  disabledButton = true;

  @Input() isCalendarModalVisible!: boolean;
  @Input() terminationDate!: string;
  @Input() departureDateControl!: AbstractControl<string>;
  @Input() returnDateControl!: AbstractControl<string>;
  @Output() closeCalendarModal = new EventEmitter<void>();

  @ViewChild('searchCalendar') searchCalendar!: FlightSearchCalendarComponent;

  constructor(private flightSearchService: FlightSearchService) {
    this.modalFormGroup = this.flightSearchService.flightSearchForm;
  }

  validateIfDisabledButton() {
    this.disabledButton = !this.departureDateControl.value && !this.returnDateControl.value;
  }

  isDisabledButton(isDisabledButton: boolean): void {
    this.disabledButton = isDisabledButton;
  }

  onCloseModal(): void {
    this.isCalendarModalVisible = false;
    this.showDatepicker = false;
    this.closeCalendarModal.emit();
  }

  onSelectDate(): void {
    this.searchCalendar.onClickReset();
  }

  onClickReset() {
   this.searchCalendar.onClickReset();
  }

  onClickDone() {
    this.searchCalendar.onClickDone();
  }

  get _isReturn(): boolean {
    return this.modalFormGroup.get('isReturn')?.value;
  }

  get _departDate(): string {
    return this.modalFormGroup.get('departDate')?.value;
  }

  get _returnDate(): string {
    return this.modalFormGroup.get('returnDate')?.value;
  }

  get _origin(): string {
    return this.modalFormGroup.get('fromCityAirport')?.value;
  }

  get _destination(): string {
    return this.modalFormGroup.get('toCityAirport')?.value;
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-pagination-bar',
  templateUrl: './pagination-bar.component.html',
  styleUrls: ['./pagination-bar.component.scss']
})
export class PaginationBarComponent implements OnInit, OnChanges {
  @Input() totalItems = 0;
  @Input() itemsPerPage = 5;
  @Output() changePage = new EventEmitter<number>();

  arrayNumberPages: number[] = [];
  numberOfPages = this.isMobile ? 3 : 5;
  currentPage = 1;
  disabledNext = false;
  disabledPrev = false;

  ngOnChanges(): void {
    this.generatePages();
  }

  ngOnInit(): void {
    this.generatePages();
  }

  onChangePage(page: number): void {
    this.currentPage = page;
    this.generatePages();
    this.changePage.emit(page);
  }

  resetPagination() {
    this.currentPage = 1;
    this.numberOfPages = 0;
    this.arrayNumberPages = [];
    this.generatePages();
  }

  generatePages(){
    this.arrayNumberPages = [];
    let pages = this.calculateNumberOfPages(this.totalItems);

    let maxitems = (this.numberOfPages / 2) | 0;
    let minpage = 1;
    let maxpage = this.numberOfPages;

    if (this.currentPage + maxitems > pages) {
      minpage = this.numberOfPages > pages ? 1 : pages - this.numberOfPages;
      maxpage = pages;
    }
    else if (this.currentPage - maxitems > 0) {
      minpage = this.currentPage - maxitems;
      maxpage = this.currentPage + maxitems;
    }

    this.disabledNext = this.currentPage >= pages;
    this.disabledPrev = this.currentPage <= minpage || minpage <= 0;

    for (var page = 1; page <= pages; page++) {
      if (page >= minpage && page <= maxpage) {
        this.arrayNumberPages.push(page);
      }
    }
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  private calculateNumberOfPages(totalItems: number): number {
    return Math.ceil(totalItems / this.itemsPerPage);
  }
}

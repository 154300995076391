import { Beneficiary, StaffProfile } from './../profile.model';
import { Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'app-profile-travel-beneficiaries',
  templateUrl: './profile-travel-beneficiaries.component.html',
  styleUrls: ['./profile-travel-beneficiaries.component.scss']
})
export class ProfileTravelBeneficiariesComponent {
  readonly birthDateFormatConstant = AppConstants.DefaultDisplayBirthDateFormat;

  _profileData?: StaffProfile;
  isTravelBeneficiaryActive = true;
  isTravelBeneficiaryShow: boolean[] = [];
  beneficiaries$ = new BehaviorSubject<Beneficiary[] | null>(null);

  @Input()
  get profileData(): StaffProfile | undefined {
    return this._profileData;
  }
  set profileData(value: StaffProfile | undefined) {
    this._profileData = value;
    this.initializeData(value);
  }

  viewTravelBeneficiary(index: number): void {
    this.isTravelBeneficiaryShow[index] = !this.isTravelBeneficiaryShow[index];
  }

  onBeneficaryViewToggle(isTravelBeneficiaryActive: boolean): void {
    this.isTravelBeneficiaryActive = isTravelBeneficiaryActive;
  }

  private initializeData(profileData: StaffProfile | undefined): void {
    let beneficiaryList: Beneficiary[] = [];
    this.isTravelBeneficiaryShow = [];

    if (profileData) {
      const beneficiaries = profileData.beneficiaries;
      if (beneficiaries.length) {
        beneficiaries.forEach((beneficiary) => {
          beneficiaryList.push(beneficiary);
          this.isTravelBeneficiaryShow.push(true);
        });
      }
    }

    this.beneficiaries$.next(beneficiaryList);
    this.onBeneficaryViewToggle(this.isTravelBeneficiaryActive);
  }
}

<div class="tw-flex tw-grow tw-self-stretch tw-flex-col">
  <!--Details-->
  <div attr.aria-label="{{detailType}}Flight details section" class="details tw-gap-5 tw-w-full">
    <!--Desktop View-->
    <div
      class="tw-hidden xs:tw-block tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-3.5 tw-inline-flex"
    >
      <div class="tw-self-stretch tw-justify-between tw-items-center tw-inline-flex tw-w-full">
        <!--Departure-->
        <div attr.aria-label="{{detailType}}Flight Departure section" class="tw-flex-col tw-justify-start tw-items-start tw-inline-flex">
          <div class="tw-flex-col tw-justify-start tw-items-start tw-gap-0.5 tw-flex">
            <div
              attr.aria-label="{{detailType}}Flight Departure shortName"
              class="tw-text-stone-500 tw-text-xs tw-font-medium tw-leading-none"
            >
              {{ this.journey.departureStation.city }}, {{ this.journey.departureStation.shortName }}
            </div>
            <div attr.aria-label="{{detailType}}Flight Departure city" class="tw-text-stone-900 tw-text-2xl tw-font-bold tw-leading-9">
              {{ this.journey.departureStation.city }}
            </div>
          </div>
          <div class="tw-self-stretch tw-h-16 tw-flex-col tw-justify-start tw-items-start tw-flex">
            <div attr.aria-label="{{detailType}}Flight Departure time" class="tw-text-stone-900 tw-text-4xl tw-font-bold tw-leading-10">
              {{ this.journey.designator.departure | date : 'HH:mm' }}
            </div>
            <div attr.aria-label="{{detailType}}Flight Departure date" class="tw-text-stone-500 tw-text-xs tw-font-medium tw-leading-none">
              {{ this.journey.designator.departure | date : 'ccc, dd MMMM' }}
            </div>
          </div>
        </div>
        <!--Line-->
        <div
          attr.aria-label="{{detailType}}Flight details line"
          class="tw-w-full tw-px-[15px] tw-flex-col tw-justify-center tw-items-center tw-inline-flex"
        >
          <div
            class="tw-self-stretch tw-text-center tw-justify-start tw-items-center tw-gap-[5px] tw-flex-col tw-inline-flex"
          >
            <div
              attr.aria-label="{{detailType}}Stop label"
              *ngIf="stops > 0"
              class="tw-self-stretch tw-text-center tw-text-stone-500 tw-text-[13px] tw-font-medium tw-leading-4.5"
            >
              {{ stops }}
              <span *ngIf="stops > 1">
                {{ 'label.book-flight-selection.flight-card.stops-at.label' | translate }}
              </span>
              <span *ngIf="stops == 1">
                {{ 'label.book-flight-selection.flight-card.stop-at.label' | translate }}
              </span>
              <span *ngFor="let segment of journey.segments">
                <span *ngFor="let leg of segment.legs; index as i">
                  <span *ngIf="leg.legTransit" class="tw-text-center" attr.aria-label="{{detailType}}1 stop transit">
                    {{ leg.legTransit.station.city }}<span *ngIf="i != segment.legs.length - 2">,</span>
                  </span>
                </span>
              </span>
            </div>
            <div class="tw-self-stretch tw-justify-start tw-items-center tw-gap-[5px] tw-inline-flex">
              <div class="tw-w-6 h-6 tw-relative">
                <img
                  attr.aria-label="{{detailType}}Flight takeoff icon"
                  class="tw-w-[20.28px] tw-h-[17.43px] tw-left-[1.84px] tw-top-[-10px] tw-absolute"
                  src="assets/icons/svg/flight-takeoff.svg"
                />
              </div>
              <div class="separator" attr.aria-label="{{detailType}}Separator line">
                <img *ngIf="stops > 0" class="tw-w-[20px] tw-p-0" src="assets/icons/svg/circle.svg" />
              </div>
              <div class="tw-w-6 tw-h-6 tw-relative">
                <img
                  attr.aria-label="{{detailType}}Map pin icon"
                  class="tw-w-4 tw-h-5 tw-left-[4px] tw-top-[2px] tw-absolute"
                  src="assets/icons/svg/map-pin.svg"
                />
              </div>
            </div>
          </div>
        </div>
        <!--Arrival-->
        <div attr.aria-label="{{detailType}}Flight Arrival section" class="tw-flex-col tw-justify-start tw-items-end tw-inline-flex">
          <div class="tw-flex-col tw-justify-start tw-items-end tw-gap-0.5 tw-flex tw-text-right">
            <div attr.aria-label="{{detailType}}Flight Arrival shortName" class="tw-text-stone-500 tw-text-xs tw-font-medium">
              {{ this.journey.arrivalStation.city }}, {{ this.journey.arrivalStation.shortName }}
            </div>
            <div attr.aria-label="{{detailType}}Flight Arrival city" class="tw-text-stone-900 tw-text-2xl tw-font-bold">
              {{ this.journey.arrivalStation.city }}
            </div>
          </div>
          <div class="tw-self-stretch tw-h-16 tw-flex-col tw-justify-start tw-items-end tw-flex">
            <div attr.aria-label="{{detailType}}Flight Arrival time" class="tw-text-stone-900 tw-text-4xl tw-font-bold">
              {{ this.journey.designator.arrival | date : 'HH:mm' }}
            </div>
            <div attr.aria-label="{{detailType}}Flight Arrival date" class="tw-text-stone-500 tw-text-xs tw-font-medium">
              {{ this.journey.designator.arrival | date : 'ccc, dd MMMM' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Mobile View-->
    <div class="tw-block xs:tw-hidden">
      <div class="tw-flex tw-grow tw-self-stretch tw-justify-between tw-gap-2">
        <!--Line-->
        <div class="tw-flex-col tw-justify-start tw-inline-flex">
          <div class="tw-w-6 h-6 tw-relative">
            <img
              attr.aria-label="{{detailType}}Flight takeoff icon"
              class="tw-w-6 tw-h-5 tw-left-[1.84px] tw-top-[3.50px] tw-absolute"
              src="assets/icons/svg/flight-takeoff.svg"
            />
          </div>
          <div attr.aria-label="{{detailType}}Stop div" class="tw-relative tw-w-[20px] tw-h-full">
            <div
              class="tw-absolute tw-left-[10px] tw-top-[25px] tw-bottom-0 tw-border tw-border-slate-900 tw-border-dashed"
            ></div>
            <div class="tw-absolute tw-top-[63px]">
              <img *ngIf="stops > 0" class="tw-w-[20px] tw-p-0" src="assets/icons/svg/circle.svg" />
            </div>
          </div>
          <div class="tw-w-6 tw-h-6 tw-relative">
            <img
              attr.aria-label="{{detailType}}Map pin icon"
              class="tw-w-4 tw-h-5 tw-left-[4px] tw-top-[2px] tw-absolute"
              src="assets/icons/svg/map-pin.svg"
            />
          </div>
        </div>
        <!--Departure/Arrival-->
        <div class="tw-flex tw-grow tw-self-stretch tw-flex-col tw-gap-5">
          <!--Departure-->
          <div class="location">
            <div class="location-content">
              <div class="location-city">{{ journey.departureStation.city }}</div>
              <div class="location-shortName">
                {{ journey.departureStation.city }}, {{ journey.departureStation.shortName }}
              </div>
            </div>
            <div class="location-content">
              <div class="location-time">{{ this.journey.designator.departure | date : 'HH:mm' }}</div>
              <div class="location-date">{{ this.journey.designator.departure | date : 'ccc, dd MMMM' }}</div>
            </div>
          </div>
          <!--Stop-->
          <div class="location" *ngIf="stops > 0">
            <div class="location-content">{{ stops }} {{ stops > 1 ? 'stops' : 'stop' }}</div>
          </div>
          <!--Arrival-->
          <div class="location">
            <div class="location-content">
              <div class="location-city">{{ journey.arrivalStation.city }}</div>
              <div class="location-shortName">
                {{ journey.arrivalStation.city }}, {{ journey.arrivalStation.shortName }}
              </div>
            </div>
            <div class="location-content">
              <div class="location-time">{{ this.journey.designator.arrival | date : 'HH:mm' }}</div>
              <div class="location-date">{{ this.journey.designator.arrival | date : 'ccc, dd MMMM' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--Footer-->
  <div attr.aria-label="{{detailType}}Flight details footer" class="footer xs:tw-absolute xs:tw-bottom-0 xs:tw-w-full">
    <div class="tw-grow tw-shrink tw-basis-0 tw-self-stretch tw-justify-between tw-items-center tw-flex">
      <div class="tw-justify-start tw-items-center tw-gap-7 tw-flex">
        <div class="tw-justify-start tw-items-center tw-gap-1 tw-flex">
          <div attr.aria-label="{{detailType}}Flight Operator By" class="label-text">
            {{ 'label.book-flight-selection.flight-card.operated-by.label' | translate }}
          </div>
          <div class="tw-w-6 tw-h-6 tw-pr-px tw-py-px tw-justify-center tw-items-center tw-flex">
            <div class="tw-w-6 tw-h-6 tw-relative">
              <div class="tw-w-6 tw-h-6 tw-left-0 tw-op-0 tw-absolute"></div>
              <img
                attr.aria-label="{{detailType}}Flight Operator Logo"
                class="tw-w-6 tw-h-6 tw-left-[2.53px] tw-top-[0.17px] tw-absolute"
                src="assets/icons/svg/scoot-operator.svg"
              />
            </div>
          </div>
          <div attr.aria-label="{{detailType}}Flight Carrier Code and Identifier" class="label-text">
            {{ journey.segments[0].legs[0].legInfo.equipmentType }}
          </div>
        </div>
        <div attr.aria-label="{{detailType}}Flight Duration" class="tw-hidden xs:tw-block label-text">
          {{ 'label.book-flight-selection.flight-card.flight-duration.label' | translate }} {{ this.transitTimeString }}
        </div>
        <div
          attr.aria-label="Flight number section for {{ journey.segments[0].identifier.carrierCode }}{{
            journey.segments[0].identifier.identifier
          }}"
          class="tw-justify-start tw-items-center tw-gap-1 tw-hidden xs:tw-flex"
        >
          <div class="tw-w-6 tw-h-6 tw-pr-px tw-py-px tw-justify-center tw-items-center tw-flex">
            <div class="tw-w-6 tw-h-6 tw-relative">
              <div class="tw-w-6 tw-h-6 tw-left-0 tw-op-0 tw-absolute"></div>
              <img
                attr.aria-label="Plane icon for {{ journey.segments[0].identifier.carrierCode }}{{
                  journey.segments[0].identifier.identifier
                }}"
                class="tw-w-[19px] tw-h-[20px] tw-left-[2.53px] tw-top-[0.17px] tw-absolute"
                src="assets/icons/svg/Plane.svg"
              />
            </div>
          </div>
          <div
            attr.aria-label="Flight number label for {{ journey.segments[0].identifier.carrierCode }}{{
              journey.segments[0].identifier.identifier
            }}"
            class="label-text"
          >
            {{ journey.segments[0].identifier.carrierCode }}{{ journey.segments[0].identifier.identifier }}
          </div>
        </div>

      </div>
      <div class="tw-justify-start tw-items-center tw-gap-1 tw-flex">
        <div
          (click)="viewDetails()"
          attr.aria-label="{{detailType}}Flight View Details"
          class="tw-cursor-pointer tw-text-sky-600 tw-text-xs tw-font-semibold tw-underline tw-leading-none"
        >
          {{ 'label.button.view-details.label' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>

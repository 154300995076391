import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-error-input',
  templateUrl: './error-input.component.html',
  styleUrls: ['./error-input.component.scss']
})
export class ErrorInputComponent implements OnInit {
  _abstractControl!: AbstractControl[];
  _patternCustomError!: string;
  _messageRequiredError!: string;

  @Input()
  get abstractControl(): AbstractControl[] {
    return this._abstractControl;
  }
  set abstractControl(abstractControl: AbstractControl[]) {
    if (abstractControl) {
      this._abstractControl = abstractControl;
    }
  }

  @Input()
  get messageRequiredError(): string {
    return this._messageRequiredError;
  }
  set messageRequiredError(messageRequiredError: string) {
    if (messageRequiredError) {
      this._messageRequiredError = messageRequiredError;
    }
  }

  @Input()
  get patternCustomError(): string {
    return this._patternCustomError;
  }
  set patternCustomError(patternCustomError: string) {
    if (patternCustomError) {
      this._patternCustomError = patternCustomError;
    }
  }

  private controls: AbstractControl[] = [];

  constructor() {}

  ngOnInit(): void {
    this.controls = this.abstractControl;
  }

  get hasError(): boolean {
    return this.hasFormControlError(this.abstractControl);
  }

  get errorLabel(): string {
    if (this.controls.some((c) => c.errors !== null && 'required' in c.errors)) {
      if (this.messageRequiredError) {
        return this.messageRequiredError;
      }
      return 'label.required';
    }
    if (this.controls.some((c) => c.errors !== null && 'pattern' in c.errors)) {
      return this.patternCustomError;
    }
    if (this.controls.some((c) => c.errors !== null && 'error' in c.errors)) {
      return this.controls[0].errors?.['error'];
    } else {
      return '';
    }
  }
  private hasFormControlError(abstractControls: AbstractControl[]): boolean {
    if (abstractControls) {
      if (abstractControls.some((c: AbstractControl) => !c.valid && (c.touched || c.dirty))) {
        return true;
      }
    }

    return false;
  }
}

<div
  #dropdownElement
  class="dropdown-container xs:tw-h-8.75"
  [class.rounded]="config.isRounded"
  attr.aria-label="Dropdown container for {{ config.componentIdentifier }}"
>
  <div
    #dropdownValueContainer
    class="dropdown-value-container tw-relative"
    [class.hasError]="hasError"
    [class.focus]="showDropdownOptions"
    (click)="showDropdownOptions = true"
  >
    <input
      autocomplete="vcc"
      #dropdownInput
      class="dropdown-input tw-mt-0.75"
      [ngClass]="{ 'tw-pl-3': config.prefix, 'tw-indent-9': config.hasPrefixIcon }"
      [class.hasError]="hasError"
      attr.aria-label="Dropdown input for {{ config.componentIdentifier }}"
      type="text"
      (input)="updateDropdown($event)"
      (keypress)="handleKeyPress($event)"
      (keydown)="handleKeyDown($event)"
      (keydown.Tab)="onFocusedOut($event.target)"
      [placeholder]="placeholder | translate"
      [(ngModel)]="searchText"
      [disabled]="isDisabled || showLoader"
      (focus)="onFocusIn()"
    />
    <span
      class="tw-absolute tw-left-0 tw-top-[10px] tw-text-lg tw-z-30"
      [ngClass]="config.control.value ? 'tw-text-black-100' : 'tw-text-black-40'"
      *ngIf="config.prefix"
      >{{ config.prefix }}
    </span>
    <img
      class="clear-selection-icon"
      src="/assets/icons/svg/exit.svg"
      attr.aria-label="Clear selection for {{ config.componentIdentifier }}"
      *ngIf="searchText"
      (click)="clearSelection()"
    />
    <app-spinner *ngIf="showLoader" class="tw-absolute tw-z-40 tw-right-[10px] tw-bottom-[7px]"></app-spinner>
    <ng-container *ngTemplateOutlet="arrowDown"></ng-container>
  </div>
  <div
    *ngIf="(showDropdownOptions || isMobile) && !showLoader && !isDisabled"
    class="dropdown-option-container tw-text-4.5 tw-leading-7"
    [ngClass]="isMobile ? 'mobile' : 'tw-max-h-72'"
    attr.aria-label="Dropdown options container for {{ config.componentIdentifier }}"
  >
    <div
      *ngFor="let option of options"
      class="dropdown-option tw-flex tw-px-5 tw-py-2.5 tw-whitespace-nowrap tw-items-start tw-self-stretch tw-justify-between"
      [class.selected]="option.code === config.control.value"
      [class.disabled]="option.isDisabled"
      attr.aria-label="Dropdown option for {{ config.componentIdentifier }}"
      (click)="onOptionClicked(option.value, option.code, option.isDisabled)"
    >
      <div class="tw-flex-col tw-justify-start">
        <div class="tw-text-lg tw-leading-normal tw-break-words tw-whitespace-normal">
          {{ config.prefix }}{{ option.value }}
        </div>
        <div class="tw-text-sm tw-text-stone-500 tw-leading-normal tw-break-words tw-whitespace-normal">
          {{ option.description }}
        </div>
      </div>
      <div
        class="tw-h-[30px] tw-px-[5px] tw-py-[3px] tw-bg-stone-900 tw-rounded tw-justify-end tw-gap-[-5px] tw-flex"
        *ngIf="option.showCodeBanner"
      >
        <div class="tw-text-center tw-text-white tw-text-[15px] tw-font-bold tw-leading-normal">
          {{ option.code }}
        </div>
      </div>
    </div>
    <div
      *ngIf="options && options.length === 0 && !showLoader"
      class="dropdown-option tw-flex tw-items-center tw-px-5 tw-py-2.5 tw-text-black-40"
      attr.aria-label="Dropdown option for {{ config.componentIdentifier }}"
    >
      {{ 'label.' + config.componentIdentifier + '.not-found' | translate }}
    </div>
  </div>
</div>
<ng-template #arrowDown>
  <img
    [hidden]="!config.showArrowDown"
    class="tw-absolute tw-right-0 tw-z-30 tw-mt-2"
    src="assets/icons/svg/arrow-down.svg"
    alt="loyalty-arrow-down"
  />
</ng-template>

<div
  aria-label="Cancel Journey modal"
  [hidden]="!showForm"
  [ngClass]="{
    'tw-justify-center tw-items-center tw-flex': showForm
  }"
  class="str-modal"
  role="dialog"
  aria-modal="true"
  *ngIf="booking$ | async as booking"
>
  <div
    aria-label="Cancel Journey modal background"
    class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
  >
  </div>
  <div aria-label="Cancel Journey modal content" 
    class="scoot-container tw-mx-auto tw-w-full tw-relative tw-rounded-5 tw-shadow tw-bg-white">
    <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-between">
       <!--Header-->
      <div aria-label="Cancel Journey header section" 
        class="tw-w-full tw-rounded-t-5 tw-border-b tw-px-[30px] tw-py-[20px] tw-bg-yellow-100"
      >
        <h2 aria-label="Cancel Journey modal header label" class="tw-text tw-text-6.5 tw-font-bold">
          <span> {{ 'label.manage-booking.cancel-flight-header.label' | translate }} </span>
        </h2>
      </div>
      <!--Content-->
      <div aria-label="Cancel Journey content section" class="tw-w-full str-modal-content">
        <div class="tw-w-full tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[1.5rem]">
          <!--Error-->
          <div class="tw-w-full tw-flex-1 tw-p-[0.7rem] tw-bg-errors-pink tw-rounded-2xl tw-border tw-items-start tw-gap-2.5 tw-inline-flex" 
            *ngIf="errorMessage$ | async as errorMessage">
            <div class="tw-w-6 tw-h-6 tw-relative">
              <img aria-label="Error icon" class="tw-mx-auto tw-cursor-pointer" src="assets/icons/svg/error.svg" />
            </div>
            <div class="tw-grow tw-shrink tw-basis-0 tw-text-stone-900 tw-text-base tw-font-medium tw-leading-normal"
              [innerHTML]="(errorMessage | translate) | safe: 'html'">
            </div>
          </div>
          <!--Info Message-->
          <div class="tw-w-full">
            <div aria-label="Cancel Journey info1 label" class="tw-text tw-text-lg tw-font-bold">
              <span> {{ 'label.manage-booking.cancel-flight-info1.label' | translate }} </span>
            </div>
            <div aria-label="Cancel Journey info2 label" class="tw-text tw-text-base">
              <span> {{ 'label.manage-booking.cancel-flight-info2.label' | translate }} </span>
            </div>
          </div>
          <!--Flight Details-->
          <div class="tw-w-full">
            <div class="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[20px]">
              <div *ngFor="let journey of booking.journeys; index as journeyIndex" class="tw-w-full">
                <div class="tw-flex tw-flex-col tw-items-start tw-justify-start tw-gap-[10px]">
                  <div class="tw-w-full tw-flex tw-flex-row tw-justify-between tw-items-center">
                    <div>
                      <span attr.aria-label="Cancel modal flight label for {{ journeyIndex }}" class="tw-mr-[5px]">
                        {{ (journeyIndex === 0 ? 'label.manage-booking.departing.label' : 'label.manage-booking.return.label') | translate }}
                      </span>
                      <span attr.aria-label="Cancel modal scootplus label for {{ journeyIndex }}" *ngIf="journey.fares[0].classOfService === flightClassOfServiceCodeEnum.scootPlus" 
                        class="tw-font-bold tw-mr-[5px] tw-p-[3px] tw-bg-yellow-300">
                        {{'label.book-flight.scootplus.label' | translate}}
                      </span>
                      <span attr.aria-label="Cancel modal economy label for {{ journeyIndex }}" *ngIf="flightClassOfServiceCodeEnum.scootForSure.includes(journey.fares[0].classOfService) || journey.fares[0].classOfService === flightClassOfServiceCodeEnum.economy" 
                        class="tw-font-bold tw-mr-[5px] tw-p-[3px] tw-bg-slate-200">
                        {{'label.book-flight.economy.label' | translate}}
                      </span>
                      <span attr.aria-label="Cancel modal scootforsure label for {{ journeyIndex }}" *ngIf="flightClassOfServiceCodeEnum.scootForSure.includes(journey.fares[0].classOfService)"
                        class="tw-font-bold tw-mr-[5px] tw-p-[3px] tw-bg-yellow-300">
                        {{'label.book-flight.scootforsure.label' | translate}}
                      </span>
                      <span attr.aria-label="Cancel modal FOC label for {{ journeyIndex }}" *ngIf="booking.isFOC"
                        class="tw-font-bold tw-mr-[5px] tw-p-[3px] tw-bg-green-100">
                        {{'label.manage-booking.flight-item.foc.label' | translate}}
                      </span>
                    </div>
                    <div class="tw-mb-[-7px]">
                      <input
                        attr.aria-label="Cancel modal checkbox for {{ journeyIndex }}"
                        type="checkbox"
                        [id]="journeyIndex === 0 ? 'DepartCheckbox' : 'ReturnCheckbox'"
                        [name]="journeyIndex === 0 ? 'DepartCheckbox' : 'ReturnCheckbox'"
                        [disabled]="journey.hoursFromSTD < 4"
                        (change)="onChecked(journeyIndex === 0 ? isCancelDepart : isCancelReturn, journeyIndex === 0 ? 'Depart' : 'Return')"
                        [checked]="journeyIndex === 0 ? isCancelDepart : isCancelReturn"
                        class="tw-w-[18px] tw-h-[18px] tw-border-2 tw-rounded-[2px] tw-bg-white tw-border-black-100"
                      />
                    </div>
                  </div>
                  <div class="tw-w-full tw-rounded-2xl tw-border tw-border-b-neutral-300">
                    <app-flight-detail [journey]="journey"> 
                    </app-flight-detail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Footer-->
      <div aria-label="Cancel Journey footer section" 
        class="tw-w-full tw-rounded-b-5 tw-border-t tw-px-[30px] tw-py-[20px] tw-bg-yellow-10"
      >
        <div class="tw-flex tw-flex-col tw-gap-5 tw-justify-end">       
          <div class="tw-flex tw-mt-auto tw-gap-2.5 xs:tw-self-end">
            <button
              aria-label="Cancel Journey cancel button"
              class="tw-btn tw-btn-secondary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
              (click)="onCloseModal()"
            >
              {{ 'label.button.cancel.label' | translate }}
            </button>
            <button
              aria-label="Cancel Journey proceed button"
              class="tw-btn tw-btn-primary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center tw-px-5 md:tw-w-auto"
              [disabled]="!isCancelDepart && !isCancelReturn"
              (click)="onProceed(booking)"
            >
              {{ 'label.button.proceed.label' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

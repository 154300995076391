<div class="tw-items-center tw-px-3.75">
  <div class="scoot-container tw-mx-auto">
    <div class="tw-pt-7" *ngIf="!isSelected">
      <div *ngIf="!trip?.journeys?.length || !isValidFlightDate" class="tw-flex tw-justify-center tw-items-center">
        <div class="tw-flex-col tw-justify-cender tw-items-center tw-gap-2.5 tw-inline-flex">
          <div class="tw-relative tw-inset-0 tw-flex tw-pt-12 tw-mb-[20px]">
            <img
              attr.aria-label="{{ selectedFlightType }} - Error icon"
              [src]="
                !trip?.journeys?.length
                  ? '/assets/icons/svg/no-flights.svg'
                  : !isValidFlightDate
                  ? '/assets/images/error.png'
                  : ''
              "
              class="tw-w-[500px] tw-h-[280px]"
            />
          </div>
          <div class="tw-text-center tw-text-stone-500 tw-text-lg tw-font-bold tw-leading-7">
            <div attr.aria-label="{{ selectedFlightType }} - Error message">
              {{
                (!trip?.journeys?.length
                  ? 'label.book-flight.no-flights-found'
                  : !isValidFlightDate
                  ? 'label.book-flight.scootforsure-within-months'
                  : ''
                ) | translate
              }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isValidFlightDate">
        <div *ngIf="trip && trip.journeys.length > 0">
          <div *ngFor="let journey of trip.journeys; let i = index">
            <app-flight-card
              [journey]="journey"
              [selectedFlightClassOfService]="selectedFlightClassOfService"
              [selectedFlightType]="selectedFlightType"
              [totalVoucherPoints]="totalVoucherPoints"
              [isSelected]="isSelected"
              [hasErrors]="hasErrors"
            >
            </app-flight-card>
          </div>
        </div>
      </div>
    </div>
    <div class="tw-pt-7" *ngIf="isSelected && selectedJourney && isValidFlightDate">
      <app-flight-card
        [journey]="selectedJourney"
        [selectedFlightClassOfService]="selectedFlightClassOfService"
        [selectedFlightType]="selectedFlightType"
        [totalVoucherPoints]="totalVoucherPoints"
        [isSelected]="isSelected"
        [hasErrors]="hasErrors"
      >
      </app-flight-card>
    </div>
  </div>
</div>

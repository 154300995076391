import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { ErrorEnum } from './error.enum';
import { SessionService } from '../session/session.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private service: ErrorService, 
    private sessionService: SessionService, 
    private router: Router
  ) { }

  private isClientError(statusCode: number): boolean {
    const clientErrorRegex = /4\d{2}/;
    return clientErrorRegex.test(statusCode?.toString());
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const criticalApiCalls = [
      '/account/login',
      '/external/staff/profile/get'
    ];
    const isCriticalApiCall = criticalApiCalls.find((apiCall) => request.url.includes(apiCall));
    const invalidStatus = [ErrorEnum.Unauthorized, ErrorEnum.SessionTimeout]
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if ((!this.isClientError(err.status) && isCriticalApiCall) || invalidStatus.includes(err.status)) {
          if (invalidStatus.includes(err.status)) {
            this.sessionService.clearSessionStorage();
          }
          
          this.service.setError(err);
          this.service.setErrorState(true);
          this.router.navigateByUrl(PageRoute.Error);
        }
        return throwError(() => err);
      })
    );
  }
}
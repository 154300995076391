import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SessionService } from './session.service';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { MsalAuthService } from '../shared/service/msal-auth/msal-auth.service';
import { AccountService } from '../account/account.service';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard  {
  constructor(
    private msalAuthService: MsalAuthService,
    private sessionService: SessionService,
    private accountService: AccountService
  ) {}

  canActivate(): Observable<boolean> {
    const token = this.sessionService.getSessionTokenFromLocalStorage();
    const ssoAuthenticated = !!token && token.trim().length > 0;

    if (this.msalAuthService.hasSSOLogin && !this.msalAuthService.authenticated) {
      this.sessionService.clearSessionStorage();
      this.msalAuthService.login();
      return of(false);
    }
    else if (!ssoAuthenticated || !this.accountService.validUser) {
      this.sessionService.clearSessionInfoFromLocalStorage();
      this.accountService.clearUserInfo();
      window.location.href = PageRoute.Home;
      return of(false);
    }
    else {
      return of(true);
    }
  }
}

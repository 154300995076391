import { HttpContextToken } from '@angular/common/http';

export interface ResponseResult {
  isSuccess: boolean;
  errorMessage: string;
}

export const SKIP_AUTHORIZATION = new HttpContextToken(() => false);
export const SKIP_RESET_SESSION = new HttpContextToken(() => false);
export const SKIP_LOADER = new HttpContextToken(() => false);

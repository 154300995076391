<div *ngIf="data"
  aria-label="Flight detail view section" 
  class="tw-bg-zinc-100 tw-rounded-5">
  <!--Header-->
  <div aria-label="Flight detail view header section" class="tw-p-[30px] tw-border-b">
    <!--Title-->
    <div class="tw-pb-[5px] tw-pr-[25px]">
      <div
        aria-label="Flight detail view departure section"
        class="tw-float-left tw-text-stone-900 tw-text-lg tw-leading-7"
      >
        <span aria-label="Flight detail view departure city" class="tw-font-bold">{{
          data.journey.departureStation.city
        }}</span>&nbsp;
        <span aria-label="Flight detail view departure city code" class="tw-font-medium">{{
          data.journey.departureStation.code
        }}</span>
      </div>
      <div
        aria-label="Flight detail view arrival section"
        class="tw-relative tw-text-stone-900 tw-text-lg tw-leading-7"
      >
        <div class="tw-float-left tw-pr-[3px] tw-pl-[3px] tw-pt-[3px]">
          <img class="tw-w-[25px]" src="assets/icons/svg/arrow-right-black.svg" />
        </div>
        <div class="tw-relative">
          <span aria-label="Flight detail view arrival city" class="tw-font-bold">{{
            data.journey.arrivalStation.city
          }}</span>&nbsp;
          <span aria-label="Flight detail view arrival city code" class="tw-font-medium">{{
            data.journey.arrivalStation.code
          }}</span>
        </div>
      </div>
    </div>
    <div
      aria-label="Flight detail view duration and stops"
      class="tw-text-stone-500 tw-text-base tw-font-medium tw-leading-normal"
    >
      {{ data.transitTimeString }}
      <span *ngIf="data.journey.stops > 0"> , {{ data.journey.stops }} stop </span>
    </div>
    <!--Close (X)-->
    <div
      aria-label="Flight detail view close section"
      class="tw-cursor-pointer tw-absolute tw-right-[1.8rem] tw-top-[2.2rem] tw-pl-[15px]"
    >
      <img
        aria-label="Flight detail view close icon"
        (click)="onCloseModal()"
        src="assets/icons/svg/exit.svg"
        alt="exit"
      />
    </div>
  </div>
  <!--Content-->
  <div aria-label="Flight detail view content section" class="tw-p-[30px] xs:tw-w-[38rem]">
    <ol
      aria-label="Flight detail view journey leg list"
      *ngFor="let segment of data.journey.segments"
      class="tw-relative tw-border-l tw-border-l-[3px] tw-border-black-100"
    >
      <li
        attr.aria-label="Flight detail view leg for {{ leg.departureStation.code }}{{ leg.arrivalStation.code }}"
        *ngFor="let leg of segment.legs"
        class="tw-ml-6"
      >
        <div class="tw-relative tw-pl-[10px]">
          <!--Pointer (circle)-->
          <span class="tw-top-[18px] circle-pointer">
            <img class="tw-w-[15px]" src="assets/icons/svg/circle.svg" />
          </span>
          <!--Header-->
          <div
            attr.aria-label="Flight detail view leg header for {{ leg.departureStation.code }}{{ leg.arrivalStation.code }}"
            class="box arrow-left tw-border tw-border-stone-300 tw-bg-yellow-20 tw-p-5"
          >
            <div class="tw-text-neutral-600 tw-text-base tw-font-medium tw-leading-normal">
              <span class="tw-pr-[10px]">
                {{ leg.designator.departure | date : 'HH:mm' }}
              </span>
              <span class="tw-top-[28px] tw-absolute">
                <img class="tw-w-[10px]" src="assets/icons/svg/circle.svg" />
              </span>
              <span class="tw-pl-[20px]">
                {{ leg.designator.departure | date : 'ccc, dd MMMM yyyy' }}
              </span>
            </div>
            <div class="tw-text-stone-900 tw-text-lg tw-font-bold font-['Montserrat'] tw-leading-7">
              {{ leg.departureStation.city }}, {{ leg.departureStation.shortName }}
            </div>
          </div>
          <!--Details-->
          <div
            attr.aria-label="Flight detail view leg details for {{ leg.departureStation.code }}{{
              leg.arrivalStation.code
            }}"
            class="tw-border tw-border-stone-300 tw-bg-yellow-20 tw-p-5 tw-rounded-bl-2xl tw-rounded-br-2xl"
          >
            <div class="tw-pb-] tw-text-neutral-600 tw-text-base tw-font-medium tw-leading-normal">
              {{ segment.identifier.carrierCode }}{{ segment.identifier.identifier }}
            </div>
            <div class="tw-relative">
              <div class="tw-float-left">
                <span class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7">{{
                  leg.departureStation.city
                }}</span>&nbsp;
                <span class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7">{{
                  leg.departureStation.code
                }}</span>
              </div>
              <div class="tw-relative">
                <div class="tw-float-left tw-pr-[3px] tw-pl-[3px] tw-pt-[3px]">
                  <img class="tw-w-[25px]" src="assets/icons/svg/arrow-right-black.svg" />
                </div>
                <div>
                  <span class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7">{{
                    leg.arrivalStation.city
                  }}</span>&nbsp;
                  <span class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7">{{
                    leg.arrivalStation.code
                  }}</span>
                </div>
              </div>
            </div>
            <div class="tw-h-11 tw-relative">
              <div class="tw-left-0 tw-top-0 tw-absolute tw-justify-start tw-items-center tw-gap-1 tw-inline-flex">
                <div class="tw-text-stone-500 tw-text-xs tw-font-medium tw-leading-none">
                  {{ 'label.book-flight-selection.flight-card.operated-by.label' | translate }}
                </div>
                <div class="tw-w-6 tw-h-6 tw-relative">
                  <div class="tw-w-6 tw-h-6 tw-left-0 tw-op-0 tw-absolute"></div>
                  <img
                    aria-label="Flight Operator Logo"
                    class="tw-w-6 tw-h-6 tw-left-[2.53px] tw-top-[0.17px] tw-absolute"
                    src="assets/icons/svg/scoot-operator.svg"
                  />
                </div>
                <div class="tw-pl-[3px] tw-text-stone-500 tw-text-xs tw-font-medium tw-leading-none">
                  {{ segment.legs[0].legInfo.equipmentType }}
                </div>
              </div>
              <div
                class="tw-left-0 tw-top-[28px] tw-absolute tw-text-stone-500 tw-text-xs tw-font-medium tw-leading-none"
              >
                {{ 'label.book-flight-selection.flight-card.flight-duration.label' | translate }}:
                {{ leg.designator.duration }}
              </div>
            </div>
          </div>
        </div>
        <!--Details (Transit)-->
        <div
          attr.aria-label="Flight detail view leg transit for {{ leg.legTransit.station.code }}"
          *ngIf="leg.legTransit"
          class="tw-relative tw-pt-[30px] tw-pb-[30px] tw-pl-[10px]"
        >
          <span class="tw-top-[5px] arrow-pointer">
            <img class="tw-w-[15px]" src="assets/icons/svg/arrow-down-line.svg" />
          </span>
          <span class="tw-pr-[10px] tw-text-neutral-600 tw-text-base tw-font-medium tw-leading-normal">
            {{ leg.designator.arrival | date : 'HH:mm' }}
          </span>
          <span class="tw-top-[35px] tw-absolute">
            <img class="tw-w-[10px]" src="assets/icons/svg/circle.svg" />
          </span>
          <span class="tw-pl-[20px] tw-text-stone-900 tw-text-base tw-font-bold tw-leading-normal">
            {{ 'label.book-flight.flight-details.transit-in' | translate }}
            {{ leg.legTransit.station.city }} {{ leg.legTransit.station.code }}
            for
            {{ leg.legTransit.duration }}
          </span>
        </div>
        <!--Details (Arrival)-->
        <div
          attr.aria-label="Flight detail view leg arrival for {{ leg.arrivalStation.code }}"
          *ngIf="!leg.legTransit"
          class="tw-relative tw-pt-[30px] tw-pl-[10px]"
        >
          <span class="tw-top-[30px] circle-pointer">
            <img class="tw-w-[15px]" src="assets/icons/svg/circle.svg" />
          </span>
          <span class="tw-pr-[10px] tw-text-neutral-600 tw-text-base tw-font-medium tw-leading-normal">
            {{ leg.designator.arrival | date : 'HH:mm' }}
          </span>
          <span class="tw-top-[35px] tw-absolute">
            <img class="tw-w-[10px]" src="assets/icons/svg/circle.svg" />
          </span>
          <span class="tw-pl-[20px] tw-text-stone-900 tw-text-base tw-font-bold tw-leading-normal">
            {{ 'label.book-flight.flight-details.arrival-in' | translate }}
            {{ leg.arrivalStation.city }} {{ leg.arrivalStation.code }}
          </span>
        </div>
      </li>
    </ol>
  </div>
</div>

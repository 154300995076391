import { NgModule } from '@angular/core';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './faq/faq.component';
import { FlightComponent } from './booking/flight/flight.component';
import { FlightSelectionComponent } from './booking/flight/flight-selection/flight-selection.component';
import { FlightSearchComponent } from './booking/flight/flight-search/flight-search.component';
import { FlightPaymentComponent } from './booking/flight/flight-payment/flight-payment.component';
import { ProfileResolver } from './profile/profile-resolver';
import { ProfileComponent } from './profile/profile.component';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '../environments/environment';
import { ConfirmationComponent } from './booking/confirmation/confirmation.component';
import { WaitComponent } from './wait/wait.component';
import { SessionGuard } from './session/session.guard';
import { ManageBookingComponent } from './manage-booking/manage-booking.component';
import { ErrorComponent } from './error/error.component';
import { ErrorGuard } from './error/error.guard';
import { PaymentReceiverComponent } from './booking/payment-receiver/payment-receiver.component';
import { RouterModule, Routes } from '@angular/router';

const msalGuard = [];
if (environment.singleSignOn.authority.length > 0){
  msalGuard.push(MsalGuard);
}

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: msalGuard
  },
  {
    path: 'error',
    canActivate: [ErrorGuard],
    component: ErrorComponent
  },
  {
    path: 'book-flight',
    component: FlightComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: '',
        component: FlightSearchComponent,
        canActivate: [SessionGuard],
      },
      {
        path: 'selection',
        component: FlightSelectionComponent,
        canActivate: [SessionGuard],
      },
      {
        path: 'payment',
        component: FlightPaymentComponent,
        canActivate: [SessionGuard],
      }
    ]
  },
  {
    path: 'manage-booking',
    component: ManageBookingComponent,
    resolve: { staffProfile: ProfileResolver },
    canActivate: [SessionGuard]
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'wait',
    component: WaitComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'receiver',
    component: PaymentReceiverComponent,
    canActivate: [SessionGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    resolve: { staffProfile: ProfileResolver },
    canActivate: [SessionGuard]
  },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

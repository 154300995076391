import { Injectable } from '@angular/core';
import { UserInformation } from '@it-application-hub/payments/lib/payments-page/payments-identity.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Currency } from './payment.model';
import { FOCPassengerData, OFWPassengerData, VoucherData } from '../flight/flight.model';
import { BookingDetailPassenger, BookingDetailVoucher, BookingPassenger, BookingPayment } from '../booking.model';
import { AppConstants } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private _submitPayment = new Subject<boolean>();
  private _paymentOnProcess = new Subject<boolean>();
  private _userInformation!: UserInformation;

  reloadPaymentPage$ = new BehaviorSubject<boolean>(true);
  ofwPassengers$ = new BehaviorSubject<OFWPassengerData[]>([]);
  focPassengers$ = new BehaviorSubject<FOCPassengerData[]>([]);
  vouchers$ = new BehaviorSubject<VoucherData[]>([]);
  voucherTotalRedeemedPoints = 0;

  selectedCurrency: Currency = {
    code: '',
    name: ''
  };

  submitPayment(): void {
    this._submitPayment.next(true);
  }

  paymentOnProcess(value: boolean): void {
    this._paymentOnProcess.next(value);
  }

  setUserInformation(userInformation: UserInformation) {
    this._userInformation = userInformation;
  }

  setOFWPassengersData(bookingPassengers: BookingPassenger[]) {
    const ofwPassengers: OFWPassengerData[] = [];
    bookingPassengers.forEach(function (passenger) {
      const ofw = {
        passengerKey: passenger.passengerKey,
        passengerType: passenger.passengerType,
        passengerFees: passenger.passengerFees,
        name: passenger.name
      } as OFWPassengerData;

      const passengerHasIPSCFee = passenger.passengerFees.find((x) => x.feeCode === AppConstants.IPSCFeeCode) != null;
      ofw.isSelected = !passengerHasIPSCFee;
      ofw.isSelectedForAdd = passengerHasIPSCFee;
      ofw.isSelectedForDelete = !passengerHasIPSCFee;

      ofwPassengers.push(ofw);
    });

    this.ofwPassengers$.next(ofwPassengers);
  }

  setFOCPassengersData(passengers: BookingDetailPassenger[], isFocBooking: boolean, creditsToDeduct: number) {
    const focPassengerData: FOCPassengerData[] = [];

    passengers.forEach(function (passenger) {
      const foc = {
        passengerTypeCode: passenger.passengerTypeCode,
        firstName: passenger.firstName,
        lastName: passenger.lastName,
        middleName: passenger.middleName,
        title: passenger.title,
        creditsRemaining: passenger.creditsRemaining,
        beneficiaryId: passenger.beneficiaryId
      } as FOCPassengerData;

      if (isFocBooking) {
        foc.creditsRemaining -= creditsToDeduct;
      }

      focPassengerData.push(foc);
    });

    this.focPassengers$.next(focPassengerData);
  }

  setVouchersData(bookingPayments: BookingPayment[], vouchers: BookingDetailVoucher[]) {
    const voucherList: VoucherData[] = [];

    vouchers.forEach((voucher) => {
      const data = {
        voucherNumber: voucher.voucherNumber,
        amount: voucher.amount,
        currency: voucher.currency,
        expiry: voucher.expiry
      } as VoucherData;

      voucherList.push(data);
    });

    bookingPayments
      .filter((x) => x.code === AppConstants.VoucherPaymentCode)
      .forEach((payment) => {
        const voucher = voucherList.find((x) => x.voucherNumber === payment.accountNumber);
        if (voucher !== undefined) {
          this.voucherTotalRedeemedPoints += voucher.amount;
          voucher.isSelectedForAdd = false;
          voucher.isSelectedForDelete = true;
          voucher.isSelected = true;
          voucher.isDeleted = false;
        }
      });

    this.vouchers$.next(voucherList);
  }

  get userInformation(): UserInformation | undefined {
    return this._userInformation;
  }

  get submitPayment$(): Observable<boolean> {
    return this._submitPayment;
  }

  get paymentOnProcess$(): Observable<boolean> {
    return this._paymentOnProcess;
  }
}

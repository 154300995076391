import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import {
  DropdownMultiSelectComponent,
  DropdownMultiSelectConfig,
  DropdownMultiSelectOption
} from '../../../../shared/components/dropdown-multiselect/dropdown-multiselect.component';
import { AbstractControl, FormGroup } from '@angular/forms';
import { FlightSearchService } from '../flight-search.service';

export interface PassengerModalData {
  title: string;
  label: string;
  options: DropdownMultiSelectOption[];
}

@Component({
  selector: 'app-flight-search-passenger-modal',
  templateUrl: './flight-search-passenger-modal.component.html',
  styleUrls: ['./flight-search-passenger-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSearchPassengerModalComponent implements OnInit, AfterViewInit {
  private readonly _destroy$ = new Subject<void>();
  private _isModalVisible!: boolean;
  _inputPassengers!: number[];

  @Input()
  get isModalVisible(): boolean {
    return this._isModalVisible;
  }
  set isModalVisible(isModalVisible: boolean) {
    this._isModalVisible = isModalVisible;
  }

  @Output() closeModal = new EventEmitter<string>();
  @Input() modalData!: PassengerModalData;
 
  passengersConfigTitle!: DropdownMultiSelectConfig;
  modalFormGroup!: FormGroup;

  @ViewChild('dropDownMenu') dropDownMenu!: DropdownMultiSelectComponent;

  constructor(private flightSearchService: FlightSearchService) {
    this.modalFormGroup = this.flightSearchService.flightSearchForm;
    this.passengersConfigTitle = this.flightSearchService.getPassengerConfig();
  }

  ngOnInit(): void {
    this.configForDropdown();
    this.observeData();
  }

  ngAfterViewInit(): void {
    if (this.dropDownMenu) {
     this.dropDownMenu.showDropdownOptions = this.isModalVisible;
    }
  }

  onCloseModal(): void {
    this.isModalVisible = false;

    if (this.passengersConfigTitle) {
      this.passengersConfigTitle.options.forEach(data => {
        data.selected = this._passengers?.value 
          ? this._passengers?.value.includes(data.id)
          : false;
      });
    }
    
    this.closeModal.emit();
  }

  onDoneClicked(): void {
    this.dropDownMenu.onDoneClicked();
  }

  private configForDropdown(): void {
    if (!this.passengersConfigTitle && this.modalFormGroup) {
      this.passengersConfigTitle = {
        control: this.modalFormGroup.controls['passengers'],
        isRounded: false,
        componentIdentifier: 'book-flight-search.select-passengers',
        options: [],
        hasPrefixIcon: true,
        showArrowDown: false,
      };
      this.passengersConfigTitle.options = this.modalData.options;
    }
  }

  private observeData(): void {
    if(this.modalFormGroup){
      this.modalFormGroup.controls['passengers'].valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
        if (value) {
          this.flightSearchService.setPassengerConfig(this.passengersConfigTitle);
          this.onCloseModal();
        }
      });
    }
  }

  get _passengers(): AbstractControl | null {
    return this.flightSearchService.flightSearchForm.get('passengers');
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

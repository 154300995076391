import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentsWaitModuleService, WaitModuleConfiguration } from '@it-application-hub/payments';
import { environment } from '../../environments/environment';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { Subject, take, takeUntil } from 'rxjs';
import { BookingService } from '../booking/booking.service';
import { SessionService } from '../session/session.service';
import { AccountService } from '../account/account.service';

@Component({
  selector: 'app-wait',
  templateUrl: './wait.component.html',
  styleUrls: ['./wait.component.scss']
})
export class WaitComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  constructor(
    private waitPageService: PaymentsWaitModuleService,
    private bookingService: BookingService,
    private accountService: AccountService,
    private sessionService: SessionService,
    private router: Router
  ) {
    const routerData = this.router.getCurrentNavigation()?.extras.state;
    if (routerData) {
      const waitModuleConfiguration: WaitModuleConfiguration = {
        paymentReference: routerData['paymentReference'],
        memberConfiguration: {
          customerId: '',
          lslToken: ''
        },
        pollingConfiguration: {
          interval: environment.worldpay.polling.interval,
          retryCount: environment.worldpay.polling.retryCount
        }
      };
      this.waitPageService.initialize(waitModuleConfiguration);
      this.waitPageService
        .submit$()
        .pipe(takeUntil(this._destroy$))
        .subscribe((isConfirmed) => {
          if (isConfirmed) {
            this.bookingService
              .addBooking$(this.bookingService.bookingRequest)
              .pipe(take(1))
              .subscribe(() => {
                this.bookingService.clearBookingRequest();
                this.accountService.setUserInfo(this.accountService.getUserInfo());
                this.sessionService.setSessionToLocalStorage({
                  accessToken: this.sessionService.getSessionTokenFromLocalStorage(),
                  expiresIn: this.sessionService.getExpiresInFromLocalStorage()
                });
                window.location.href = PageRoute.Confirmation;
              });
          } else this.router.navigate([PageRoute.BookFlightPayment]);
        });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
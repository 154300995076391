import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { AbstractControl, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import _ from 'lodash';
import { PassengerTypeEnum } from '../../../booking/flight/flight.enum';
import { AppConstants } from '../../../app.constants';

export interface DropdownMultiSelectOption {
  id: number;
  title: string;
  value: string;
  code: string;
  description: string;
  isDisabled: boolean;
  selected: boolean;
  hasError: boolean;
  errorMessage: string;
  imageUrl: string;
  data: {};
}

export interface DropdownMultiSelectConfig {
  control: AbstractControl<any[], any[]>;
  componentIdentifier: string;
  options: DropdownMultiSelectOption[];
  isRounded: boolean;
  prefix?: string;
  hasPrefixIcon: boolean;
  showArrowDown: boolean;
}

@Component({
  selector: 'tr-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DropdownMultiSelectComponent,
      multi: true
    }
  ]
})
export class DropdownMultiSelectComponent implements ControlValueAccessor {
  options!: DropdownMultiSelectOption[];
  origOptions: DropdownMultiSelectOption[] = [];
  selectedValues: string = '';

  private _config!: DropdownMultiSelectConfig;

  @Input() showLoader!: boolean;
  @Input()
  get config(): DropdownMultiSelectConfig {
    return this._config;
  }
  set config(data: DropdownMultiSelectConfig) {
    if (data) {
      this._config = data;
      this.options = data.options;

      if (!data.options.find((option) => option.value === this.optionSelect?.value)) {
        this.config.control.setValue([]);
      }
    }
  }

  @ViewChild('dropdownElement') dropdownElement!: ElementRef;
  @ViewChild('dropdownValueContainer') dropdownValueContainer!: ElementRef;

  showDropdownOptions = false;
  keyword: string = '';
  disable: boolean = false;
  optionSelect: DropdownMultiSelectOption | undefined;

  onChangeOption = () => {};

  onTouched = () => {};

  writeValue() {
    this.selectedValues = this.options
      .filter((r) => r.selected)
      .map((r) => r.value)
      .join(', ');
  }

  registerOnChange(onChangeOption: any) {
    this.onChangeOption = onChangeOption;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disable: any) {
    this.disable = disable;
  }

  onFocusedOut() {
    this.setSelectedValues();
    this.config.control?.markAsTouched();
    this.showDropdownOptions = false;
  }

  onFocusIn() {
    this.origOptions = JSON.parse(JSON.stringify(this.config.options));
    this.options = this.config.options;
  }

  onOptionClicked(option: DropdownMultiSelectOption) {
    if (!option.hasError && !option.isDisabled) {
      option.selected = !option.selected;
      this.validatePassengerOneAdultPerInfant();
    }
  }

  validatePassengerOneAdultPerInfant() {
    const getCount = (passengerType: PassengerTypeEnum) =>
      this.options.filter((x) => x.description === passengerType && x.selected).length;

    const adultCount = getCount(PassengerTypeEnum.ADULT);
    const infantCount = getCount(PassengerTypeEnum.INFANT);

    this.options.forEach((option) => {
      const isInfant = option.description === PassengerTypeEnum.INFANT;

      if (adultCount === infantCount && isInfant) {
        option.isDisabled = !option.selected && isInfant;
      } else if (adultCount > infantCount && isInfant) {
        option.isDisabled = false;
      } else if (isInfant) {
        option.selected = !isInfant;
        option.isDisabled = adultCount ? false : true;
      }
    });
  }

  onDoneClicked() {
    this.setSelectedValues();
    this.showDropdownOptions = false;
  }

  private setSelectedValues(){
    this.config.options = this.options;
    this.selectedValues = this.options
      .filter((r) => r.selected)
      .map((r) => r.value)
      .join(', ');
    this.config.control.patchValue(this.options.filter((r) => r.selected).map((r) => r.id));
  }

  get placeholder(): string {
    return 'label.' + this.config.componentIdentifier + '.placeholder';
  }

  get isRequired(): boolean {
    return this.config.control.hasValidator(Validators.required);
  }

  get hasError(): boolean {
    return !this.config.control?.valid && (this.config.control?.touched || this.config.control?.dirty);
  }

  get isDisabled(): boolean {
    return this.config.control.disabled;
  }

  get dropdownOptions(): DropdownMultiSelectOption[] {
    if (this.optionSelect?.value !== undefined) {
      return this.config.options.filter((option) =>
        this.optionSelect?.value !== undefined
          ? option.value.toLowerCase().includes(this.optionSelect?.value.toLocaleLowerCase())
          : null
      );
    }
    return this.config.options;
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(target: HTMLElement) {
    if (!this.dropdownElement.nativeElement.contains(target) && this.showDropdownOptions) {
      this.onFocusedOut();
    }
  }
}

import { Injectable } from '@angular/core';
import { SessionService } from './session.service';
import { environment } from '../../environments/environment';
import { Observable, ReplaySubject } from 'rxjs';
import { ModalService } from '../shared/components/modal/modal.service';
import { SessionExpiringComponent } from './session-expiring/session-expiring.component';
import { SessionExpiredComponent } from './session-expired/session-expired.component';
import { SessionResponse } from './session.model';

@Injectable({
  providedIn: 'root'
})
export class SessionExpireService {
  warningTimeoutInSecond = environment.session.warningTimeoutInSeconds;
  remainingTimeBeforeExpiryInSecond = 0;
  startExpireTimeoutInSeconds = 0;
  showSessionExpired = environment.session.sessionExpired;

  private _remainingTimeBeforeExpiryInSecond$ = new ReplaySubject<number>(1);
  private interval: any;

  constructor(
    private sessionService: SessionService, 
    private modalService: ModalService
  ) {
    this.warningTimeoutInSecond = environment.session.warningTimeoutInSeconds;
  }

  get remainingTimeBeforeExpiryInSecond$(): Observable<number> {
    return this._remainingTimeBeforeExpiryInSecond$;
  }

  startTimerCountdown(startExpireIn: number): void {
    this.startExpireTimeoutInSeconds = startExpireIn;
    this.remainingTimeBeforeExpiryInSecond = this.startExpireTimeoutInSeconds;
    this.start();
  }

  reset(): void {
    this.remainingTimeBeforeExpiryInSecond = this.startExpireTimeoutInSeconds;
  }

  stop(): void {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  private start(): void {
    if (this.showSessionExpired) {
      this.interval = setInterval(() => {
        this.remainingTimeBeforeExpiryInSecond--;

        if (this.remainingTimeBeforeExpiryInSecond === this.warningTimeoutInSecond) {
          this.modalService.show(SessionExpiringComponent);
        }

        if (this.remainingTimeBeforeExpiryInSecond === 0) {
            this.sessionService.clearSessionInfoFromLocalStorage();
            this.modalService.show(SessionExpiredComponent);
            this.stop();
        }

        this._remainingTimeBeforeExpiryInSecond$.next(this.remainingTimeBeforeExpiryInSecond);
      }, 1000);
    }
  }
}

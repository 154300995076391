import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StaffProfile } from '../profile.model';

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileDetailsComponent {
  @Input() profileData?: StaffProfile;
}

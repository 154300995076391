import { Component, HostListener, OnInit } from '@angular/core';
import { InterlinesFaqUrl, ScootStaffTravelFaqUrl } from './faq.enum';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  isMobileView = false;
  isBodyShow = true;
  isInterlineBodyShow = true;

  readonly faqScootStaffTravelLinks = {
    StaffTravelPolicyLink: ScootStaffTravelFaqUrl.StaffTravelPolicyLink,
    StaffTravelGuideLink: ScootStaffTravelFaqUrl.StaffTravelGuideLink,
    FAQInfoLink: ScootStaffTravelFaqUrl.FAQInfoLink,
    ChopeMySeatLink: ScootStaffTravelFaqUrl.ChopeMySeatLink,
    ESCFeedbackFormLink: ScootStaffTravelFaqUrl.ESCFeedbackFormLink,
    ScootForSureLink: ScootStaffTravelFaqUrl.ScootForSureLink,
    ChatWithKakiLink: ScootStaffTravelFaqUrl.ChatWithKakiLink,
    ChatWithOzyLink: ScootStaffTravelFaqUrl.ChatWithOzyLink,
  };

  readonly faqInterlinesLinks = {
    SQInterlinePolicyAndFaqLink: InterlinesFaqUrl.SQInterlinePolicyAndFaqLink,
    LHInterlinePolicyAndFaqLink: InterlinesFaqUrl.LHInterlinePolicyAndFaqLink,
    SQorLHInterlinePolicyAndFaqLink: InterlinesFaqUrl.SQorLHInterlinePolicyAndFaqLink
  };

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobileView = window.innerWidth < AppConstants.MobileWidth.xs;
  }

  ngOnInit(): void {
    this.onResize();
  }

  onBodyToggle(): void {
    this.isBodyShow = !this.isBodyShow;
  }

  onInterlineBodyToggle(): void {
    this.isInterlineBodyShow = !this.isInterlineBodyShow;
  }
}

<div *ngIf="currentPage$ | async as currentPage">
  <div
    aria-label="Progression Bar"
    class="tw-w-full tw-bg-white tw-fixed tw-z-[999]"
    *ngIf="currentPage.includes(pageRoute.BookFlightSelection) || currentPage.includes(pageRoute.BookFlightPayment)"
  >
    <div class="tw-h-[65px] tw-mx-auto tw-pt-[20px] tw-pb-[20px]">
      <ul>
        <!--Booking-->
        <li
          aria-label="Progression Bar Booking"
          [ngClass]="
            currentPage.includes(pageRoute.BookFlightSelection) || currentPage.includes(pageRoute.BookFlightPayment)
              ? 'active'
              : 'inactive'
          "
        >
          <div class="icon">
            <div class="icon-circle"></div>
            <div class="icon-svg">
              <img aria-label="Progression Bar Booking icon" src="/assets/icons/svg/icon-progress-booking.svg" />
            </div>
          </div>
          <span class="label" aria-label="Progression Bar Booking label">{{
            'label.progression-bar.booking' | translate
          }}</span>
        </li>
        <!--Flights-->
        <li
          aria-label="Progression Bar Flights"
          [ngClass]="
            currentPage.includes(pageRoute.BookFlightSelection) || currentPage.includes(pageRoute.BookFlightPayment)
              ? 'active'
              : 'inactive'
          "
        >
          <div class="arrow">
            <img aria-label="Progression Bar Arrow icon" src="/assets/icons/svg/icon-progress-arrow.svg" />
          </div>
          <div class="icon">
            <div class="icon-circle"></div>
            <div class="icon-svg">
              <img aria-label="Progression Bar Flights icon" src="/assets/icons/svg/icon-progress-flight.svg" />
            </div>
          </div>
          <span class="label" aria-label="Progression Bar Flights label">{{
            'label.progression-bar.flights' | translate
          }}</span>
        </li>
        <!--Payment-->
        <li
          aria-label="Progression Bar Payment"
          [ngClass]="currentPage.includes(pageRoute.BookFlightPayment) ? 'active' : 'inactive'"
        >
          <div class="arrow">
            <img aria-label="Progression Bar Arrow icon" src="/assets/icons/svg/icon-progress-arrow.svg" />
          </div>
          <div class="icon">
            <div class="icon-circle"></div>
            <div class="icon-svg">
              <img aria-label="Progression Bar Payment icon" src="/assets/icons/svg/icon-progress-dollar.svg" />
            </div>
          </div>
          <span class="label" aria-label="Progression Bar Payment label">{{
            'label.progression-bar.payment' | translate
          }}</span>
        </li>
      </ul>
    </div>
  </div>
</div>

import { AfterViewChecked, Component } from '@angular/core';
import { ConfirmationModuleService, ConfirmationSettings } from '@it-application-hub/bookings';
import { Subject } from 'rxjs';
import { SessionService } from '../../session/session.service';
import { AppConstants } from '../../app.constants';
import { HeaderService } from '../../header/header.service';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements AfterViewChecked {
  private readonly _destroy$ = new Subject<void>();

  isLoaded = false;

  constructor(
    private confirmationModuleService: ConfirmationModuleService,
    private sessionService: SessionService,
    private headerService: HeaderService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit(): void {
    this.headerService.setCurrencyCode();
    this.initializeConfirmation();
  }

  private initializeConfirmation(): void {
    const setting = {
      paymentCodesExcluded: [],
      eventCategory: AppConstants.EventCategory,
      cultureCode: AppConstants.DefaultCultureCode,
      agentId: '',
      urlMarketPlaceWidget: '',
      urlRentalCar: '',
      exitWidget: false,
      showAmountSection: false,
      selectedCurrencyCode: {
        code: this.currencyCode
      },
      whiteLabelBanner: {
        isEnabled: false,
        allowSlideNext: false
      },
      fstBanner: false
    } as ConfirmationSettings;
    this.confirmationModuleService.initialize(setting);
    this.isLoaded = true;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  get currencyCode(): string {
    return this.sessionService.getSelectedCurrencyCode() ?? this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  ngAfterViewChecked(): void {
    const element = document.querySelector('tr-booking-confirmation .confirm-summary-status');
    if (element) {
      const elementMessage = document.querySelector(
        'tr-booking-confirmation .confirm-summary-status>div.confirmation-message'
      );
      if (!elementMessage) {
        element.insertAdjacentHTML(
          'beforeend',
          `<div class=\"confirmation-message lg:tw-w-[700px] tw-pt-5 \" aria-label=\"booking confirmation message\">${this.translatePipe.transform(
            'labels.bookings.confirmation.dashboard.confirmed-message'
          )}</div>`
        );
      }
    }
  }
}

<div 
  aria-label="Sub header section" 
  class="tw-px-3.75 tw-py-5 tw-items-center tw-bg-stone-900"
  *ngIf="{ selectedOrigin: selectedOrigin$ | async, selectedDestination: selectedDestination$ | async } as data">
  <div class="scoot-container tw-mx-auto" >
    <div class="tw-justify-between tw-items-center tw-flex tw-gap-1.25 xs:tw-gap-2.5">
      <!--Departing Flight-->
      <div
        aria-label="Departing div"
        class="selected tw-justify-start tw-items-center tw-gap-24 tw-flex"
      >
        <div class="label-div">
          <div aria-label="Departing label" class="title-type">
            {{ 'label.book-flight-selection-header.departing.label' | translate }}
          </div>
          <div aria-label="Departing container label" class="label-container">
            <!--Origin-->
            <div *ngIf="data.selectedOrigin" class="label-content">
              <div aria-label="Departing Origin route label" class="label-text tw-hidden xs:tw-block">
                {{ data.selectedOrigin.stationName }} ({{ data.selectedOrigin.stationCode }})
              </div>
              <div aria-label="Departing Origin mobile route label" class="label-text tw-block xs:tw-hidden">
                {{ data.selectedOrigin.stationCode }}
              </div>
            </div>
            <!--Arrow-->
            <div aria-label="Departing line div" class="tw-border-yellow-400 line-div"></div>
            <!--Destination-->
            <div *ngIf="data.selectedDestination" class="label-content">
              <div aria-label="Departing Destination route label" class="label-text tw-hidden xs:tw-block s:tw-block">
                {{ data.selectedDestination.stationName }} ({{ data.selectedDestination.stationCode }})
              </div>
              <div aria-label="Departing Destination mobile route label" class="label-text tw-block xs:tw-hidden">
                {{ data.selectedDestination.stationCode }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Arrow-->
      <div
        *ngIf="isReturn"
        class="tw-mr-1 tw-ml-1 xs:tw-mr-7 xs:tw-ml-7 tw-w-8 tw-justify-center tw-items-center tw-flex-col tw-flex"
      >
        <img aria-label="Arrow icon" class="tw-w-[30px] tw-h-[30px]" src="/assets/icons/svg/double-arrow.svg" />
      </div>
      <!--Returning Flight-->
      <div
        aria-label="Returning div"
        *ngIf="isReturn"
        class="selected tw-pr-7 tw-justify-start tw-items-center tw-gap-24 tw-flex"
      >
        <div class="label-div">
          <div aria-label="Returning label" class="title-type">
            {{ 'label.book-flight-selection-header.returning.label' | translate }}
          </div>
          <div aria-label="Returning container label" class="label-container">
             <!--Origin-->
             <div *ngIf="data.selectedDestination" class="label-content">
              <div aria-label="Returning Origin route label" class="label-text tw-hidden xs:tw-block s:tw-block">
                {{ data.selectedDestination.stationName }} ({{ data.selectedDestination.stationCode }})
              </div>
              <div aria-label="Returning Origin mobile route label" class="label-text tw-block xs:tw-hidden">
                {{ data.selectedDestination.stationCode }}
              </div>
            </div>
            <!--Arrow-->
            <div aria-label="Returning line div" class="tw-border-yellow-400 line-div"></div>
            <!--Destination-->
            <div *ngIf="data.selectedOrigin" class="label-content">
              <div aria-label="Returning Destination route label" class="label-text tw-hidden xs:tw-block">
                {{ data.selectedOrigin.stationName }} ({{ data.selectedOrigin.stationCode }})
              </div>
              <div aria-label="Returning Destination mobile route label" class="label-text tw-block xs:tw-hidden">
                {{ data.selectedOrigin.stationCode }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Modify-->
      <div
        *ngIf="bookingFlightType === bookingFlightTypeEnum.NewBooking"
        aria-label="Modify container"
        class="tw-justify-start tw-items-center tw-gap-1 tw-flex tw-cursor-pointer"
        (click)="onClickModify()"
      >
        <div class="tw-text-yellow-300 tw-text-lg tw-font-bold tw-leading-7">
          <p class="tw-hidden xs:tw-block" aria-label="Modify label">
            {{ 'label.book-flight-selection-header.modify.label' | translate }}
          </p>
        </div>
        <div class="tw-w-6 tw-h-6 tw-relative">
          <img
            class="tw-w-[40px] tw-h-[30px] tw-right-[0.5px] tw-bottom-[0.5px] tw-absolute"
            src="/assets/icons/svg/pencil.svg"
            aria-label="Modify icon"
          />
        </div>
      </div>
    </div>
  </div>
</div>

export enum DateFieldTypeEnum {
  Depart = 'Depart',
  Return = 'Return'
}

export enum GenderDescriptorEnum {
  Male = 'Male',
  Female = 'Female',
  None = ''
}

export enum GenderEnum {
  Male = 1,
  Female = 2,
  None = 0
}

export enum TitleEnum {
  Male = 'MR',
  Female = 'MS',
  Master = 'MASTER',
  Miss = 'MISS',
  None = ''
}

export enum LocationTypeEnum {
  Origin = 'Origin',
  Destination = 'Destination'
}

export enum PassengerTypeEnum {
  ADULT = 'ADT',
  CHILD = 'CHD',
  INFANT = 'INF'
}

export enum FlightTypeEnum {
  OneWay = 'One-way',
  Return = 'Return'
}

export enum FlightSelectionTypeEnum {
  Departing = 'departing',
  Returning = 'returning'
}

export enum FlightFareTypeEnum {
  Economy = 'Economy',
  ScootPlus = 'ScootPlus',
  ScootForSure = 'ScootForSure'
}

export enum FlightFareTypeCodeEnum {
  Economy = 'S2',
  ScootPlus = 'S1'
}

export enum FlightClassOfServiceEnum {
  EconomyAndScootPlus = 'EconomyAndScootPlus',
  ScootForSure = 'ScootForSure',
  None = ''
}

export enum YesOrNoEnum {
  Yes = 'Yes',
  No = 'No'
}

export enum BookingFlightTypeEnum {
  NewBooking = 'new-booking',
  Retrieve = 'retrieve',
  ResetBooking = 'reset-booking',
  ChangeFlight = 'change-flight',
  ManageAddOns = 'manage-addons'
}

export enum BookingCommentTypeEnum {
  Default = 0,
  Itinerary = 1,
  Manifest = 2,
  Alert = 3,
  Archive = 4
}

export enum CabinOfServiceCode {
  Economy = 'Y',
  ScootPlus = 'W'
}

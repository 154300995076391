<div *ngIf="booking$ | async as booking">
  <div class="tw-w-full tw-bg-zinc-100 tw-border-black tw-border-b tw-border-1">
    <div class="scoot-container tw-h-17.5 tw-mx-auto tw-pt-[80px]">
      <div class="tw-flex tw-flex-col tw-gap-5 tw-pl-7.5 tw-pr-7.5">
        <h1 aria-label="Manage Booking header label" class="tw-text-stone-900 tw-text-4xl tw-font-bold tw-leading-10">
          {{ 'label.banner.manage-my-booking.label' | translate }}
        </h1>
        <!--Tab Menu-->
        <ul aria-label="Tab container" class="tw-flex tw-bg-20 tw-w-1/2 tw-pt-4 tw-gap-4">
          <li
            aria-label="Upcoming tab section"
            class="tw-flex-1 tw-text-center tw-cursor-pointer"
            (click)="setActiveTab(tabMenuEnum.Upcoming, tripStatusEnum.Upcoming)"
          >
            <div class="tw-h-9 tw-flex-col tw-justify-between tw-items-start tw-inline-flex">
              <div class="tw-text-lg tw-font-bold tw-leading-7">
                <p
                  aria-label="Upcoming tab label"
                  [ngClass]="tabName === tabMenuEnum.Upcoming ? 'tw-text-black' : 'tw-text-slate-400'"
                >
                  {{ 'label.manage-booking.upcoming.tab.label' | translate }}
                </p>
              </div>
            </div>
            <div
              aria-label="Upcoming tab bottom border"
              class="tw-w-full tw-h-0.5"
              [ngClass]="tabName === tabMenuEnum.Upcoming ? 'tw-bg-black-100' : 'tw-bg-slate-100'"
            ></div>
          </li>
          <li
            aria-label="Past tab section"
            class="tw-flex-1 tw-text-center tw-cursor-pointer"
            (click)="setActiveTab(tabMenuEnum.Past, tripStatusEnum.Past)"
          >
            <div class="tw-h-9 tw-flex-col tw-justify-between tw-items-start tw-inline-flex">
              <div class="tw-text-lg tw-font-bold tw-leading-7">
                <p
                  aria-label="Past tab label"
                  [ngClass]="tabName === tabMenuEnum.Past ? 'tw-text-black' : 'tw-text-slate-400'"
                >
                  {{ 'label.manage-booking.past.tab.label' | translate }}
                </p>
              </div>
            </div>
            <div
              aria-label="Past tab bottom border"
              class="tw-w-full tw-h-0.5"
              [ngClass]="tabName === tabMenuEnum.Past ? 'tw-bg-black-100' : 'tw-bg-slate-100'"
            ></div>
          </li>
          <li
            aria-label="Cancelled tab section"
            class="tw-flex-1 tw-text-center tw-cursor-pointer"
            (click)="setActiveTab(tabMenuEnum.Cancelled, tripStatusEnum.Cancelled)"
          >
            <div class="tw-h-9 tw-flex-col tw-justify-between tw-items-start tw-inline-flex">
              <div class="tw-text-lg tw-font-bold tw-leading-7">
                <p
                  aria-label="Cancelled tab label"
                  [ngClass]="tabName === tabMenuEnum.Cancelled ? 'tw-text-black' : 'tw-text-slate-400'"
                >
                  {{ 'label.manage-booking.cancelled.tab.label' | translate }}
                </p>
              </div>
            </div>
            <div
              aria-label="Cancelled tab bottom border"
              class="tw-w-full tw-h-0.5"
              [ngClass]="tabName === tabMenuEnum.Cancelled ? 'tw-bg-black-100' : 'tw-bg-slate-100'"
            ></div>
          </li>
        </ul>
        <!--End Tab Menu-->
      </div>
    </div>
  </div>
  <div class="scoot-container tw-h-17.5 tw-mx-auto">
    <div class="tw-gap-5 tw-pl-7.5 tw-pr-7.5">
      <!--Booking Lists-->
      <div
        *ngIf="booking?.data?.length"
        aria-label="Bookings section"
        class="tw-flex tw-flex-col tw-gap-5 tw-w-full tw-pt-5"
      >
        <app-flight-item
          *ngFor="let booking of booking.data; index as bookingIndex"
          [booking]="booking"
          [bookingIndex]="bookingIndex"
          [tabName]="tabName"
          (onProceed)="openFlightSearchModal($event)"
          (onCancelled)="openCancelJourneyModal($event)"
        >
        </app-flight-item>
      </div>
      <div
        class="tw-pt-[45px] tw-text-center tw-text-stone-500 tw-text-lg tw-font-bold tw-leading-7"
        *ngIf="!booking?.data || booking?.data?.length == 0"
      >
        {{ 'label.booking-no-flights-available' | translate }}
      </div>
      <!--End Booking Lists-->

      <div
        *ngIf="booking?.data?.length"
        class="tw-flex tw-justify-center tw-pt-[30px]"
        aria-label="my booking pagination"
      >
        <app-pagination-bar
          #pagination
          [totalItems]="booking.totalFlightCount"
          [itemsPerPage]="pageSize"
          (changePage)="onChangePage($event)"
        >
        </app-pagination-bar>
      </div>
    </div>
  </div>
</div>

<app-flight-search-modal
  [showModal]="showFlightSearchModal"
  (closeModal)="closeFlightSearchModal()"
></app-flight-search-modal>
<app-cancel-journey
  [showModal]="showCancelJourneyModal"
  [booking]="cancelBooking"
  (closeModal)="closeCancelJourneyModal($event)"
></app-cancel-journey>

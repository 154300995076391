<div
  *ngIf="currentPage$ | async as currentPage"
  class="tw-flex tw-justify-between tw-items-center tw-flex-row tw-gap-1.25 xs:tw-gap-2.5"
>
  <div class="header-item">
    <div class="tw-w-20 tw-h-20 tw-relative">
      <a aria-label="Home link" [href]="pageRoute.Home">
        <img aria-label="logo" src="/assets/images/logo.svg" class="tw-left-1.75 tw-top-4.5 tw-absolute" />
      </a>
    </div>
    <button
      aria-label="Book A Flight button"
      [routerLink]="pageRoute.BookFlight"
      routerLinkActive="activeButton"
      *ngIf="
        !currentPage.includes(pageRoute.BookFlightSelection) &&
        !currentPage.includes(pageRoute.BookFlightPayment) &&
        !currentPage.includes(pageRoute.Error)
      "
      class="tw-hidden tw-w-32 header-button tw-bg-white xs:tw-flex"
    >
      <label aria-label="Book A Flight label" routerLinkActive="activeLabel" class="header-label tw-text-stone-900">
        {{ 'label.banner.book-a-flight.label' | translate }}
      </label>
    </button>
    <button
      aria-label="Manage My Booking button"
      [routerLink]="pageRoute.ManageMyBooking"
      routerLinkActive="activeButton"
      *ngIf="
        !currentPage.includes(pageRoute.BookFlightSelection) &&
        !currentPage.includes(pageRoute.BookFlightPayment) &&
        !currentPage.includes(pageRoute.Error)
      "
      class="tw-hidden tw-w-[11.2rem] header-button tw-bg-white xs:tw-flex"
    >
      <label aria-label="Manage My Booking label" routerLinkActive="activeLabel" class="header-label tw-text-stone-900">
        {{ 'label.banner.manage-my-booking.label' | translate }}
      </label>
    </button>
    <button
      aria-label="FAQ button"
      [routerLink]="pageRoute.FAQ"
      routerLinkActive="activeButton"
      *ngIf="
        !currentPage.includes(pageRoute.BookFlightSelection) &&
        !currentPage.includes(pageRoute.BookFlightPayment) &&
        !currentPage.includes(pageRoute.Error)
      "
      class="tw-hidden header-button tw-bg-white xs:tw-flex"
    >
      <label aria-label="FAQ label" routerLinkActive="activeLabel" class="header-label tw-text-stone-900">
        {{ 'label.banner.FAQ.label' | translate }}
      </label>
    </button>
    <button
      aria-label="SQ/OAL button"
      *ngIf="
        !isCadetPilot &&
        !currentPage.includes(pageRoute.BookFlightSelection) &&
        !currentPage.includes(pageRoute.BookFlightPayment) &&
        !currentPage.includes(pageRoute.Error)
      "
      class="tw-hidden tw-w-24 header-button tw-bg-white xs:tw-flex"
      (click)="onSQOAL()"
    >
      <label aria-label="SQ/OAL label" class="header-label tw-text-stone-900">
        {{ 'label.banner.SQOAL.label' | translate }}
      </label>
    </button>
  </div>
  <div class="header-item">
    <div id="currency-section" class="tw-relative header-allowance" (mouseover)="onShowCurrencySelection()" (mouseleave)="showCurrencyModal = false">
      <button
        aria-label="Currency button"
        class="!tw-w-auto !tw-h-9 tw-flex tw-justify-center tw-items-center tw-p-2.5 tw-gap-0.5 !tw-text-3.5 tw-bg-yellow-20 tw-rounded-full"
        [ngClass]="{ 
          '!tw-bg-slate-950': showCurrencyModal,
          'tw-cursor-pointer': currentPage.includes(pageRoute.BookFlightSelection) || currentPage.includes(pageRoute.BookFlightPayment)
        }"
        *ngIf="!currentPage.includes(pageRoute.Error)"
      >
        <div [ngClass]="{ 'currency-button-arrow': showCurrencyModal }"></div>
        <label
          *ngIf="currencyCode$ | async as currencyCode"
          aria-label="Currency label"
          class="header-label tw-text-stone-900"
          [ngClass]="{ '!tw-text-slate-50': showCurrencyModal }"
        >
          {{ currencyCode | currencySymbol }} {{ currencyCode }}
        </label>
      </button>
      <div *ngIf="{ currencies: currencies$ | async, externalRates : externalRates$ | async } as data" >
        <div *ngIf="data.currencies && data.externalRates">
          <app-currency-selection
          *ngIf="showCurrencyModal"
          [currencies]="data.currencies"
          [externalRates]="data.externalRates"
          (closeCurrencySelection)="closeCurrencySelection()"
        ></app-currency-selection>
        </div>
      </div>
    </div>

    <button
      aria-label="Profile button"
      [routerLink]="pageRoute.Profile"
      routerLinkActive="activeButton"
      #rla="routerLinkActive"
      *ngIf="
        !currentPage.includes(pageRoute.BookFlightSelection) &&
        !currentPage.includes(pageRoute.BookFlightPayment) &&
        !currentPage.includes(pageRoute.Error)
      "
      class="!tw-w-auto !tw-h-9 tw-flex tw-justify-center tw-items-center tw-p-2.5 tw-gap-0.5 !tw-text-3.5 tw-bg-yellow-20 tw-rounded-full"
    >
      <div class="tw-p-[2px] tw-relative">
        <img
          aria-label="Profile icon"
          src="/assets/icons/svg/person.svg"
          src="{{ rla.isActive ? '/assets/icons/svg/person-white.svg' : '/assets/icons/svg/person.svg' }}"
          class="tw-w-2.66 tw-h-2.66"
        />
      </div>
      <label
        aria-label="Profile label"
        routerLinkActive="activeLabel"
        class="tw-hidden header-label tw-text-stone-900 xs:tw-flex"
      >
        {{ 'label.banner.profile.label' | translate }}
      </label>
    </button>
    <button
      aria-label="Toggle button"
      *ngIf="!currentPage.includes(pageRoute.BookFlightSelection) && !currentPage.includes(pageRoute.BookFlightPayment)"
      (click)="showDropDownMenu = !showDropDownMenu"
      (clickOutside)="showDropDownMenu = false"
      [ngClass]="showDropDownMenu ? 'activeButton' : ''"
      class="xs:tw-hidden tw-flex tw-flex-row tw-items-start tw-p-2.5 tw-gap-0.5 tw-w-9 tw-h-9 tw-bg-white tw-rounded-full"
    >
      <div class="tw-w-4 tw-h-2.33 tw-relative">
        <img
          aria-label="Hamburger icon"
          src="{{ showDropDownMenu ? '/assets/icons/svg/hamburger-white.svg' : '/assets/icons/svg/hamburger.svg' }}"
          [ngClass]="showDropDownMenu ? 'tw-top-[0px] tw-left-0' : 'tw-top-[3.33px] tw-left-0.5'"
          class="tw-absolute"
        />
      </div>
    </button>
    <button
      aria-label="Summary button"
      *ngIf="currentPage.includes(pageRoute.BookFlightSelection) || currentPage.includes(pageRoute.BookFlightPayment)"
      class="tw-hidden !tw-w-auto !tw-h-9 tw-flex tw-justify-center tw-items-center tw-gap-1 !tw-text-3.5 tw-bg-yellow-100 tw-border-b tw-border-stone-900"
    >
      <label aria-label="Summary label" class="header-label tw-text-stone-900">
        {{ banner.TotalSummary | number : '1.2-2' }}
      </label>
      <div class="tw-p-[2px] tw-relative">
        <img aria-label="Summary icon" src="/assets/icons/svg/arrow-down.svg" class="tw-w-3 tw-h-3" />
      </div>
    </button>
    <button
      *ngIf="showLogOut"
      aria-label="Logout button"
      class="tw-hidden !tw-w-auto !tw-h-9 xs:tw-flex tw-justify-center tw-items-center tw-p-2.5 tw-gap-0.5 !tw-text-3.5 tw-bg-yellow-20 tw-rounded-full"
      (click)="onLogout()"
    >
      <div class="tw-p-[2px] tw-relative">
        <img aria-label="Logout icon" src="/assets/icons/svg/log-out.svg" class="tw-w-2.66 tw-h-2.66" />
      </div>
      <label aria-label="Logout label" class="header-label tw-text-stone-900">
        {{ 'label.banner.logout.label' | translate }}
      </label>
    </button>
  </div>
</div>

<div *ngIf="currentPage$ | async as currentPage">
  <div aria-label="Menu Dropdown" *ngIf="showDropDownMenu">
    <div
      class="tw-absolute xs:tw-right-0 tw-right-[5px] tw-bottom-[8px] tw-min-w-[200px] tw-translate-y-full tw-rounded-br-5 tw-rounded-bl-5"
    >
      <div class="tw-relative">
        <img
          aria-label="Triangle shape"
          class="tw-visible xs:tw-invisible tw-absolute tw-top-[-10px] tw-right-[20px]"
          src="/assets/icons/svg/triangle.svg"
          alt=""
        />
        <div class="tw-bg-stone-50 tw-rounded-bl-5 tw-rounded-br-5">
          <div class="tw-flex tw-flex-col tw-rounded-bl-5 tw-rounded-br-5 tw-text-3.75/6">
            <div
              class="tw-custom-menu-item"
              aria-label="Book A Flight menu section"
              [routerLink]="pageRoute.BookFlight"
              *ngIf="!currentPage.includes(pageRoute.Error)"
            >
              <p aria-label="Book A Flight menu label">
                {{ 'label.banner.book-a-flight.label' | translate }}
              </p>
            </div>
            <div
              class="tw-custom-menu-item"
              aria-label="Manage My Booking menu section"
              [routerLink]="pageRoute.ManageMyBooking"
              *ngIf="!currentPage.includes(pageRoute.Error)"
            >
              <p aria-label="Manage My Booking menu label">
                {{ 'label.banner.manage-my-booking.label' | translate }}
              </p>
            </div>
            <div
              class="tw-custom-menu-item"
              aria-label="FAQ menu section"
              [routerLink]="pageRoute.FAQ"
              *ngIf="!currentPage.includes(pageRoute.Error)"
            >
              <p aria-label="FAQ menu label">
                {{ 'label.banner.FAQ.label' | translate }}
              </p>
            </div>
            <div
              class="tw-custom-menu-item"
              aria-label="SQ/OAL menu section"
              *ngIf="!isCadetPilot && !currentPage.includes(pageRoute.Error)"
            >
              <a aria-label="SQ/OAL menu label" (click)="onSQOAL()" class="tw-text-stone-900">
                {{ 'label.banner.SQOAL.label' | translate }}
              </a>
            </div>
            <div *ngIf="showLogOut" class="tw-custom-menu-item" aria-label="Logout menu section">
              <a aria-label="Logout menu label" (click)="onLogout()" class="tw-text-stone-900">
                {{ 'label.banner.logout.label' | translate }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

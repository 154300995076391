<div
  *ngIf="isSubmitPayment"
  class="tw-fixed tw-inset-0 tw-z-[9999] tw-bg-black-100 tw-bg-opacity-20 tw-transition-opacity"
></div>
<div aria-label="Page Content" class="page-content">
  <div class="scoot-container tw-mx-auto tw-mb-20 tw-mt-[20px]">
    <tr-add-ons-dashboard class="add-ons-dashboard"></tr-add-ons-dashboard>
    <div class="tw-mb-[20px]">
      <span
        aria-label="review summary title label"
        class="tw-text-stone-900 tw-font-bold tw-font-['Montserrat'] tw-leading-[48px] tw-text-[26px]"
      >
        {{ 'labels.bookings.summary.title' | translate }}
      </span>
    </div>
    <app-ofw-declaration
      *ngIf="
        bookingFlightType !== bookingFlightTypeEnum.ManageAddOns &&
        bookingFlightType !== bookingFlightTypeEnum.ChangeFlight"
    >
    </app-ofw-declaration>
    <app-foc-credit
      *ngIf="withFOC"
      [booking]="booking"
      [totalCreditsRemaining]="bookingDetail.totalCreditsRemaining"
      [totalEntitlement]="bookingDetail.totalEntitlement"
    >
    </app-foc-credit>
    <div *ngIf="refreshBooking; then reviewSummary; else reviewSummary"></div>
    <ng-template #reviewSummary>
      <tr-booking-review-summary></tr-booking-review-summary>
    </ng-template>
    <app-voucher *ngIf="bookingDetail.isFlightScootForSure && bookingFlightType !== bookingFlightTypeEnum.ManageAddOns">
    </app-voucher>
    <div *ngIf="refreshBooking; then paymentSummary; else paymentSummary"></div>
    <ng-template #paymentSummary>
      <app-payment></app-payment>
    </ng-template>
  </div>
</div>
<div aria-label="Footer section" class="footer">
  <div class="scoot-container tw-mx-auto">
    <div class="tw-flex tw-flex-col tw-gap-5 tw-justify-end">
      <div class="tw-flex tw-px-5 tw-mt-auto tw-gap-2.5 xs:tw-self-end xs:tw-px-0">
        <button
          *ngIf="bookingFlightType !== bookingFlightTypeEnum.ManageAddOns"
          aria-label="Back button"
          class="tw-btn tw-btn-tertiary footer-btn xs:tw-w-26"
          (click)="onBack()"
        >
          {{ 'label.button.back.label' | translate }}
        </button>
        <button
          [disabled]="isMakePaymentDisabled"
          (click)="makePayment()"
          aria-label="Make Payment button"
          class="tw-btn tw-btn-primary footer-btn xs:tw-w-40"
        >
          {{ 'label.button.make-payment.label' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

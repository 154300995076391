<div class="md:tw-flex tw-mb-7">
  <div
    class="tw-w-full tw-bg-white tw-rounded-tl-2xl xs:tw-rounded-tr-none xs:tw-rounded-bl-2xl tw-rounded-tr-2xl tw-border tw-border-b-neutral-300 xs:tw-relative"
  >
    <app-flight-detail
      attr.aria-label="{{ selectedFlightType }} - Flight Details"
      [journey]="journey"
      [selectedFlightType]="selectedFlightType"
    >
    </app-flight-detail>
  </div>
  <div
    class="md:tw-w-[360px] tw-p-[20px] tw-bg-white tw-rounded-bl-2xl xs:tw-rounded-bl-none xs:tw-rounded-tr-2xl tw-rounded-br-2xl tw-border tw-border-b-neutral-300"
  >
    <!--Economy & ScootPlus-->
    <div
      *ngIf="selectedFlightClassOfService === flightClassOfServices.EconomyAndScootPlus"
      class="tw-text-center tw-h-auto tw-p-1.5 tw-bg-sky-600 tw-rounded-tl-2xl tw-rounded-tr-2xl tw-rounded-bl-sm tw-rounded-br-sm tw-flex-col tw-justify-start tw-items-center tw-flex xs:tw-min-w-[320px]"
    >
      <div
        attr.aria-label="{{ selectedFlightType }} - Standby passengers count section"
        class="tw-text-white tw-leading-4.5"
      >
        <div *ngFor="let segment of journey.segments">
          <div
            *ngFor="let leg of segment.legs"
            attr.aria-label="{{ selectedFlightType }} - Standby passengers count detail"
          >
            <span
              *ngIf="journey.stops"
              attr.aria-label="{{ selectedFlightType }} - Standby passengers for {{ leg.designator.origin }}{{leg.designator.destination}}"
              class="tw-pr-[5px] tw-text-[10px]"
            >
              {{ leg.designator.origin }} - {{ leg.designator.destination }}
            </span>
            <span
              attr.aria-label="{{ selectedFlightType }} - Standby passengers count for {{leg.designator.origin}}{{ leg.designator.destination }}"
              class="tw-text-[13px] tw-font-bold"
            >
              {{ leg.standByCount }}
              {{
                (leg.standByCount > 1
                  ? 'label.book-flight.selection.flight-card-fare.standby-passengers.label'
                  : 'label.book-flight.selection.flight-card-fare.standby-passenger.label'
                ) | translate
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--ScootForSure-->
    <div
      *ngIf="flightClassOfServices.ScootForSure.includes(selectedFlightClassOfService ?? '')"
      class="tw-text-center tw-h-auto tw-p-1.5 tw-bg-yellow-100 tw-rounded-tl-2xl tw-rounded-tr-2xl tw-rounded-bl-sm tw-rounded-br-sm tw-flex-col tw-justify-start tw-items-center tw-flex xs:tw-min-w-[320px]"
    >
      <span
        attr.aria-label="{{ selectedFlightType }} - ScootForSure Firm Ticket label"
        class="tw-text-[13px] tw-font-bold tw-leading-4.5"
      >
        {{ 'label.book-flight-selection.flight-card-fare.firm-ticket.label' | translate }}
      </span>
    </div>
    <!--Fare Details-->
    <div class="tw-flex tw-w-full">
      <app-flight-fare
        *ngFor="let fare of journey.fares"
        attr.aria-label="{{ selectedFlightType }} - Flight Fare section"
        [fare]="fare"
        [journey]="journey"
        [selectedFlightType]="selectedFlightType"
        [selectedFlightClassOfService]="selectedFlightClassOfService"
        [totalVoucherPoints]="totalVoucherPoints"
        [isSelected]="isSelected"
        [hasErrors]="hasErrors"
        [class.selected]="isSelected"
        [class.economy]="fare.type === flightFareType.Economy"
        [class.scootPlus]="fare.type === flightFareType.ScootPlus"
        [class.scootForSure]="fare.type === flightFareType.ScootForSure"
        [ngClass]="{ 'tw-basis-1/2': journey.fares.length > 1 }"
        class="tw-flex-1 tw-p-[15px] tw-items-center"
      >
      </app-flight-fare>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { SessionService } from '../session.service';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { SessionExpireService } from '../session-expire.service';
import { ModalService } from '../../shared/components/modal/modal.service';
import { MsalAuthService } from '../../shared/service/msal-auth/msal-auth.service';
import { PageRoute } from '../../shared/enums/page-routes.enum';

@Component({
  selector: 'app-session-expiring',
  templateUrl: './session-expiring.component.html',
  styleUrls: ['./session-expiring.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SessionExpiringComponent implements OnInit, OnDestroy {
  sessionRemainingTime$!: Observable<number>;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private sessionService: SessionService,
    private sessionExpireService: SessionExpireService,
    private msalAuthService: MsalAuthService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getSessionRemainingTime();
  }

  refreshTokenAndCloseModal(): void {
    this.sessionService
      .refreshSessionToken$()
      .pipe(take(1))
      .subscribe(() => {
        this.msalAuthService.acquireTokenSilent();
        this.sessionExpireService.reset();
      });

    this.modalService.hide();
  }

  logInAgain(): void {
    this.modalService.hide();
    if (this.msalAuthService.hasSSOLogin && !this.msalAuthService.authenticated) {
      this.sessionService.clearSessionStorage();
      this.msalAuthService.login();
    }
    else {
      this.sessionService.clearSessionInfoFromLocalStorage();
      window.location.href = PageRoute.Home
    }
  }

  getSessionRemainingTime(): void {
    this.sessionRemainingTime$ = this.sessionExpireService.remainingTimeBeforeExpiryInSecond$.pipe(
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export enum ScootStaffTravelFaqUrl {
  StaffTravelPolicyLink = 'https://flyscoot.service-now.com/hrportal?id=kb_article&sys_id=44fc63b6dba7c1103265a2ddd39619e3&table=kb_knowledge',
  StaffTravelGuideLink = 'https://flyscoot.service-now.com/hrportal?id=kb_article&sys_id=3774563d1bacf5109613a9bfe54bcbb1&spa=1',
  FAQInfoLink = 'https://flyscoot.service-now.com/hrportal?id=kb_article&sys_id=dc9dc692db906d901720972ed3961956&table=kb_knowledge',
  ChopeMySeatLink = 'https://chopemyseat.flyscoot.com/aliss-staff-standby/',
  ESCFeedbackFormLink = 'https://flyscoot.service-now.com/hrportal?id=sc_cat_item&sys_id=8dda595adb582700c4b95040cf961958',
  ScootForSureLink = 'https://flyscoot.service-now.com/hrportal?sys_kb_id=3774563d1bacf5109613a9bfe54bcbb1&id=kb_article_view&sysparm_rank=2&sysparm_tsqueryId=b00c954397d0ce90cbb7b9ef9153af8f',
  ChatWithKakiLink = 'https://insidescoot.workplace.com/chat/t/109430240557494',
  ChatWithOzyLink = 'https://insidescoot.workplace.com/chat/t/108379344349171',
}

export enum InterlinesFaqUrl {
  SQInterlinePolicyAndFaqLink = 'https://flyscoot.service-now.com/hrportal?sys_kb_id=5bd87a9adb5588105e147f868c9619a4&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=bd044f9e1b95f1109613a9bfe54bcb9a',
  LHInterlinePolicyAndFaqLink = 'https://flyscoot.service-now.com/hrportal?sys_kb_id=4079db6a1b29f950ccf18661f54bcb57&id=kb_article_view&sysparm_rank=1&sysparm_tsqueryId=a3c497e91b4ef110ccf18661f54bcb25',
  SQorLHInterlinePolicyAndFaqLink = 'https://flyscoot.service-now.com/hrportal?id=sc_cat_item&sys_id=d5375d1adb18f340c4b95040cf9619cb'
}

<div class="tw-pb-[20px]" aria-label="Pagination bar">
  <div class="tw-justify-start tw-items-center tw-gap-4 tw-inline-flex">
    <div aria-label="Pagination bar label" class="tw-text-zinc-800 tw-text-base tw-font-bold tw-leading-normal">
      {{ 'label.pagination.page' | translate }}
    </div>
    <button
      class="prev-btn tw-p-[3px] tw-pl-[5px] -tw-rotate-180"
      [disabled]="disabledPrev"
      (click)="onChangePage(currentPage - 1)"
    >
      <img aria-label="Pagination bar Arrow left icon" src="/assets/icons/svg/right-chev-arrow.svg" />
    </button>
    <button
      class="btn"
      *ngFor="let page of arrayNumberPages"
      [attr.aria-label]="'Page button - ' + page"
      [ngClass]="{
        'btn-selected': currentPage === page,
        'btn-not-selected': currentPage !== page
      }"
      (click)="onChangePage(page)"
    >
      {{ page }}
    </button>
    <button class="next-btn tw-p-[3px] tw-pl-[5px]" [disabled]="disabledNext" (click)="onChangePage(currentPage + 1)">
      <img aria-label="Pagination bar Arrow right icon" src="/assets/icons/svg/right-chev-arrow.svg" />
    </button>
  </div>
</div>

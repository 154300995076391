<div
  aria-label="Flight search modal"
  [hidden]="!showModal"
  [ngClass]="{
    'tw-justify-center tw-items-center tw-flex': showModal
  }"
  class="modal"
  role="dialog"
  aria-modal="true"
>
  <div
    aria-label="Flight search modal background"
    class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
  ></div>
  <div aria-label="Flight search modal content" 
    class="scoot-container tw-mx-auto tw-w-full tw-relative tw-rounded-5 tw-shadow  tw-bg-zinc-100 tw-rounded-5">
     <!--Content-->
     <div aria-label="Flight search modal detail section" class="tw-w-full">
      <img
        aria-label="Flight search modal close icon"
        class="tw-absolute tw-right-[2.25rem] tw-top-[2.25rem] tw-cursor-pointer"
        (click)="onCloseModal()"
        src="/assets/icons/svg/exit.svg"
        alt="exit"
      />
      <app-flight-search #flightSearch 
        [isModify]="true" 
        [loadFormForModify]="showModal"
        (onClickLetsGo)="onClickLetsGo()">
      </app-flight-search>
    </div>
    <!--End Content-->
  </div>
</div>

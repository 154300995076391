import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject, take, takeUntil } from 'rxjs';
import { Booking, FareOverideRequest } from '../booking.model';
import { BookingService } from '../booking.service';
import { FOCPassengerData } from '../flight/flight.model';
import { PaymentService } from '../payment/payment.service';

@Component({
  selector: 'app-foc-credit',
  templateUrl: './foc-credit.component.html',
  styleUrls: ['./foc-credit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FocCreditComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  focPassengers$ = new BehaviorSubject<FOCPassengerData[]>([]);

  isBodyShow = true;
  isDisabledFOCRedeemCheckbox = true;
  isFOCCreditRedeemCheck = false;
  departBaseFare: number = 0;
  returnBaseFare: number = 0;
  departJourneyKey = '';
  returnJourneyKey = '';
  isReturn = false;
  creditsToDeduct = 1;
  totalCreditsToDeduct = 0;
  totalCreditsRemaining = 0;

  constructor(
    private readonly bookingService: BookingService, 
    private readonly paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.isFOCCreditRedeemCheck = this.bookingService.isFocBooking;
    this.focPassengers$ = this.paymentService.focPassengers$;
    this.totalCreditsRemaining = this.bookingService.bookingDetail.totalCreditsRemaining;

    this.bookingService.booking$
      .pipe(takeUntil(this._destroy$))
      .subscribe((booking) => {
        this.departBaseFare = 0;
        this.returnBaseFare = 0;

        if (booking) {
          this.isReturn = booking.journeys.length > 1;
          this.creditsToDeduct = booking.journeys.length;
          this.departBaseFare += booking.journeys[0].publishedFare;
          this.departJourneyKey = booking?.journeys[0].journeyKey ?? '';

          if (booking.journeys.length > 1) {
            this.returnBaseFare += booking.journeys[1].publishedFare;
            this.returnJourneyKey = booking.journeys[1]?.journeyKey ?? '';
          }
        }

        this.checkCreditsRemainingValidity();
      });
  }

  onBodyToggle(): void {
    this.isBodyShow = !this.isBodyShow;
  }

  onClickedRedeemCheckbox(isFOCCreditRedeemCheck: boolean): void {
    this.isFOCCreditRedeemCheck = !isFOCCreditRedeemCheck;
    this.bookingService.setIsFocBooking(this.isFOCCreditRedeemCheck);

    let fareOverideRequest: FareOverideRequest[] = [
      {
        journeyKey: this.departJourneyKey,
        amount: this.isFOCCreditRedeemCheck ? 0 : this.departBaseFare
      }
    ];

    if (this.isReturn) {
      fareOverideRequest.push({
        journeyKey: this.returnJourneyKey,
        amount: this.isFOCCreditRedeemCheck ? 0 : this.returnBaseFare
      })
    }

    this.bookingService
      .fareOverride$(fareOverideRequest)
      .pipe(takeUntil(this._destroy$))
      .subscribe((res) => {
        if (res) {
          this.calculateCreditsRemaining();
        }
      });
  }

  private calculateCreditsRemaining(): void {
    this.paymentService.focPassengers$.pipe(take(1))
    .subscribe((focPassengers) => {
      focPassengers.forEach((passenger) => {
        if (this.isFOCCreditRedeemCheck) {
          passenger.creditsRemaining -= this.creditsToDeduct;
        } else {
          passenger.creditsRemaining += this.creditsToDeduct;
        }
      });

      this.paymentService.reloadPaymentPage$.next(true);
    });

  }

  private checkCreditsRemainingValidity(): void {
    this.paymentService.focPassengers$.pipe(take(1))
    .subscribe((focPassengers) => {
      const passengerCeditsToDeduct = this.isFOCCreditRedeemCheck ? 0 : this.creditsToDeduct;
      const hasPassengersWithMaxCredits = focPassengers.some(
        (passenger) => passenger.creditsRemaining < passengerCeditsToDeduct
      );

      this.totalCreditsToDeduct = focPassengers.length * this.creditsToDeduct;
      this.isDisabledFOCRedeemCheckbox = hasPassengersWithMaxCredits || (this.totalCreditsRemaining - this.totalCreditsToDeduct) < 0;  
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

<div class="tw-flex tw-flex-col md:tw-min-w-[130px]">
  <div attr.aria-label="{{selectedFlightType}} - Fare Type" class="tw-flex-1 tw-text-center tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7">
    <div attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Title" *ngIf="fare.type === flightFareType.Economy">
      {{ 'label.book-flight.economy-uppercase.label' | translate }}
    </div>
    <div attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Title" *ngIf="fare.type === flightFareType.ScootPlus">
      {{ 'label.book-flight.scootplus-uppercase.label' | translate }}
    </div>
    <div attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Title" *ngIf="fare.type === flightFareType.ScootForSure">
      {{ 'label.book-flight.scootforsure-uppercase.label' | translate }}
    </div>
  </div>
  <div class="tw-pb-[5px]">
    <!--Economy & ScootPlus-->
    <div
      *ngIf="fare.type === flightFareType.Economy || fare.type === flightFareType.ScootPlus"
      attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Price"
      class="tw-flex-1 tw-text-center tw-text-stone-900 tw-text-base tw-font-bold tw-leading-normal"
    >
     <span *ngIf="selectedCurrency$ | async as selectedCurrencyCode">
      {{getPriceBasedOnSelectedCurrency(fare.price ?? 0, selectedCurrencyCode) | currency : currencyCode + ' '}}
     </span>
    </div>
    <!--ScootForSure-->
    <div
      *ngIf="fare.type === flightFareType.ScootForSure"
      attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Points"
      class="tw-flex-1 tw-text-center tw-text-stone-900 tw-text-base tw-font-bold tw-leading-normal"
    >
      <span *ngIf="fare.points === 0" attr.aria-label="{{selectedFlightType}} - No available point label"> - </span>
      <span *ngIf="(fare.points ?? 0) >= 1" attr.aria-label="{{selectedFlightType}} - Point label">
        {{ fare.points }}
        {{ (fare.points ?? 0 > 1 ? 'label.book-flight.points.label' : 'label.book-flight.point.label') | translate }}
      </span>
    </div>
  </div>

  <!--Fare Details-->
  <div class="tw-flex-1">
    <div class="tw-w-full">
      <img
        (click)="showDetails(fare)"
        attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Info icon"
        class="tw-w-3.5 tw-h-3.5 tw-mx-auto tw-cursor-pointer"
        src="assets/icons/svg/tooltip.svg"
      />
    </div>
  </div>
  <div class="tw-flex tw-justify-center tw-items-center tw-pt-[5px] tw-pb-[10px] tw-text-center">
    <div class="tw-text-stone-900 tw-text-xs">
      <div *ngFor="let segment of journey.segments">
        <div
          *ngFor="let leg of segment.legs"
          class="tw-w-fit tw-pl-[6px] tw-pr-[6px] tw-pt-[4px] tw-pb-[4px]"
          [ngClass]="{
            'tw-bg-neutral-200': fare.type === flightFareType.Economy,
            'tw-bg-yellow-100': fare.type === flightFareType.ScootPlus || fare.type === flightFareType.ScootForSure
          }"
        >
          <div
            attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Leg Origin and Destination"
            class="tw-p-[3px] tw-font-medium"
            *ngIf="journey.stops"
          >
            {{ leg.designator.origin }} - {{ leg.designator.destination }}
          </div>
          <div
            class="tw-p-[3px] tw-font-bold tw-leading-none tw-p-1"
            attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Seats Available">
            <span *ngIf="fare.type === 'ScootForSure'">
              <span *ngIf="(fare.availableCount ?? 0) > 6">
                <span attr.aria-label="{{selectedFlightType}} - {{ fare.type }}-{{ leg.designator.origin }}{{leg.designator.destination}} seats left count">
                  {{ 'label.book-flight-selection.flight-card-fare.scoot-for-sure.default-seats.label' | translate }}
                </span>
                {{ 'label.book-flight-selection.flight-card-fare.seats-left.label' | translate }}
              </span>
              <span *ngIf="(fare.availableCount ?? 0) <= 6">
                <span 
                  attr.aria-label="{{selectedFlightType}} - {{ fare.type }}-{{ leg.designator.origin }}{{leg.designator.destination}} seats left count">
                  {{ fare.availableCount }} 
                </span>
                {{ 'label.book-flight-selection.flight-card-fare.seats-left.label' | translate }}
              </span>
            </span>
            <span *ngIf="fare.type === 'Economy' || fare.type === 'ScootPlus'">
              <span attr.aria-label="{{selectedFlightType}} - {{ fare.type }}-{{ leg.designator.origin }}{{leg.designator.destination}} seats left count">
                {{ getSeatsLeft(fare.type, leg) }}
              </span>
              {{ 'label.book-flight-selection.flight-card-fare.seats-left.label' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="tw-flex-1 tw-items-center tw-text-center">
    <button
      *ngIf="!isSelected"
      attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Select button"
      (click)="onSelect()"
      [disabled]="
        hasErrors ||
        (isScootForSureSelected && (!fare.availableCount || totalVoucherPoints < (fare.points ?? 0))) ||
        (isDisabledEconomy && fare.type === flightFareType.Economy) ||
        (isDisabledScootPlus && fare.type === flightFareType.ScootPlus)
      "
      class="tw-btn tw-btn-primary tw-items-center"
    >
      {{ 'label.button.select.label' | translate }}
    </button>
    <a
      *ngIf="isSelected"
      attr.aria-label="{{selectedFlightType}} - {{ fare.type }} Fare Edit Selection"
      (click)="onEdit()"
      class="tw-text-sky-600 tw-text-xs tw-font-semibold tw-underline tw-leading-none"
    >
      {{ 'label.button.edit-selection.label' | translate }}
    </a>
  </div>
</div>

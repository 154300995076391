<div class="wrapper">
  <app-header class="header"></app-header>
  <app-progression-bar [ngClass]="{ 'progress-bar': withProgressBar }"></app-progression-bar>
  <div aria-label="content" class="content">
    <router-outlet></router-outlet>
  </div>
</div>
<app-notification></app-notification>
<app-session-timeout></app-session-timeout>
<app-modal></app-modal>
<app-loader *ngIf="loading"></app-loader>
<app-dialog-outlet></app-dialog-outlet>
<div
  aria-label="Page Content"
  class="tw-w-full flight-search-content"
  [ngClass]="{
    'bg-mobile': isMobile && !isModify,
    'flight-search-content-main': !isModify
  }"
>
  <div class="scoot-container tw-mx-auto tw-w-full tw-p-[30px]">
    <div
      aria-label="Flight Search Section"
      [ngClass]="{
        'tw-flex tw-flex-col tw-relative tw-align-middle tw-bg-white tw-rounded-2xl tw-p-5 tw-gap-5 xs:tw-gap-7.5 xs:tw-p-7.5':
          !isModify
      }"
    >
      <!--Form-->
      <form aria-label="Flight Search Group" [formGroup]="staffTravelPortalForm">
        <div class="tw-flex tw-flex-col tw-gap-5">
          <h2 aria-label="Flight Search Header" class="tw-text tw-text-6.5 tw-font-bold">
            <span>
              {{
                (isModify
                  ? 'label.book-flight-search.modify-staff-travel-portal.header'
                  : 'label.book-flight-search.staff-travel-portal.header'
                ) | translate
              }}
            </span>
          </h2>
          <!--Tab-->
          <nav>
            <ul class="tw-flex tw-bg-20">
              <li
                aria-label="Return nav tab"
                class="tw-flex-1 tw-text-center tw-cursor-pointer"
                (click)="setIsReturn(true)"
              >
                <div class="tw-h-9 tw-flex-col tw-justify-between tw-items-start tw-inline-flex">
                  <div class="tw-text-lg tw-font-bold tw-leading-7">
                    <p aria-label="Return nav label" [ngClass]="_isReturn ? 'tw-text-black' : 'tw-text-slate-400'">
                      {{ 'label.book-flight-search.return.label' | translate }}
                    </p>
                  </div>
                </div>
                <div class="tw-w-full tw-h-0.5" [ngClass]="_isReturn ? 'tw-bg-yellow-300' : 'tw-bg-slate-200'"></div>
              </li>
              <li
                aria-label="One-way nav tab"
                class="tw-flex-1 tw-text-center tw-cursor-pointer"
                (click)="setIsReturn(false)"
              >
                <div class="tw-h-9 tw-flex-col tw-justify-between tw-items-start tw-inline-flex">
                  <div class="tw-text-lg tw-font-bold tw-leading-7">
                    <p aria-label="One-way nav label" [ngClass]="_isReturn ? 'tw-text-slate-400' : 'tw-text-black'">
                      {{ 'label.book-flight-search.one-way.label' | translate }}
                    </p>
                  </div>
                </div>
                <div class="tw-w-full tw-h-0.5" [ngClass]="!_isReturn ? 'tw-bg-yellow-300' : 'tw-bg-slate-200'"></div>
              </li>
            </ul>
          </nav>
          <div class="tw-flex tw-flex-col tw-gap-5">
            <!--Desktop From/To Location-->
            <div class="tw-hidden xs:tw-block">
              <div class="tw-flex xs:tw-flex-row tw-flex-col tw-gap-5">
                <!--From Location-->
                <div class="tw-flex tw-flex-col tw-gap-1.25 xs:tw-basis-1/2 tw-basis-1">
                  <p aria-label="Origin label" class="tw-text-3.75">
                    {{ 'label.book-flight-search.from.label' | translate }}
                  </p>
                  <div class="tw-relative" [class.disabled-field]="fromCityConfigTitles.control.disabled">
                    <img
                      class="tw-z-30 tw-mt-2 tw-absolute"
                      [src]="isSelectingOrigin ? '/assets/icons/svg/destination.svg' : '/assets/icons/svg/map-pin.svg'"
                      aria-label="Origin icon"
                    />
                    <tr-dropdown
                      (click)="onSelectOrigin()"
                      aria-label="Origin dropdown field"
                      formControlName="fromCityAirport"
                      [config]="fromCityConfigTitles"
                    >
                    </tr-dropdown>
                    <div
                      class="tw-z-30 tw-absolute tw-items-end tw-right-7 tw-top-0.5 tw- tw-h-7.5 tw-px-[5px] tw-py-[3px] tw-bg-stone-900 tw-rounded"
                      *ngIf="originMarket"
                    >
                      <div
                        aria-label="Origin Station Code"
                        class="tw-text-center tw-text-white tw-text-3.75 tw-font-bold tw-leading-normal"
                      >
                        {{ originMarket.stationCode }}
                      </div>
                    </div>
                  </div>
                  <app-error-input
                    [abstractControl]="[staffTravelPortalForm.controls['fromCityAirport']]"
                    aria-label="Origin dropdown field error"
                  >
                  </app-error-input>
                </div>
                <!--To Location-->
                <div class="tw-flex tw-flex-col tw-gap-1.25 xs:tw-basis-1/2 tw-basis-1">
                  <p aria-label="Destination label" class="tw-text-3.75">
                    {{ 'label.book-flight-search.to.label' | translate }}
                  </p>
                  <div class="tw-relative">
                    <div [class.disabled-field]="destinationShowLoader || toCityConfigTitles.control.disabled">
                      <img
                        class="tw-z-30 tw-mt-2 tw-absolute"
                        [src]="
                          isSelectingDestination ? '/assets/icons/svg/destination.svg' : '/assets/icons/svg/map-pin.svg'
                        "
                        aria-label="Destination icon"
                      />
                      <tr-dropdown
                        (click)="onSelectDestination()"
                        aria-label="Destination dropdown field"
                        formControlName="toCityAirport"
                        [config]="toCityConfigTitles"
                        [showLoader]="destinationShowLoader"
                      >
                      </tr-dropdown>
                      <div
                        class="tw-z-30 tw-absolute tw-items-end tw-right-7 tw-top-0.5 tw- tw-h-7.5 tw-px-[5px] tw-py-[3px] tw-bg-stone-900 tw-rounded"
                        *ngIf="destinationMarket"
                      >
                        <div
                          aria-label="Destination Station Code"
                          class="tw-text-center tw-text-white tw-text-3.75 tw-font-bold tw-leading-normal"
                        >
                          {{ destinationMarket.stationCode }}
                        </div>
                      </div>
                    </div>
                    <app-tooltip
                      aria-label="Destination tooltip"
                      *ngIf="destinationShowLoader"
                      class="tw-w-full tw-absolute tw-bottom-0"
                    ></app-tooltip>
                  </div>
                  <app-error-input
                    [abstractControl]="[staffTravelPortalForm.controls['toCityAirport']]"
                    aria-label="Destination dropdown field error"
                  >
                  </app-error-input>
                </div>
              </div>
            </div>
            <!--Mobile From/To Location-->
            <div class="tw-block xs:tw-hidden">
              <div
                class="tw-border tw-h-full tw-border-neutral-200 tw-rounded-2xl"
                [ngClass]="
                  (staffTravelPortalForm.controls['fromCityAirport'].touched &&
                    staffTravelPortalForm.controls['fromCityAirport'].status === 'INVALID') ||
                  (staffTravelPortalForm.controls['toCityAirport'].touched &&
                    staffTravelPortalForm.controls['toCityAirport'].status === 'INVALID')
                    ? 'tw-border-red-500'
                    : 'tw-border-neutral-200'
                "
              >
                <div class="tw-flex tw-flex-row tw-py-4">
                  <!--From-->
                  <div class="tw-flex-1 tw-text-center">
                    <div class="tw-relative" [class.disabled-mobile-field]="fromCityConfigTitles.control.disabled">
                      <div aria-label="Origin mobile div" (click)="showOriginMarketModal(true)">
                        <div class="tw-justify-between tw-items-start tw-inline-flex">
                          <p aria-label="Origin mobile label">
                            {{ 'label.book-flight-search.from.label' | translate }}
                          </p>
                        </div>
                        <div aria-label="Origin mobile value" class="tw-relative">
                          <p class="tw-text-center tw-text-stone-300" *ngIf="!originMarket">
                            {{ 'label.book-flight-search.select-city.placeholder' | translate }}
                          </p>
                          <div *ngIf="originMarket">
                            <p class="tw-text-center tw-text-stone-900 tw-font-bold">
                              {{ originMarket.stationCode }}
                            </p>
                            <p class="tw-text-center tw-text-stone-900 tw-text-sm">
                              {{ originMarket.countryName }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Plane-->
                  <div class="tw-w-6 tw-py-2.5 tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                    <div class="tw-w-6 tw-h-6 tw-relative">
                      <img src="/assets/icons/svg/flight.svg" aria-label="Plane icon" />
                    </div>
                  </div>

                  <!--To-->
                  <div class="tw-flex-1 tw-text-center">
                    <div class="tw-relative" [class.disabled-mobile-field]="toCityConfigTitles.control.disabled">
                      <div aria-label="Destination mobile div" (click)="showDestinationMarketModal(true)">
                        <div class="tw-justify-between tw-items-start tw-inline-flex">
                          <p aria-label="Destination mobile label">
                            {{ 'label.book-flight-search.to.label' | translate }}
                          </p>
                        </div>
                        <div aria-label="Destination mobile value" class="tw-relative">
                          <p class="tw-text-center tw-text-stone-300" *ngIf="!destinationMarket">
                            {{ 'label.book-flight-search.select-city.placeholder' | translate }}
                          </p>
                          <div *ngIf="destinationMarket">
                            <p class="tw-text-center tw-text-stone-900 tw-font-bold">
                              {{ destinationMarket.stationCode }}
                            </p>
                            <p class="tw-text-center tw-text-stone-900 tw-text-sm">
                              {{ destinationMarket.countryName }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-error-input
                class="tw-items-center tw-justify-between"
                [abstractControl]="[
                  staffTravelPortalForm.controls['fromCityAirport'],
                  staffTravelPortalForm.controls['toCityAirport']
                ]"
                aria-label="Routes dropdown field error"
              >
              </app-error-input>
            </div>
            <div class="tw-flex xs:tw-flex-row tw-flex-col tw-gap-5">
              <!--Passengers-->
              <div class="tw-flex tw-flex-col tw-gap-1.25 xs:tw-basis-1/2 tw-basis-1">
                <!--Desktop Passengers-->
                <div class="tw-relative tw-basis-1/2 tw-hidden xs:tw-block">
                  <label aria-label="Passengers label" class="tw-block tw-font-medium tw-text-3.75 tw-leading-normal">
                    {{ 'label.book-flight-search.passengers.label' | translate }}
                  </label>
                  <div class="tw-relative">
                    <div [class.disabled-field]="passengersShowLoader || passengersConfigTitle.control.disabled">
                      <img
                        aria-label="Passengers icon"
                        class="tw-z-30 tw-mt-[0.9rem] tw-absolute"
                        src="/assets/icons/svg/passengers.svg"
                      />
                      <tr-dropdown-multiselect
                        aria-label="Passengers dropdown field"
                        formControlName="passengers"
                        [config]="passengersConfigTitle"
                        [isMobile]="false"
                        [showLoader]="passengersShowLoader"
                        (click)="scrollDocument()"
                      >
                      </tr-dropdown-multiselect>
                    </div>
                    <app-tooltip
                      aria-label="Passengers tooltip"
                      *ngIf="passengersShowLoader"
                      class="tw-w-full tw-absolute tw-bottom-0"
                    ></app-tooltip>
                  </div>
                  <app-error-input
                    [abstractControl]="[staffTravelPortalForm.controls['passengers']]"
                    aria-label="Passengers dropdown field error"
                  >
                  </app-error-input>
                </div>
                <!--Mobile Passengers-->
                <div class="tw-block xs:tw-hidden">
                  <div class="tw-relative">
                    <div
                      class="tw-py-2.5 tw-rounded-5 tw-border tw-justify-between"
                      [ngClass]="
                        staffTravelPortalForm.controls['passengers'].touched &&
                        staffTravelPortalForm.controls['passengers'].status === 'INVALID'
                          ? 'tw-border-red-500'
                          : 'tw-border-neutral-200'
                      "
                      aria-label="Passenger mobile div"
                      [class.disabled-field]="passengersShowLoader || passengersConfigTitle.control.disabled"
                      (click)="onShowPassengerModal()"
                    >
                      <div class="tw-relative">
                        <div class="tw-flex">
                          <img
                            class="tw-ml-2.5"
                            src="/assets/icons/svg/passengers.svg"
                            aria-label="Passengers icon"
                            id="passengerIcon"
                          />
                          <label
                            *ngIf="!passengersConfigTitle.control.value.length"
                            class="tw-w-full tw-px-2 tw-text-stone-300"
                          >
                            {{ 'label.book-flight-search.select-passengers.placeholder' | translate }}
                          </label>
                          <label
                            *ngIf="passengersConfigTitle.control.value.length"
                            class="tw-w-full tw-pl-[1rem] tw-pr-[1.8rem]"
                          >
                            {{ passengers.join(', ') }}
                          </label>
                          <app-spinner *ngIf="passengersShowLoader" class="tw-mr-2.5"></app-spinner>
                          <img
                            *ngIf="!passengersShowLoader"
                            class="tw-mr-2.5"
                            src="assets/icons/svg/arrow-down.svg"
                            alt="arrow-down"
                          />
                        </div>
                      </div>
                    </div>
                    <app-tooltip
                      aria-label="Passengers mobile tooltip"
                      *ngIf="passengersShowLoader"
                      class="tw-w-full tw-h-full tw-absolute tw-top-0"
                    ></app-tooltip>
                  </div>
                  <app-error-input
                    [abstractControl]="[staffTravelPortalForm.controls['passengers']]"
                    aria-label="Passengers mobile dropdown error"
                  >
                  </app-error-input>
                </div>
              </div>
              <!--Departure & Return date-->
              <div
                (clickOutside)="onClickOutsideCalendar()"
                class="tw-flex tw-flex-col tw-gap-1.25 xs:tw-basis-1/2 tw-basis-1"
                [ngClass]="_isReturn ? 'md:tw-flex-row' : ''"
              >
                <!--Departure Date-->
                <div class="tw-basis-1/2 tw-relative">
                  <label aria-label="Depart date label" for="departDate" class="tw-text-3.75 tw-block">
                    {{ 'label.book-flight-search.depart-date.label' | translate }}
                  </label>
                  <div [class.disabled-field]="staffTravelPortalForm.controls['departDate'].disabled">
                    <img
                      class="tw-z-30 tw-mt-2 tw-absolute"
                      src="/assets/icons/svg/date-range.svg"
                      aria-label="Depart date icon"
                    />
                    <div class="tw-relative tw-flex tw-h-8.75">
                      <input
                        aria-label="Depart date field"
                        readonly
                        type="text"
                        #departDate
                        id="departDate"
                        name="departDate"
                        class="tw-text-input tw-indent-8 tw-mt-0.75"
                        formControlName="departDate"
                        [placeholder]="'label.book-flight-search.select-depart-date.placeholder' | translate"
                        [value]="_departDate?.value ?? ''"
                        (click)="setDateFieldSelected(dateFieldTypes.Depart)"
                      />
                    </div>
                  </div>
                  <app-error-input
                    [abstractControl]="[staffTravelPortalForm.controls['departDate']]"
                    aria-label="Depart date field error"
                  >
                  </app-error-input>
                  <app-flight-search-calendar
                    #flightCalendar
                    id="flightCalendar"
                    [showDatepicker]="datepicker.show"
                    [departureDateControl]="staffTravelPortalForm.controls['departDate']"
                    [returnDateControl]="staffTravelPortalForm.controls['returnDate']"
                    [terminationDate]="userInfo?.terminationDate ?? ''"
                    (onCloseCalendar)="onCloseCalendar()"
                  >
                  </app-flight-search-calendar>
                </div>
                <!--Return Date-->
                <div *ngIf="_isReturn" class="tw-basis-1/2">
                  <label aria-label="Return date label" for="returnDate" class="tw-text-3.75 tw-block">
                    {{ 'label.book-flight-search.return-date.label' | translate }}
                  </label>
                  <div
                    class="tw-relative"
                    [class.disabled-field]="staffTravelPortalForm.controls['returnDate'].disabled"
                  >
                    <img
                      aria-label="Return date icon"
                      class="tw-z-30 tw-mt-2 tw-absolute"
                      src="/assets/icons/svg/date-range.svg"
                    />
                    <div class="tw-relative tw-flex tw-h-8.75">
                      <input
                        aria-label="Return date field"
                        readonly
                        type="text"
                        #returnDate
                        *ngIf="staffTravelPortalForm.controls['returnDate']"
                        id="returnDate"
                        name="returnDate"
                        class="tw-text-input tw-indent-8 tw-mt-0.75"
                        formControlName="returnDate"
                        [placeholder]="'label.book-flight-search.select-return-date.placeholder' | translate"
                        [value]="_returnDate?.value ?? ''"
                        (click)="setDateFieldSelected(dateFieldTypes.Return)"
                      />
                    </div>
                    <app-error-input
                      *ngIf="staffTravelPortalForm.controls['returnDate']"
                      [abstractControl]="[staffTravelPortalForm.controls['returnDate']]"
                      aria-label="Return date field error"
                    >
                    </app-error-input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <!--Footer-->
      <div
        class="tw-flex tw-flex-col tw-gap-5 tw-justify-end xs:tw-flex-row xs:tw-gap-7.5"
        [ngClass]="{ 'tw-pt-[30px]': isModify }"
      >
        <div>
          <button
            aria-label="Go button"
            (click)="onSubmit()"
            [disabled]="disableSubmit"
            class="tw-btn tw-btn-primary tw-w-36 tw-h-10 tw-px-5 tw-py-2 tw-justify-center tw-flex tw-items-center"
          >
            <span>
              {{
                (isModify ? 'label.book-flight-search.modify-flight.label' : 'label.book-flight-search.lets-go.label')
                  | translate
              }}
            </span>
            <img aria-label="Path icon" src="/assets/icons/svg/path.svg" class="tw-ml-2" />
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-flight-search-calendar-mobile-modal
  [isCalendarModalVisible]="datepicker.modalShow"
  [terminationDate]="userInfo?.terminationDate ?? ''"
  [departureDateControl]="staffTravelPortalForm.controls['departDate']"
  [returnDateControl]="staffTravelPortalForm.controls['returnDate']"
  (closeCalendarModal)="onCloseCalendarModal()"
>
</app-flight-search-calendar-mobile-modal>
<app-dropdown-modal
  *ngIf="fromCityConfigTitles"
  [isVisible]="isOriginModalVisible"
  [config]="fromCityConfigTitles"
  [label]="'From'"
  [title]="'Origin'"
  (closeModal)="showOriginMarketModal(false)"
>
</app-dropdown-modal>
<app-dropdown-modal
  *ngIf="toCityConfigTitles"
  [isVisible]="isDestinationModalVisible"
  [config]="toCityConfigTitles"
  [label]="'To'"
  [title]="'Destination'"
  [showLoader]="destinationShowLoader"
  (closeModal)="showDestinationMarketModal(false)"
>
</app-dropdown-modal>
<app-flight-search-passenger-modal
  [isModalVisible]="showPassengerModal"
  [modalData]="passengerModalData"
  (closeModal)="onClosePassengerModal()"
>
</app-flight-search-passenger-modal>

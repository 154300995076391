<div
  *ngIf="notification$ | async as notification"
  aria-label="Notification modal"
  [hidden]="!notification"
  [ngClass]="{
    'tw-justify-center tw-items-center tw-flex': notification
  }"
  class="tw-z-modal tw-fixed tw-top-0 tw-left-0 tw-right-0 t tw-p-4 tw-overflow-x-hidden tw-overflow-y-auto md:tw-inset-0 tw-h-[calc(100%-1rem)] tw-max-h-full"
  role="dialog"
  aria-modal="true"
>
  <div
    aria-label="Notification modal background"
    (click)="onCloseModal(notification)"
    class="tw-fixed tw-inset-0 tw-bg-black-100 tw-bg-opacity-80 tw-transition-opacity"
  ></div>

  <div
    aria-label="Notification content"
    class="tw-relative tw-w-full tw-max-h-full"
    [ngClass]="notification.widthClass ? 'xs:' + notification.widthClass : 'xs:tw-w-[30%]'"
  >
    <div
      class="tw-relative tw-rounded-5 tw-shadow dark:tw-bg-gray-700"
      [ngClass]="notification.textAlignClass ? notification.textAlignClass : 'tw-text-center tw-justify-center'"
    >
      <div class="tw-bg-white tw-rounded-5 tw-p-[30px]">
        <div class="tw-flex tw-flex-col tw-grow tw-gap-4">
          <div
            class="tw-flex"
            *ngIf="notification.imageUrl"
            [ngClass]="notification.textAlignClass ? notification.textAlignClass : 'tw-justify-center'"
          >
            <img
              aria-label="Notification image"
              src="{{ notification.imageUrl }}"
              width="245px"
              height="183px"
            />
          </div>
          <div class="tw-flex tw-flex-col tw-gap-4">
            <h2 aria-label="Notification title" class="tw-text-[26px] tw-font-bold">
              {{ notification.title | translate }} {{ notification.addTextAfterTitle }}
            </h2>
            <p
              aria-label="Notification text"
              class="tw-leading-6 tw-text-3.75"
              [innerHTML]="notification.text | translate | safe : 'html'"
            ></p>
          </div>
          <div class="tw-justify-center tw-flex tw-items-center tw-mt-auto tw-gap-2.5">
            <button
              *ngIf="notification.showCancelButton"
              (click)="onCloseModal(notification)"
              type="button"
              aria-label="Notification exit button"
              class="btn xs:tw-w-auto tw-w-full tw-px-5 tw-whitespace-nowrap"
              [ngClass]="notification.cancelButtonClass ? notification.cancelButtonClass : 'tw-btn-secondary'"
            >
              {{ notification.cancelButtonText ?? 'notification.button.ok' | translate }}
            </button>
            <button
              *ngIf="notification.showConfirmButton"
              (click)="onConfirm(notification)"
              type="button"
              aria-label="Notification confirm button"
              class="btn xs:tw-w-auto tw-w-full tw-px-5 tw-whitespace-nowrap"
              [ngClass]="notification.confirmButtonClass ? notification.confirmButtonClass : 'tw-btn-primary'"
            >
              {{ notification.confirmButtonText ?? 'notification.button.ok' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

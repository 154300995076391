import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Beneficiary, StaffProfile } from '../profile.model';
import { ProfileService } from '../profile.service';
import { PassengerTypeEnum } from '../../booking/flight/flight.enum';

@Component({
  selector: 'app-profile-foc-entitlement',
  templateUrl: './profile-foc-entitlement.component.html',
  styleUrls: ['./profile-foc-entitlement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileFocEntitlementComponent {

  @Input() profileData?: StaffProfile;

  constructor(private readonly profileService: ProfileService) {}

  getBeneficiaries(profile: StaffProfile | undefined): Beneficiary[] {
    if (profile) {
      return profile.beneficiaries.filter((beneficiary) =>
        this.profileService.getPassengerType(beneficiary.dateOfBirth, new Date()) != PassengerTypeEnum.INFANT);
    }
    else {
      return [];
    }
  }

  isInfant(beneficiary: Beneficiary | undefined): boolean {
    if (beneficiary) {
      return this.profileService.getPassengerType(beneficiary.dateOfBirth, new Date()) == PassengerTypeEnum.INFANT;
    }
    else {
      return false;
    }
  }

  isFOCValid(profile: StaffProfile | undefined): boolean {
    if (profile) {
      return new Date(profile.focEntitlements[0].endDate) > new Date();
    }
    else {
      return false;
    }
  }
}

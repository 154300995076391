export enum ProfileTabsTitleEnum {
  profile = 'label.banner.profile.label',
  focEntitlement = 'label.profile.foc.entitlement-tab.label',
  travelBeneficiaries = 'label.profile.my-voucher.travel-beneficiaries.label',
  myVouchers = 'label.profile.my-voucher.label'
}

export enum ProfileTabsIconEnum {
  profile = '/assets/icons/svg/person.svg',
  focEntitlement = '/assets/icons/svg/credit_card.svg',
  travelBeneficiaries = '/assets/icons/svg/passengers.svg',
  myVouchers = '/assets/icons/svg/my-vouchers.svg'
}
export enum TitleConstant {
  MR = 'MR',
  MS = 'MS'
}
export enum GenderConstant {
  Male = 'Male',
  Female = 'Female'
}

import { StaffProfile, StaffProfileRequest, StaffProfileVoucherRequest, Voucher, VoucherList } from './../profile.model';
import { ChangeDetectionStrategy, Component, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { AppConstants } from '../../app.constants';
import { PaginationBarComponent } from '../../pagination-bar/pagination-bar.component';
import { SessionService } from '../../session/session.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-profile-my-voucher',
  templateUrl: './profile-my-voucher.component.html',
  styleUrls: ['./profile-my-voucher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileMyVoucherComponent {
  private _destroy$ = new Subject<void>();
  readonly voucherStatusCode = AppConstants.VoucherStatus;
  @ViewChild('pagination') pagination!: PaginationBarComponent;

  dateToday = new Date();
  voucherList = new BehaviorSubject<Voucher[]>([]);
  displayedVoucherList = new BehaviorSubject<Voucher[]>([]);
  isVoucherShow: boolean[] = [];
  isMobileView = false;
  isVoucherActive = true;
  pageRoute = PageRoute;
  _profileData?: StaffProfile;
  totalVoucherCount = 0;

  currentPage = 1;
  pageSize = 10;
  totalount = 0;

  constructor(
    private sessionService: SessionService,
    private staffProfileService: ProfileService
  ) {
    this.onVoucherViewToggle(this.isVoucherActive);
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.isMobileView = window.innerWidth < AppConstants.MobileWidth.xs;
  }

  onVoucherViewToggle(isVoucherActive: boolean): void {
    this.currentPage = 1;
    this.isVoucherActive = isVoucherActive;
    this.getvoucherList();
    this.isVoucherShow = [];
  }

  viewVoucher(index: number): void {
    this.isVoucherShow[index] = !this.isVoucherShow[index];
  }

  isDateExpired(date: Date): boolean {
    const dateToday = new Date();
    dateToday.setHours(0, 0, 0, 0);
    const expiryDate = new Date(date);
    return dateToday > expiryDate;
  }

  onChangePage(page: number): void {
    this.currentPage = page;
    this.getvoucherList();
  }

  private getvoucherList() {
    const request = {
      idToken: this.sessionService.getCurrentUserIdTokenFromLocalStorage(),
      isVoucherActive: this.isVoucherActive,
      page: this.currentPage,
      pageSize: this.pageSize
    } as StaffProfileVoucherRequest;

    this.staffProfileService.retrieveStaffProfileVouchers$(request)
      .pipe(takeUntil(this._destroy$))
      .subscribe((response: VoucherList) => {
        if (response) {
          this.totalVoucherCount = response.totalVoucherCount;
          this.initializeData(response);
        }
      });
  }

  private initializeData(voucherList: VoucherList): void {
    this.onResize();
    if (voucherList.vouchers) {
      this.displayedVoucherList.next(voucherList.vouchers);
      if (this.pagination) {
        this.pagination.currentPage = this.currentPage;
        this.pagination.totalItems = this.totalVoucherCount;
        this.pagination.ngOnInit();
      }
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { BookingService } from '../booking/booking.service';
import { FlightSearchService } from '../booking/flight/flight-search/flight-search.service';
import { Router } from '@angular/router';
import { PageRoute } from '../shared/enums/page-routes.enum';
import { SessionService } from '../session/session.service';
import { FlightService } from '../booking/flight/flight.service';
import { AppConstants } from '../app.constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(
    private flightSearchService: FlightSearchService,
    private flightService: FlightService,
    private sessionService: SessionService,
    private bookingService: BookingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.flightSearchService.resetForm();
    this.flightSearchService.setFormData(null)
    this.flightService.resetFlightSelectedData();
    this.bookingService.resetBookingInfo();
    this.sessionService.setCollectedCurrencyCode(AppConstants.DefaultCurrencyCode);
    this.sessionService.setCurrencyCodeToSessionStorage(AppConstants.DefaultCurrencyCode);
    this.sessionService.setSelectedCurrencyCode(AppConstants.DefaultCurrencyCode);
    this.sessionService.setSelectedCurrencyDescription(AppConstants.DefaultCurrencyDescription);
    if (this.sessionService.authenticated){
      this.router.navigateByUrl(PageRoute.BookFlight);
    }
  }
}
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private readonly _error$ = new BehaviorSubject<HttpErrorResponse>({} as HttpErrorResponse);
  private readonly _errorState$ =  new BehaviorSubject<boolean>(false);

  readonly error$ = this._error$.asObservable();
  readonly errorState$ = this._errorState$.asObservable();

  setErrorState(state: boolean): void{
    this._errorState$.next(state);
  }

  setError(error: HttpErrorResponse): void{
    this._error$.next(error);
  }
}
import { ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, Observable, BehaviorSubject, take } from 'rxjs';
import { ProfileService } from './profile.service';
import { StaffProfile } from './profile.model';
import { AppConstants } from '../app.constants';
import { ProfileTabsIconEnum, ProfileTabsTitleEnum } from './profile.enum';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();
  private readonly _profileData$ = new BehaviorSubject<StaffProfile | null>(null);

  profileData$!: Observable<StaffProfile | null>;
  profileMenuTitle = ProfileTabsTitleEnum;
  profileMenuIcon = ProfileTabsIconEnum;
  showMenu = false;
  isMobile = false;
  selectedMenu = {
    icon: ProfileTabsIconEnum.profile,
    title: ProfileTabsTitleEnum.profile
  }

  constructor(private readonly profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.profileData$ = this._profileData$.asObservable();
    this.profileService.staffProfile$.pipe(take(1)).subscribe((data) => {
      this._profileData$.next(data);
    }); 
    this.validateSettings();
  }

  onSelectMenu(title: ProfileTabsTitleEnum, icon: ProfileTabsIconEnum ): void {
    if (this.isMobile) {
      this.showMenu = !this.showMenu;
    }

    this.selectedMenu = {
      icon: icon,
      title: title
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.validateSettings();
  }

  validateSettings(): void {
    this.isMobile = window.innerWidth < AppConstants.MobileWidth.xs;
    this.showMenu = !this.isMobile;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

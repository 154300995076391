<div aria-label="My Voucher Section" class="tw-bg-white tw-rounded-[20px] tw-overflow-hidden tw-p-[30px]">
  <!--Headeer-->
  <div class="tw-w-[100%]">
    <span aria-label="My Voucher header title" class="tw-text-stone-900 tw-text-[26px] tw-font-bold tw-leading-9">{{
      'label.profile.my-voucher.label' | translate
    }}</span>
  </div>
  <div class="tw-w-[100%]">
    <span aria-label="My Voucher detail text" class="tw-text-stone-900 tw-text-[15px] tw-font-medium tw-leading-normal">
      {{ 'label.profile.my-voucher.details.label' | translate }} </span
    ><a
      [routerLink]="pageRoute.FAQ"
      aria-label="My Voucher detail faq text"
      class="tw-text-sky-600 tw-text-[15px] tw-font-semibold tw-no-underline tw-leading-normal"
    >
      {{ 'label.profile.my-voucher.details.faq-page.label' | translate }}</a
    >
  </div>
  <!--Tab menu-->
  <div class="tw-w-[100%] tw-mt-[20px]">
    <div aria-label="My Voucher table buttons" class="div-tab-menu">
      <button
        aria-label="My Voucher active button"
        class="tab-menu"
        [ngClass]="{
          'tab-menu-highlight': isVoucherActive,
          'tw-text-black-40': !isVoucherActive
        }"
        (click)="onVoucherViewToggle(true)"
      >
        {{ 'label.profile.my-voucher.active.label' | translate }}
      </button>
      <button
        aria-label="My Voucher expired used button"
        class="tab-menu"
        [ngClass]="{
          'tab-menu-highlight': !isVoucherActive,
          'tw-text-black-40': isVoucherActive
        }"
        (click)="onVoucherViewToggle(false)"
      >
        {{ 'label.profile.my-voucher.expired-used.label' | translate }}
      </button>
    </div>
  </div>
  <!--Voucher table-->
  <div
    aria-label="My Voucher table"
    class="tw-w-[100%] xs:tw-mt-[20px]"
    *ngIf="displayedVoucherList | async as vouchers"
  >
    <div aria-label="My Voucher table header" class="table-header" *ngIf="vouchers.length > 0">
      <div aria-label="My Voucher table col" class="tw-w-[calc(100%-200px)] tw-px-[10px]">
        <span class="tw-text-stone-900 tw-text-[13px] tw-font-bold tw-leading-[18px] tw-text-white">
          {{ 'label.profile.voucher.active.label' | translate }}
        </span>
      </div>
      <div aria-label="My Voucher table col" class="tw-w-[118px] table-col">
        <span class="tw-text-white">
          {{ 'labels.payments.vouchers.expiry.validTill' | translate }}
        </span>
      </div>
      <div aria-label="My Voucher table col" class="tw-w-[80px] table-col">
        <span class="tw-text-white">
          {{ 'label.profile.my-voucher.status.label' | translate }}
        </span>
      </div>
    </div>
    <div aria-label="My Voucher table body">
      <div aria-label="My Voucher table row" class="table-row" *ngFor="let voucher of vouchers; let i = index">
        <div aria-label="My Voucher Title" class="title" (click)="viewVoucher(i)">
          <span
            attr.aria-label="My Voucher {{ i }} voucher"
            class="tw-text-stone-900 xs:tw-text-[13px] xxs:tw-text-[15px] tw-font-bold tw-leading-[18px]"
            >{{ 'label.book-flight.scootforsure.label' | translate }} {{ voucher.amount }}
            {{ 'label.profile.my-voucher.points.label' | translate }}</span
          >
          <img
            attr.aria-label="My Voucher {{ i }} chevron arrow"
            class="tw-transition-transform xs:tw-hidden"
            [ngClass]="{ 'tw-rotate-[0deg]': isVoucherShow[i], 'tw-rotate-[-180deg]': !isVoucherShow[i] }"
            src="/assets/icons/svg/Chev-Arrow.svg"
            alt="Chev Arrow"
          />
        </div>
        <div
          aria-label="My Vocher details"
          class="tw-flex tw-items-center tw-justify-around xs:tw-w-[200px] xxs:tw-w-[100%]"
          *ngIf="(isVoucherShow[i] && isMobileView) || !isMobileView"
        >
          <div aria-label="My Voucher table col" class="table-details-col xs:tw-w-[60%] xxs:tw-w-[50%]">
            <div class="tw-w-[100%] xs:tw-hidden">
              <span>{{ 'labels.payments.vouchers.expiry.validTill' | translate }}</span>
            </div>
            <div class="tw-w-[100%] tw-flex xs:tw-justify-center">
              <span attr.aria-label="My Voucher {{ i }} valid till">{{ voucher.expiry | date : 'dd MMM yyyy' }}</span>
            </div>
          </div>
          <div aria-label="My Voucher table col" class="table-details-col xxs:tw-w-[50%] xs:tw-w-[40%]">
            <div class="tw-w-[100%] xs:tw-hidden">
              <span>{{ 'label.profile.my-voucher.status.label' | translate }}</span>
            </div>
            <div class="tw-w-[100%] tw-font-medium tw-flex xs:tw-justify-center" *ngIf="isVoucherActive">
              <span attr.aria-label="My Voucher {{ i }} status" class="tw-text-green-success">
                <strong>{{ 'label.profile.my-voucher.active.label' | translate }}</strong>
              </span>
            </div>
            <div class="tw-w-[100%] tw-font-medium tw-flex xs:tw-justify-center" *ngIf="!isVoucherActive">
              <p attr.aria-label="My Voucher {{ i }} status" class="tw-text-red-100">
                <strong>
                  <span *ngIf="voucher.status === voucherStatusCode.Void">
                    {{ 'label.profile.my-voucher.void.label' | translate }}
                  </span>
                  <span *ngIf="isDateExpired(voucher.expiry) && voucher.status == voucherStatusCode.Available">
                    {{ 'label.profile.my-voucher.expired.label' | translate }}
                  </span>
                  <span *ngIf="voucher.status === voucherStatusCode.Redeemed">
                    {{ 'label.profile.my-voucher.used.label' | translate }}
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tw-text-center tw-border-b-[1px] tw-border-black-40 xs:tw-pb-[20px] xs:tw-pt-0 xxs:tw-py-[20px]"
      *ngIf="vouchers.length == 0"
    >
      <span class="tw-font-bold" aria-label="No data Available label">
        {{ 'label.profile.my-voucher.no-available-data' | translate }}
      </span>
    </div>
    <div *ngIf="totalVoucherCount > 10" class="tw-flex tw-justify-center tw-pt-[30px]" aria-label="my booking pagination">
      <app-pagination-bar #pagination 
        [totalItems]="totalVoucherCount" 
        [itemsPerPage]="pageSize" 
        (changePage)="onChangePage($event)">
      </app-pagination-bar>
    </div>
  </div>
</div>

<div
  class="tw-fixed tw-flex tw-mx-auto tw-w-full tw-left-0 tw-right-0 xs:tw-w-192 tw-shadow-user-profile currency-selection"
  aria-label="Currency selector popup"
  [ngClass]="{
    'tw-h-[calc(120%-1rem)]  tw-max-h-full tw-rounded-br-0 tw-rounded-bl-0 tw-mt-[-58px] tw-fixed  tw-overflow-y-auto':
      isMobile,
    'tw-max-h-[80vh] tw-overflow-auto tw-absolute tw-bottom-auto tw-left-auto tw-top-[100%]  tw-rounded-br-5 tw-rounded-bl-5 tw-shadow-user-profile':
      !isMobile
  }"
>
  <div class="tw-relative tw-w-full tw-h-full">
    <div
      class="tw-visible xs:tw-hidden tw-fixed tw-w-full tw-bg-yellow-20 tw-flex tw-justify-end tw-items-end tw-pt-2.5 tw-pr-2.5"
    >
      <img src="/assets/icons/exit.svg" aria-label="Close currency icon" (click)="closeCurrencyModal()" />
    </div>
    <div
      class="tw-bg-yellow-20 tw-pt-[25px] xs:tw-p-2.5 tw-flex tw-flex-col tw-w-full tw-overflow-y-auto"
      [ngClass]="{ 'tw-min-h-[120vh]': isMobile }"
    >
      <div
        class="border-bottom-lightgray tw-w-full tw-block xs:tw-hidden tw-flex xs:tw-flex-row xs:tw-flex-wrap tw-flex-col tw-text-3.75/6 tw-px-[15px] tw-pt-[15px]"
      >
        <div class="tw-px-2.5 tw-py-2.5 tw-mt-[20px]">
          <h1 class="tw-font-bold tw-text-xl" aria-label="Currency label">{{ 'label.currency' | translate }}</h1>
          <span aria-label="Currently viewing in label">
            {{ 'label.you-are-currently-viewing-in' | translate }}
          </span>
          <span aria-label="Selected currency code" class="tw-font-bold">{{ selectedCurrencyCode }}</span> &nbsp;
          <span aria-label="Selected currency description" class="tw-font-bold">{{ selectedCurrencyDescription }}</span>
        </div>
        <input
          type="text"
          class="tw-px-2.5 tw-py-2.5 tw-rounded-2xl tw-w-full tw-indent-[35px] tw-mb-[30px]"
          placeholder="Search Currency"
          [(ngModel)]="searchCurrency"
          (input)="onSearchCurrency()"
        />
      </div>
      <div class="tw-w-full tw-hidden xs:tw-block">
        <div
          class="tw-font-bold tw-text-[18px] tw-leading-7 tw-w-full tw-px-2.5 tw-py-2.5"
          aria-label="Popular currencies label"
        >
          {{ 'label.header-item-curency.popular-currencies.label' | translate }}
        </div>
        <hr />
      </div>
      <div class="tw-flex xs:tw-flex-row xs:tw-flex-wrap tw-flex-col tw-text-3.75/6 tw-p-[20px]">
        <div class="tw-relative tw-w-full" *ngIf="isMobile">
          <img
            src="/assets/icons/svg/magnifying-glass.svg"
            class="tw-w-[20px] tw-absolute tw-bottom-[60px] tw-left-[10px]"
          />
        </div>
        <div
          class="tw-px-2.5 tw-py-2.5 hover:tw-bg-slate-950 hover:tw-text-semibold hover:tw-text-white tw-cursor-pointer xs:tw-w-[33%]"
          *ngFor="let currency of popularCurrencies"
          (click)="setSelectedCurrency(currency)"
          [attr.aria-label]="'Currency selector option ' + currency.currencyCode"
          [ngClass]="{ 'selected-item': selectedCurrencyCode === currency.currencyCode }"
        >
          <span
            class="tw-font-bold tw-text-[15px] tw-mr-[5px]"
            [attr.aria-label]="'Currency item code ' + currency.currencyCode"
            >{{ currency.currencyCode }}</span
          >
          <span
            class="tw-font-medium tw-text-[15px]"
            [attr.aria-label]="'Currency item description ' + currency.description"
            >{{ currency.description }}</span
          >
        </div>
      </div>
      <div class="tw-w-full tw-hidden xs:tw-block">
        <div
          class="tw-font-bold tw-text-[18px] tw-leading-7 tw-w-full tw-px-2.5 tw-py-2.5"
          aria-label="More currencies label"
        >
          {{ 'label.header-item-curency.more-currencies.label' | translate }}
        </div>
        <hr />
      </div>
      <div
        class="tw-flex xs:tw-flex-row xs:tw-flex-wrap tw-flex-col tw-text-3.75/6 tw-p-[20px] xs:tw-mt-0 tw-mt-[-40px]"
      >
        <div
          class="tw-px-2.5 tw-py-2.5 hover:tw-bg-slate-950 hover:tw-text-semibold hover:tw-text-white tw-cursor-pointer xs:tw-w-[33%]"
          *ngFor="let currency of otherCurrencies"
          (click)="setSelectedCurrency(currency)"
          [attr.aria-label]="'Currency selector option ' + currency.currencyCode"
          [ngClass]="{ 'selected-item': selectedCurrencyCode === currency.currencyCode }"
        >
          <span
            class="tw-font-bold tw-text-[15px] tw-mr-[5px]"
            [attr.aria-label]="'Currency item code ' + currency.currencyCode"
            >{{ currency.currencyCode }}</span
          >
          <span
            class="tw-font-medium tw-text-[15px]"
            [attr.aria-label]="'Currency item description ' + currency.description"
            >{{ currency.description }}</span
          >
        </div>
        <div
          class="tw-px-2.5 tw-py-2.5 tw-cursor-pointer xs:tw-w-[33%]"
          *ngIf="isMobile && !searchCurrency"
          (click)="onToggleShowMoreShowLess()"
        >
          <span *ngIf="!isShowMore" class="tw-underline tw-text-sky-500 tw-font-bold" aria-label="Show more label">{{
            'label.show-more' | translate
          }}</span>
          <span *ngIf="isShowMore" class="tw-underline tw-text-sky-500 tw-font-bold" aria-label="Show less label">{{
            'label.show-less' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div aria-label="FOC Entitlement Section" class="tw-flex tw-flex-wrap tw-gap-[20px]">
  <div class="panel">
    <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex">
      <div
        aria-label="FOC Entitlement header"
        class="tw-self-stretch tw-justify-start tw-items-center tw-gap-96 tw-inline-flex"
      >
        <div
          aria-label="FOC Entitlement title label"
          class="tw-text-stone-900 tw-text-[26px] tw-font-bold tw-leading-9"
        >
          {{ 'label.profile.foc.entitlement.label' | translate }}
        </div>
      </div>
      <div
        aria-label="FOC Entitlement information group"
        class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-gap-5 tw-flex"
      >
        <div class="tw-self-stretch tw-justify-start tw-items-start tw-gap-5 tw-inline-flex">
          <div class="tw-grow tw-shrink tw-basis-0 tw-flex-col tw-justify-start tw-items-start tw-gap-1 tw-inline-flex">
            <div
              aria-label="FOC Entitlement information text"
              class="tw-text-stone-900 tw-text-[15px] tw-font-medium tw-leading-normal"
              [innerHTML]="('contentBlock.profile.foc.entitlement.information' | translate)"
            >
            </div>
          </div>
        </div>
        <div
          *ngIf="isFOCValid(profileData)"
          class="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-gap-5 tw-w-full"
        >
          <div aria-label="Foc date group" class="xs:tw-w-[calc(40%-10px)] xxs:tw-w-full">
            <div
              aria-label="FOC Entitlement date label"
              class="tw-text-stone-900 tw-text-[15px] tw-font-medium tw-leading-normal tw-w-full"
            >
              {{ 'label.profile.foc.date.label' | translate }}
            </div>
            <div
              aria-label="FOC Entitlement date data"
              class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7"
            >
              {{ profileData ? (profileData.focEntitlements[0].startDate | date : 'dd MMMM yyyy') : '' }} -
              {{ profileData ? (profileData.focEntitlements[0].endDate | date : 'dd MMMM yyyy') : '' }}
            </div>
          </div>
          <div aria-label="FOC Entitlement total section" class="xs:tw-w-[calc(60%-10px)] xxs:tw-w-full">
            <div
              aria-label="FOC Entitlement total label"
              class="tw-text-stone-900 xs:tw-text-[15px] xxs:tw-text-[15px] tw-font-medium tw-leading-normal tw-w-full"
            >
              {{ 'label.profile.foc.totalEntitlement.label' | translate }}
            </div>
            <div
              aria-label="FOC Entitlement total data"
              class="tw-text-stone-900 tw-text-lg tw-font-medium tw-leading-7"
            >
              {{ profileData?.totalCreditsRemaining ?? 0 }} / {{ profileData?.totalEntitlement ?? 0 }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div aria-label="FOC Entitlement passengers section" class="panel">
    <div class="tw-self-stretch tw-flex-col tw-justify-start tw-items-start tw-flex">
      <div
        aria-label="FOC Entitlement passenger title label"
        class="tw-text-[26px] tw-font-bold tw-leading-9 tw-w-full tw-mb-5"
      >
        {{ 'label.profile.foc.passengers.label' | translate }}
      </div>
      <div
        aria-label="Legend section"
        class="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-gap-5 tw-w-full tw-mb-[10px]"
      >
        <div aria-label="Legend for employee and immediate family" class="xs:tw-w-[calc(60%-10px)] xxs:tw-w-full">
          <div class="tw-text-stone-900 tw-text-md tw-font-medium tw-leading-7">
            <span class="circle-bullet-yellow" aria-label="Bullet for employee immediate family">&#11044;</span> &nbsp;
            <span aria-label="Employee immediate family label">{{
              'label.profile.foc.employee-immediate-family.label' | translate
            }}</span>
          </div>
        </div>
        <div aria-label="Legend for nominated companion" class="xs:tw-w-[calc(40%-10px)] xxs:tw-w-full">
          <div class="tw-text-stone-900 tw-text-md tw-font-medium tw-leading-7">
            <span class="circle-bullet-orange" aria-label="Bullet for nominated companion">&#11044;</span> &nbsp;
            <span aria-label="Nominated companion label">{{
              'label.profile.foc.nominated-companion.label' | translate
            }}</span>
          </div>
        </div>
      </div>

      <div class="panel-content" *ngIf="isFOCValid(profileData)">
        <div class="panel-title">
          <div aria-label="Profile Icon" class="div-icon">
            <img src="/assets/icons/svg/person.svg" alt="Profile Icon" />
          </div>
          <span aria-label="FOC Entitlement staff full name label" class="label">
            {{ profileData?.fullName }}
          </span>
        </div>
        <div class="panel-info">
          <div class="div-bar">
            <div
              aria-label="FOC Entitlement staff credits remaining bar"
              class="tw-h-[12px] tw-rounded-full non-companion"
              [style.width.%]="profileData ? (profileData.creditsRemaining / profileData.maxEligibility) * 100 : 0"
            ></div>
          </div>
          <div class="tw-flex tw-gap-[3px] tw-w-auto xs:tw-w-auto xxs:tw-w-[100%]">
            <span aria-label="FOC Entitlement staff credits remaining label">
              {{ profileData?.creditsRemaining }}
            </span>
            /
            <span aria-label="FOC Entitlement staff max eligibility label">
              {{ profileData?.maxEligibility }}
            </span>
            <span aria-label="FOC Entitlement staff remaining credits label">
              {{ 'label.profile.foc.remainingCredits.label' | translate }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="isFOCValid(profileData)" class="tw-w-full">
        <div class="panel-content" *ngFor="let beneficiary of getBeneficiaries(profileData); let i = index">
          <div class="panel-title">
            <div aria-label="Profile Icon" class="div-icon">
              <img src="/assets/icons/svg/person.svg" alt="Profile Icon" />
            </div>
            <span attr.aria-label="FOC Entitlement beneficiary {{ i }} full name label" class="label">
              {{ beneficiary.firstName + (beneficiary.lastName == '' ? '' : ' ' + beneficiary.lastName) }}
            </span>
          </div>
          <div class="panel-info">
            <div class="div-bar">
              <div
                attr.aria-label="FOC Entitlement beneficiary {{ i }} credits remaining bar"
                class="tw-h-[12px] tw-rounded-full"
                [class.companion]="beneficiary.relationship.descriptor.includes('Nominated Companion')"
                [class.non-companion]="!beneficiary.relationship.descriptor.includes('Nominated Companion')"
                [style.width.%]="(beneficiary.creditsRemaining / beneficiary.maxEligibility) * 100"
              ></div>
            </div>
            <div class="tw-flex tw-gap-[3px] tw-w-auto xs:tw-w-auto xxs:tw-w-[100%]">
              <span attr.aria-label="FOC Entitlement beneficiary {{ i }} credits remaining label">{{
                beneficiary.creditsRemaining
              }}</span>
              /
              <span attr.aria-label="FOC Entitlement beneficiary {{ i }} max eligibility label">{{
                beneficiary.maxEligibility
              }}</span
              ><span attr.aria-label="FOC Entitlement beneficiary {{ i }} remaining credits label">{{
                'label.profile.foc.remainingCredits.label' | translate
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="tw-w-full tw-text-center tw-py-[20px] tw-border-y-[1px] tw-border-black-40"
        *ngIf="!isFOCValid(profileData)"
      >
        <span class="tw-font-bold" aria-label="No data Available label">
          {{ 'label.profile.my-voucher.no-available-data' | translate }}
        </span>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { DropdownConfig } from '../dropdown.model';
import { has } from 'lodash';

@Component({
  selector: 'app-dropdown-modal',
  templateUrl: './dropdown-modal.component.html',
  styleUrls: ['./dropdown-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownModalComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  _config!: DropdownConfig;
  _isVisible!: boolean;
  loading: boolean = false;

  @Input()
  get isVisible(): boolean {
    return this._isVisible;
  }
  set isVisible(value: boolean) {
    this._isVisible = value;
    if (value) {
      this.setValue();
    }
  }
  @Input()
  get config(): DropdownConfig {
    return this._config;
  }
  set config(value: DropdownConfig) {
    this._config = value;
    if (value) this.initializeConfig();
  }
  @Input() title!: string;
  @Input() label!: string;
  @Input() showLoader: boolean = false;
  @Output() closeModal = new EventEmitter<string | null>();

  formGroup = new FormGroup({
    dropDownField: new FormControl(null as string | null)
  });

  dropdownFieldConfig!: DropdownConfig;

  ngOnInit(): void {
    this.initializeConfig();
    this.observeData();
  }

  onCloseModal(value: string | null = null): void {
    this.isVisible = false;
    this.closeModal.emit(value);
  }

  initializeConfig() {
    if (this.formGroup && this.config) {
      this.dropdownFieldConfig = {
        control: this.formGroup.controls.dropDownField,
        isRounded: false,
        componentIdentifier: this.config.componentIdentifier,
        options: this.config.options,
        hasPrefixIcon: true,
        showArrowDown: false
      };
    }
  }

  private observeData() {
    if (this.formGroup) {
      this.formGroup.controls.dropDownField.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((value) => {
        if (value) {
          this.config.control.setValue(value);

          this.initializeConfig();
          this.onCloseModal(value);
        }
      });
    }
  }

  setValue() {
    if (this.formGroup && this.config) {
      this.initializeConfig();
      this.formGroup.controls.dropDownField.setValue(this.config.control.value, { onlySelf: true, emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

import { Component, OnDestroy, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { ModalService } from './modal.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  @ViewChild('modal', { read: ViewContainerRef }) modalContainerRef!: ViewContainerRef;
  show = false;

  constructor(
    private modalService: ModalService, 
    private renderer: Renderer2
  ) {
    this.modalService.currentlyDisplayed$.pipe(takeUntil(this._destroy$)).subscribe((componentToDisplay) => {
      this.show = componentToDisplay !== undefined;
      if (componentToDisplay !== undefined) {
        this.modalContainerRef.clear();
        this.renderer.addClass(document.body, 'modal-open');

        const component = this.modalContainerRef.createComponent(componentToDisplay);
        this.modalService.currentlyData$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
          if (data) {
            component.setInput('data', data);
          }
        });
      } else {
        this.show = false;
        this.renderer.removeClass(document.body, 'modal-open');
        if (this.modalContainerRef) this.modalContainerRef.clear();
      }
    });
  }

  onCloseModal() {
    this.modalService.hide();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

<div aria-label="Travel Beneficiaries Section" class="tw-flex tw-flex-wrap tw-gap-[20px]">
  <div
    aria-label="Travel beneficiaries group"
    class="tw-bg-white tw-rounded-[20px] tw-overflow-hidden tw-p-[30px] tw-w-full"
  >
    <!-- Heading -->
    <div class="tw-self-stretch tw-justify-start tw-items-center tw-gap-96 tw-inline-flex">
      <div
        aria-label="Travel Beneficiaries header title"
        class="tw-text-stone-900 tw-text-2xl tw-font-bold tw-leading-9 tw-pb-4"
>
        {{ 'label.profile.travelBeneficiaries.heading.label' | translate }}
      </div>
    </div>

    <!-- List -->
    <div
      *ngFor="let beneficiary of beneficiaries$ | async as beneficiaries; let i = index"
      aria-label="Travel beneficiary list"
    >
      <div class="tw-flex tw-flex-wrap tw-items-center tw-justify-around tw-w-[100%] tw-h-px tw-bg-neutral-200"></div>
      <!-- Header -->
      <div
        attr.aria-label="Travel beneficiary header for {{ beneficiary.firstName }} {{ beneficiary.lastName }}"
        class="tw-py-5"
        (click)="viewTravelBeneficiary(i)"
      >
        <div class="tw-flex tw-items-center tw-justify-between">
          <div
            attr.aria-label="Travel beneficiary title for {{ beneficiary.firstName }} {{ beneficiary.lastName }}"
            class="tw-flex tw-justify-center tw-items-center tw-gap-[10px]"
          >
            <img
              attr.aria-label="Travel beneficiary icon for {{ beneficiary.firstName }} {{ beneficiary.lastName }}"
              class="tw-w-[36px] tw-h-[36px]"
              src="/assets/icons/svg/person-yellow.svg"
              alt="Travel beneficiary icon"
            />
            <span
              attr.aria-label="Travel beneficiary name label for {{ beneficiary.firstName }} {{ beneficiary.lastName }}"
              class="tw-text-stone-900 tw-text-medium tw-font-bold tw-leading-7"
              >{{ beneficiary.firstName }} {{ beneficiary.lastName }}</span
            >
          </div>
          <div class="tw-flex tw-items-center tw-justify-center">
            <img
              class="tw-cursor-pointer tw-transition-transform tw-w-[24px] tw-h-[24px]"
              [ngClass]="{
                'tw-rotate-[0deg]': isTravelBeneficiaryShow[i],
                'tw-rotate-[-180deg]': !isTravelBeneficiaryShow[i]
              }"
              attr.aria-label="Travel beneficiary chevron arrow icon for {{ beneficiary.firstName }} {{
                beneficiary.lastName
              }}"
              src="/assets/icons/svg/Chev-Arrow.svg"
              alt="Travel beneficiary chevron arrow"
            />
          </div>
        </div>
      </div>
      <!-- Body -->
      <div
        *ngIf="isTravelBeneficiaryShow[i]"
        attr.aria-label="Travel beneficiary body for {{ beneficiary.firstName }} {{ beneficiary.lastName }}"
        class="tw-bg-white tw-flex tw-flex-wrap tw-items-start tw-justify-start"
      >
        <div class="tw-flex tw-flex-wrap tw-gap-[10px] tw-w-[100%]">
          <div class="div-group-details">
            <div attr.aria-label="Travel beneficiaries title group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries title label for {{ beneficiary.firstName }} {{beneficiary.lastName }}" class="label">
                {{ 'label.profile.details.title.label' | translate }}
              </div>
              <div
                attr.aria-label="Travel beneficiaries title data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text">
                {{ beneficiary.title }}
              </div>
            </div>
            <div attr.aria-label="Travel beneficiaries date of birth group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries date of birth label for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="label">
                {{ 'label.profile.travelBeneficiaries.dateOfBirth.label' | translate }}
              </div>
              <div attr.aria-label="Travel beneficiaries date of birth data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text gb_mask">
                {{ beneficiary.dateOfBirth | date : birthDateFormatConstant }}
              </div>
            </div>
          </div>
          <div class="div-group-details">
            <div attr.aria-label="Travel beneficiaries first name group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries first name label for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="label">
                {{ 'label.profile.details.firstName.label' | translate }}
              </div>
              <div attr.aria-label="Travel beneficiaries first name data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text">
                {{ beneficiary.firstName }}
              </div>
            </div>
            <div attr.aria-label="Travel beneficiaries last name group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries last name label for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="label">
                {{ 'label.profile.details.lastName.label' | translate }}
              </div>
              <div attr.aria-label="Travel beneficiaries last name data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text tw-pb-4">
                {{ beneficiary.lastName }}
              </div>
            </div>
          </div>
          <div class="div-group-details">
            <div attr.aria-label="Travel beneficiaries gender group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries gender label for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="label">
                {{ 'label.profile.travelBeneficiaries.gender.label' | translate }}
              </div>
              <div attr.aria-label="Travel beneficiaries gender data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text tw-pb-4">
                {{ beneficiary.gender | genderDescriptor }}
              </div>
            </div>
            <div attr.aria-label="Travel beneficiaries relationship group for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="div-group">
              <div attr.aria-label="Travel beneficiaries relationship label for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="label">
                {{ 'label.profile.travelBeneficiaries.relationship.label' | translate }}
              </div>
              <div attr.aria-label="Travel beneficiaries relationship data for {{ beneficiary.firstName }} {{beneficiary.lastName}}" class="text tw-pb-4">
                {{ beneficiary.relationship.descriptor }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="data"
  attr.aria-label="{{ data.type }} fare section"
  [ngClass]="{
    'tw-bg-yellow-20': data.type === flightFareType.ScootPlus,
    'tw-bg-zinc-100': data.type === flightFareType.Economy,
    'tw-bg-teal-100': data.type === flightFareType.ScootForSure
  }"
  class="tw-rounded-5 tw-p-[30px]"
>
  <div class="tw-flex tw-flex-col tw-grow">
    <!--Header-->
    <div class="tw-flex tw-flex-col tw-pb-[15px]">
      <div class="tw-text-stone-900 tw-text-lg tw-font-bold tw-leading-7">
        <div attr.aria-label="{{ data.type }} fare title" *ngIf="data.type === flightFareType.Economy">
          {{ 'label.book-flight.economy-uppercase.label' | translate }}
        </div>
        <div attr.aria-label="{{ data.type }} fare title" *ngIf="data.type === flightFareType.ScootPlus">
          {{ 'label.book-flight.scootplus-uppercase.label' | translate }}
        </div>
        <div attr.aria-label="{{ data.type }} fare title" *ngIf="data.type === flightFareType.ScootForSure">
          {{ 'label.book-flight.scootforsure-uppercase.label' | translate }}
        </div>
      </div>
    </div>
    <!--Content-->
    <div
      attr.aria-label="{{ data.type }} fare text content"
      *ngIf="data.type === flightFareType.ScootForSure"
      class="tw-w-64 tw-pb-3.5 tw-text-stone-900 tw-text-xs tw-font-medium tw-leading-4"
      [innerHTML]="'label.book-flight.flight-details.content.scootforsure' | translate | safe : 'html'"
    ></div>
    <!--Details-->
    <div *ngFor="let detail of details">
      <div
        *ngIf="
          (data.type === flightFareType.ScootPlus && detail.scootPlus) ||
          (data.type === flightFareType.Economy && detail.economy) ||
          (data.type === flightFareType.ScootForSure && detail.scootForSure)
        "
        class="tw-self-stretch tw-justify-start tw-items-start tw-inline-flex xs:tw-mb-0 xxs:tw-mb-[7px]"
      >
        <div class="tw-w-40 flex-col tw-justify-start tw-items-start tw-inline-flex">
          <div class="tw-justify-start tw-items-center tw-pb-[15px] tw-inline-flex">
            <img
              class="tw-w-5 tw-h-5 tw-relative"
              attr.aria-label="{{ data.type }} {{ detail.ariaLabel }} icon"
              src="{{ detail.icon }}"
              alt="{{ detail.ariaLabel }} icon"
            />
            <div
              attr.aria-label="{{ data.type }} {{ detail.ariaLabel }} title"
              class="tw-pl-[5px] tw-text-stone-900 tw-text-xs tw-font-bold tw-leading-none"
            >
              {{ detail.title | translate }}
            </div>
          </div>
        </div>
        <div class="tw-grow tw-shrink tw-basis-0 tw-text-stone-900 tw-text-xs tw-font-medium tw-leading-4">
          <div
            attr.aria-label="{{ data.type }} {{ detail.ariaLabel }} description"
            *ngIf="data.type === flightFareType.Economy"
          >
            {{ detail.economy | translate }}
          </div>
          <div
            attr.aria-label="{{ data.type }} {{ detail.ariaLabel }} description"
            *ngIf="data.type === flightFareType.ScootPlus"
          >
            {{ detail.scootPlus | translate }}
          </div>
          <div
            attr.aria-label="{{ data.type }} {{ detail.ariaLabel }} description"
            *ngIf="data.type === flightFareType.ScootForSure"
          >
            {{ detail.scootForSure | translate }}
          </div>
        </div>
      </div>
    </div>
    <!--Footer-->
    <div class="tw-justify-start tw-items-center tw-gap-2.5 tw-inline-flex">
      <button
        (click)="onCloseModal()"
        type="button"
        aria-label="Fare details close button"
        class="btn tw-btn-primary tw-mx-auto"
      >
        {{ 'label.button.ok.label' | translate }}
      </button>
    </div>
  </div>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PageRoute } from '../../shared/enums/page-routes.enum';

@Component({
  selector: 'app-payment-receiver',
  templateUrl: './payment-receiver.component.html',
  styleUrls: ['./payment-receiver.component.scss']
})
export class PaymentReceiverComponent {
  constructor(private route: ActivatedRoute, private router: Router) {
    const paymentReference = this.route.snapshot.queryParams['paymentReference'] ?? '';
    this.router.navigate([PageRoute.Wait], {
      state: {
        paymentReference: paymentReference
      },
      queryParams: {
        skipLoading: true
      }
    });
  }
}

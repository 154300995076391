import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ManageBooking, SendItineraryRequest } from '../manage-booking.model';
import { TabMenuEnum } from '../manage-booking.enum';
import { Subject, forkJoin, take, takeUntil } from 'rxjs';
import { NotificationService } from '../../shared/components/notification/notification.service';
import { ManageBookingService } from '../manage-booking.service';
import { BookingFlightTypeEnum, FlightFareTypeEnum } from '../../booking/flight/flight.enum';
import { Router } from '@angular/router';
import { PageRoute } from '../../shared/enums/page-routes.enum';
import { BookingService } from '../../booking/booking.service';
import { AppConstants } from '../../app.constants';
import { SessionService } from '../../session/session.service';
import { ProfileService } from '../../profile/profile.service';
import { StaffProfile } from '../../profile/profile.model';
import { NotificationData } from '../../shared/components/notification/notification.model';
import { Booking } from '../../booking/booking.model';

@Component({
  selector: 'app-flight-item',
  templateUrl: './flight-item.component.html',
  styleUrls: ['./flight-item.component.scss']
})
export class FlightItemComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  readonly tabMenu = TabMenuEnum;

  @Input() booking!: ManageBooking;
  @Input() bookingIndex = 0;
  @Input() tabName!: TabMenuEnum;
  @Output() onProceed = new EventEmitter<string>();
  @Output() onCancelled = new EventEmitter<Booking>();

  showDetails = false;
  showFlightSearchModal = false;
  selectedBooking!: ManageBooking;
  readonly flightFareType = FlightFareTypeEnum;

  constructor(
    private readonly manageBookingService: ManageBookingService,
    private readonly notificationService: NotificationService,
    private readonly profileService: ProfileService,
    private readonly bookingService: BookingService,
    private readonly sessionService: SessionService,
    private router: Router
  ) {}

  changeFlight(manageBooking: ManageBooking): void {
    const self = this;
    this.bookingService
      .searchBooking$({ recordLocator: manageBooking.recordLocator })
      .pipe(take(1))
      .subscribe((response) => {
        if (!response.booking.canManageBooking) {
          this.showNotificationManageIsNotAllowed('notification.text.manage-booking.not-allow-to-alter');
        } else {
          this.notificationService.show({
            title: 'notification.title.manage-booking.change-datetime',
            text: 'notification.text.manage-booking.change-datetime',
            showConfirmButton: true,
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Proceed',
            widthClass: 'tw-w-[40%]',
            textAlignClass: 'tw-text-left',
            callBack: function (isConfirm: boolean) {
              if (isConfirm) {
                const currencyCode = self.getCollectedCurrecy(manageBooking);
                self.sessionService.setCurrencyCodeToSessionStorage(currencyCode);
                self.sessionService.setSelectedCurrencyCode(currencyCode);
                self.sessionService.setCollectedCurrencyCode(currencyCode);

                self.bookingService.setBookingType(BookingFlightTypeEnum.ChangeFlight);
                self.bookingService.setIsFocBooking(!!manageBooking.isFocBooking);
                self.onProceed.emit(manageBooking.recordLocator);
              }
            }
          });
        }
      });
  }

  manageAddOns(manageBooking: ManageBooking): void {
    forkJoin({
      bookingResponse: this.bookingService.searchBooking$({ recordLocator: manageBooking.recordLocator }).pipe(take(1)),
      profileResponse: this.profileService.staffProfile$.pipe(take(1))
    })
      .pipe(takeUntil(this._destroy$))
      .subscribe((response) => {
        if (response.bookingResponse && response.profileResponse) {
          if (!response.bookingResponse.booking.canManageAddOns) {
            this.showNotificationManageIsNotAllowed('notification.text.manage-addons.not-allow-to-alter');
          } else {
            if (response.profileResponse) {
              const profile = response.profileResponse as StaffProfile;
              const booking = response.bookingResponse.booking as Booking;
              const isFlightScootForSure =
                booking.journeys.flatMap((x) => x.fares).some((x) => x.type === FlightFareTypeEnum.ScootForSure) ??
                false;

              const currencyCode = this.getBookingCollectedCurrecy(booking);
              this.sessionService.setCurrencyCodeToSessionStorage(currencyCode);
              this.sessionService.setSelectedCurrencyCode(currencyCode);

              this.bookingService.setBookingDetail({
                currencyCode: currencyCode,
                userContactInformation: this.profileService.getContactInformation(profile),
                isFlightScootForSure: isFlightScootForSure,
                isFlightWithScootPlus: false,
                totalCreditsRemaining: profile.totalCreditsRemaining,
                totalEntitlement: profile.totalEntitlement,
                vouchers: [],
                passengers: []
              });
              this.bookingService.setBookingType(BookingFlightTypeEnum.ManageAddOns);
              this.bookingService.setIsFocBooking(!!manageBooking.isFocBooking);
              this.router.navigateByUrl(PageRoute.BookFlightPayment);
            }
          }
        }
      });
  }

  resendItinerary(manageBooking: ManageBooking): void {
    const recordLocator = manageBooking.recordLocator;
    const request: SendItineraryRequest[] = [{ recordLocator }];
    this.manageBookingService.sendItinerary$(request).subscribe((response) => {
      const notificationData: NotificationData = {
        title: 'notification.title.resendItinerary.success',
        text: 'notification.text.resendItinerary.success',
        showConfirmButton: false,
        showCancelButton: true
      };

      if (!response.isSuccess) {
        notificationData.title = 'notification.title.resendItinerary.failed';
        notificationData.text = 'notification.text.resendItinerary.failed';
        notificationData.addTextAfterTitle = manageBooking.recordLocator;
        notificationData.confirmButtonText = 'notification.button.ok';
        notificationData.showConfirmButton = true;
        notificationData.showCancelButton = false;
      }

      this.notificationService.show(notificationData);
    });
  }

  cancelFlight(booking: ManageBooking): void {
    this.bookingService
      .searchBooking$({ recordLocator: booking.recordLocator })
      .pipe(take(1))
      .subscribe((response) => {
        if (!response.booking.canManageBooking) {
          this.showNotificationManageIsNotAllowed('notification.text.manage-cancel.not-allow');
        } else {
          this.onCancelled.emit(response.booking);
        }
      });
  }

  private showNotificationManageIsNotAllowed(text: string): void {
    this.notificationService.show({
      title: '',
      text: text,
      showConfirmButton: true,
      showCancelButton: false,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'OK',
      widthClass: 'tw-w-[40%]',
      textAlignClass: 'tw-text-left'
    });
  }

  private getCollectedCurrecy(booking: ManageBooking): string {
    return (
      (booking.payments.length > 0 ? booking.payments[0].collectedCurrencyCode : AppConstants.DefaultCurrencyCode) ??
      AppConstants.DefaultCurrencyCode
    );
  }

  private getBookingCollectedCurrecy(booking: Booking): string {
    return (
      (booking.payments.length > 0
        ? booking.payments[0].amounts.collectedCurrencyCode
        : AppConstants.DefaultCurrencyCode) ?? AppConstants.DefaultCurrencyCode
    );
  }

  get currencyCode(): string {
    return this.sessionService.getCurrencyCodeFromSessionStorage();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

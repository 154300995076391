<div 
  aria-label="Page Content" 
  [ngClass]="{
    'bg-mobile': isMobile
  }"
  class="container">
  <div class="content">
    <div class="tw-flex tw-flex-col tw-relative tw-align-middle tw-bg-white tw-rounded-2xl tw-p-5 tw-gap-5 xs:tw-gap-7.5 xs:tw-p-7.5">
      <div class="tw-text-center tw-flex tw-flex-col tw-gap-6 tw-items-center tw-justify-center xs:tw-p-[10%]">
        <div class="tw-text-stone-900 tw-text-4xl tw-font-bold tw-leading-10">
          <h2 aria-label="Error message" class="tw-text-[26px] xs:tw-text-[36px]">
            {{  errorMessage | translate }} 
          </h2>
        </div>
      </div>
    </div>
  </div>
</div>
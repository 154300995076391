<div class="tw-items-center xs:tw-px-3.75">
  <div class="scoot-container tw-mx-auto">
    <div attr.aria-label="{{ selectedFlightType }} - Date Paginator section" class="datePaginator" *ngIf="dateSelected">
      <div class="tw-flex tw-justify-between">
        <button
          attr.aria-label="{{ selectedFlightType }} - Date Paginator Arrow left button"
          class="date-arrow arrow-left"
          [disabled]="config.disabledNavPrevButton"
          (click)="onNavigatePrev()"
        >
          <div class="tw-w-6 tw-h-6 tw-relative tw-p-[3px] tw-pl-[5px]">
            <img
              *ngIf="!config.disabledNavPrevButton"
              attr.aria-label="{{ selectedFlightType }} - Date Paginator Arrow left icon"
              src="/assets/icons/svg/arrow-left.svg"
            />
          </div>
        </button>
        <div
          #datePaginatorlement
          attr.aria-label="{{ selectedFlightType }} - Dates section"
          *ngIf="dates$ | async as dates"
          ngClass="{{ isMobile ? 'date-mobile' : 'date-grid' }}"
          class="date-items"
        >
          <div *ngFor="let date of dates; let i = index">
            <div
              id="{{ selectedFlightType }} - {{ date | date : config.ariaLabelDateFormat }}"
              [tabindex]="isSelected(date) ? 0 : i + 1"
              attr.aria-label="{{ selectedFlightType }} - {{ date | date : config.ariaLabelDateFormat }}"
              [class.selected]="isSelected(date)"
              [class.disabled]="
                isBelowMinDate(date) ||
                isAboveMaxDate(date) ||
                isNotAvailableFlightDate(date) ||
                isPastTerminationDate(date)
              "
              (click)="onDateSelection(date)"
              class="date"
            >
              <div>
                {{ date | date : config.displayDateFormat }}
              </div>
              <div
                attr.aria-label="{{ selectedFlightType }} - {{ date | date : config.ariaLabelDateFormat }} - SFS seat(s) label"
                *ngIf="selectedClassOfService === flightClassOfServiceEnum.ScootForSure"
                class="tw-text-[11px]"
              >
                {{
                  (withSeat(date)
                    ? 'label.book-flight-selection.sfs-seats-available.label'
                    : 'label.book-flight-selection.no-sfs-seat.label'
                  ) | translate
                }}
              </div>
            </div>
          </div>
        </div>
        <button
          attr.aria-label="{{ selectedFlightType }} - Date Paginator Arrow right button"
          class="date-arrow arrow-right"
          [disabled]="config.disabledNavNextButton"
          (click)="onNavigateNext()"
        >
          <div class="tw-w-6 tw-h-6 tw-relative tw-p-[3px] tw-pl-[5px]">
            <img
              *ngIf="!config.disabledNavNextButton"
              attr.aria-label="{{ selectedFlightType }} - Date Paginator Arrow right icon"
              src="/assets/icons/svg/arrow-right.svg"
            />
          </div>
        </button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppConstants } from '../app.constants';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private readonly _currentPage$ = new BehaviorSubject<string>('');
  private readonly _currencyCode$ = new BehaviorSubject<string>(AppConstants.DefaultCurrencyCode);

  constructor(private sessionService: SessionService) { }

  readonly currentPage$ = this._currentPage$.asObservable();
  readonly currencyCode$ = this._currencyCode$.asObservable();

  setCurrentPage(currentPage: string) {
    this._currentPage$.next(currentPage);
  }

  setCurrencyCode() {
    const currencyCode = this.sessionService.getSelectedCurrencyCode() ?? this.sessionService.getCurrencyCodeFromSessionStorage();
    this._currencyCode$.next(currencyCode);
  }
}
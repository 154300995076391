<div
  aria-label="Payment loading container"
  class="scoot-overlay tw-flex tw-flex-col tw-items-center tw-bg-white tw-text-center"
>
  <img aria-label="Payment loading image indicator" src="../../assets/images/loading.png" class="rotating-image" />
  <h3
    class="tw-w-full tw-text-[36px] tw-text-center tw-font-bold tw-leading-[48px] tw-mt-[20px]"
    aria-label="Payment loading title"
  >
    {{ 'label.wait.booking-loading.label' | translate }}
  </h3>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { BookingFlightTypeEnum, FlightClassOfServiceEnum, FlightSelectionTypeEnum } from '../../flight.enum';
import { FlightService } from '../../flight.service';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-flight-selection-toggle',
  templateUrl: './flight-selection-toggle.component.html',
  styleUrls: ['./flight-selection-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightSelectionToggleComponent implements AfterViewInit, OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();
  private readonly _activeTypeIndicatorWidth$ = new BehaviorSubject<number>(0);
  readonly flightClassOfServiceEnums = FlightClassOfServiceEnum;

  activeTypeIndicatorWidth$!: Observable<number>;
  selectedFlightClassOfService = FlightClassOfServiceEnum.EconomyAndScootPlus;

  @Input() selectedFlightType!: FlightSelectionTypeEnum | null;
  @Input() bookingFlightType!: BookingFlightTypeEnum | null;

  @ViewChild('EconomyAndScootPlus') economyAndScootPlus!: ElementRef;
  @ViewChild('ScootForSure') scootForSure!: ElementRef;

  constructor(private flightService: FlightService) {}

  ngOnInit(): void {
    this.activeTypeIndicatorWidth$ = this._activeTypeIndicatorWidth$.asObservable();
    this.flightService.selectedClassOfService$
      .pipe(takeUntil(this._destroy$))
      .subscribe((value: FlightClassOfServiceEnum) => {
        this.selectedFlightClassOfService = value;
        this.setToggleWidth();
      });

    this.flightService.selectedDepartingFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight) => {
        if (!flight) {
          this.setToggleWidth();
        }
      });

    this.flightService.selectedReturningFlightData$
      .pipe(takeUntil(this._destroy$))
      .subscribe((flight) => {
        if (!flight) {
          this.setToggleWidth();
        }
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.setToggleWidth();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.setToggleWidth();
  }

  toggleEconomyAndScootPlus() {
    if (this.bookingFlightType === BookingFlightTypeEnum.NewBooking) {
      this.flightService.setSelectedClassOfService(FlightClassOfServiceEnum.EconomyAndScootPlus);
    }
  }

  toggleScootForSure() {
    if (this.bookingFlightType === BookingFlightTypeEnum.NewBooking) {
      this.flightService.setSelectedClassOfService(FlightClassOfServiceEnum.ScootForSure);
    }
  }

  private setToggleWidth() {
    setTimeout(() => {
      if (this.selectedFlightClassOfService == FlightClassOfServiceEnum.ScootForSure) {
        this._activeTypeIndicatorWidth$.next(this.scootForSure ? this.scootForSure.nativeElement.offsetWidth : 0);
      } else {
        this._activeTypeIndicatorWidth$.next(this.economyAndScootPlus ? this.economyAndScootPlus.nativeElement.offsetWidth : 0);
      }
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

import { NotificationService } from '../../shared/components/notification/notification.service';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Subject, forkJoin, take, takeUntil } from 'rxjs';
import { AddFeeRequest, Booking } from '../booking.model';
import { BookingService } from '../booking.service';
import { NotificationData } from '../../shared/components/notification/notification.model';
import { OFWPassengerData } from '../flight/flight.model';
import { PaymentService } from '../payment/payment.service';
import { cloneDeep } from 'lodash';
import { AppConstants } from '../../app.constants';

@Component({
  selector: 'app-ofw-declaration',
  templateUrl: './ofw-declaration.component.html',
  styleUrls: ['./ofw-declaration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfwDeclarationComponent implements OnDestroy, OnInit {
  private readonly _destroy$ = new Subject<void>();
  private readonly ofwCityCodes = AppConstants.OfwCityCodes;

  showOFCDeclaration$ = new BehaviorSubject<boolean>(false);
  ofwPassengers$ = new BehaviorSubject<OFWPassengerData[]>([]);
  disabledApplyButton = true;
  previousPassengerData!: OFWPassengerData[];
  booking!: Booking | null;
  isBodyShow = true;

  constructor(
    private readonly bookingService: BookingService,
    private readonly notificationService: NotificationService,
    private readonly paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.ofwPassengers$ = this.paymentService.ofwPassengers$;
    this.paymentService.ofwPassengers$.pipe(takeUntil(this._destroy$)).subscribe((data) => {
      this.previousPassengerData = cloneDeep(data);
      this.validateSelectedData(data);
    });

    this.bookingService.booking$.pipe(takeUntil(this._destroy$)).subscribe((booking) => {
      this.booking = booking;
      if (booking) {
        this.showOFCDeclaration$.next(
          this.ofwCityCodes.includes(booking.origin) || (booking.journeys.length > 1 ? this.ofwCityCodes.includes(booking.destination) : false)
        );
      }
    });
  }

  onChangeCertified(
    currentOFWPassengerData: OFWPassengerData[],
    passenger: OFWPassengerData,
    isSelected: boolean
  ): void {
    passenger.isSelected = isSelected;
    passenger.isSelectedForAdd = !isSelected;
    passenger.isSelectedForDelete = isSelected;

    this.validateSelectedData(currentOFWPassengerData);
  }

  onApply(currentOFWPassengerData: OFWPassengerData[]): void {
    const addDataList: AddFeeRequest[] = [];
    const deleteDataList: string[] = [];

    //Add
    const forAddData = currentOFWPassengerData.filter((p) => p.isSelectedForAdd && !p.isSelected);
    forAddData.forEach((data) => {
      const passengerHasIPSCFee = data.passengerFees.find((x) => x.feeCode === AppConstants.IPSCFeeCode) != null;
      if (!passengerHasIPSCFee) {
        const request = {
          passengerKey: data.passengerKey,
          feeCode: AppConstants.IPSCFeeCode,
          origin: this.booking?.origin ?? '',
          collectedCurrencyCode: 'IPS',
          note: ''
        } as AddFeeRequest;

        addDataList.push(request);
      }
    });

    //Delete
    const forDeleteData = currentOFWPassengerData.filter((p) => p.isSelectedForDelete && p.isSelected);
    forDeleteData.forEach((data) => {
      if (data.passengerKey) {
        const passenger = data.passengerFees.find((fee) => fee.feeCode === AppConstants.IPSCFeeCode);

        if (passenger) {
          deleteDataList.push(passenger.passengerFeeKey);
        }
      }
    });

    //Submit
    if (addDataList.length > 0 && deleteDataList.length > 0) {
      forkJoin({
        addResponse: this.bookingService.addBookingFee$(addDataList).pipe(take(1)),
        deleteResponse: this.bookingService.deleteBookingFee$(deleteDataList).pipe(take(1))
      })
        .pipe(take(1))
        .subscribe(() => this.reload());
    } else if (addDataList.length > 0) {
      this.bookingService
        .addBookingFee$(addDataList)
        .pipe(take(1))
        .pipe(take(1))
        .subscribe(() => this.reload());
    } else if (deleteDataList.length > 0) {
      this.bookingService
        .deleteBookingFee$(deleteDataList)
        .pipe(take(1))
        .pipe(take(1))
        .subscribe(() => this.reload());
    }
  }

  onBodyToggle(): void {
    this.isBodyShow = !this.isBodyShow;
  }

  displayFullDeclaration(): void {
    const notification: NotificationData = {
      title: 'notification.title.ofwDeclaration',
      text: 'notification.text.ofwDeclaration',
      showConfirmButton: true,
      showCancelButton: false
    };
    this.notificationService.show(notification);
  }

  private reload(): void {
    this.paymentService.reloadPaymentPage$.next(true);
  }

  private validateSelectedData(currentOFWPassengerData: OFWPassengerData[]): void {
    this.disabledApplyButton = JSON.stringify(this.previousPassengerData) === JSON.stringify(currentOFWPassengerData);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService } from './error.service';
import { Subject, takeUntil } from 'rxjs';
import { AppConstants } from '../app.constants';
import { ErrorEnum } from './error.enum';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  errorMessage!: string;

  constructor(private errorService: ErrorService) {}

  ngOnInit(): void {
    this.errorService.error$.pipe(takeUntil(this._destroy$)).subscribe((error) => {
      if (error.status === ErrorEnum.SessionTimeout) {
        this.errorMessage = 'error.message.sessionTimeoutExpiredError';
      } else if (error.status === ErrorEnum.Unauthorized) {
        this.errorMessage = 'error.login.unauthorized';
      } else if (error.status === ErrorEnum.InternalServerError && error.error === 'Error retrieving staff profile') {
        this.errorMessage = 'error.message.invalidEmployee';
      } else if (error.status === ErrorEnum.InternalServerError && error.error === 'Workday downtime') {
        this.errorMessage = 'error.message.workDayDownTimeError';
      } else {
        this.errorMessage = 'error.message.generic';
      }
    });
  }

  get isMobile(): boolean {
    return window.innerWidth < AppConstants.MobileWidth.xs;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}

import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { ErrorService } from './error.service';
import { PageRoute } from '../shared/enums/page-routes.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorGuard  {

  constructor(private errorService: ErrorService, private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> {
    return this.errorService.errorState$.pipe(
      map((state: boolean) => {
        if (state) {
          return true;
        }
        else{
          return this.router.createUrlTree([PageRoute.Home]);
        }
      })
    );
  }
}

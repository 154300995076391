import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { Observable, Subject } from 'rxjs';
import { NotificationData } from './notification.model';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  showModal = false;
  notification$?: Observable<NotificationData | undefined>;

  constructor(private notificationService: NotificationService, public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.notification$ = this.notificationService.notification$;
  }

  onCloseModal(notification: NotificationData) {
    this.notificationService.hide(notification, false);
  }

  onConfirm(notification: NotificationData) {
    this.notificationService.hide(notification, true);
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
